import apiClient from '@/services/ApiClient.js'
import AuthenticationService from '@/services/AuthenticationService.js'

const BASE_URL = "ClassRoom";

export default {
    loadClassRooms(requestObj) {
        if(!requestObj)
            return Promise.reject("failedToLoadClassrooms");

        return apiClient.get(`ClassRoom/${requestObj.apiPath}/${requestObj.id}`);
    },
    async addClassRoom(classroom){
        let data = new FormData();
        for(let key of Object.keys(classroom)){
            data.append(key, classroom[key])
        }

        return apiClient.post('ClassRoom/addclassroom', data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            } })
    }, 
    async removeStudentFromClassroom(student){
        return apiClient.delete(`ClassRoom/DeleteStudent/${student.id}`)
    }, 
    async updateClassroom(classroom){
        let userId = AuthenticationService.getProfile().id
        let data = new FormData();
        for(let key of Object.keys(classroom)){
            data.append(key, classroom[key])
        }
        data.append('teacherUserId', userId)
        return apiClient.put(`ClassRoom/${classroom.id}`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            } })
    },
    async removeClassroom(classroom){
        return apiClient.delete(`ClassRoom/${classroom.id}`)
    },
    async addStudent(student){
        return apiClient.post(`ClassRoom/AddStudent`, student)
    },  
    async changeStudentLanguage(userId, language){
        return apiClient.put(`User/ChangeStudentTextLanguage/${userId}/${language}`)
    },  
    async changeStudentAudioLanguage(userId, language){
        return apiClient.put(`User/ChangeStudentAudioLanguage/${userId}/${language}`)
    }, 
    async isUserNameAvailable(username){
        return apiClient.put(`User/ChangeStudentTextLanguage/${username}`)
    },
    resetPassword(requestObj){
        return apiClient.put(`${BASE_URL}/StudentPasswordReset`, requestObj);
    }, 
    async loadStandardsReport(classroomId){
        return apiClient.get(`teks-performance-report/classroom/${classroomId}`)
    }, 
    async exportStandardsReport(classroomId){
        return apiClient.get(`download-teks-performance-report-xls/classroom/${classroomId}`,{
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }


}