var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "drop",
      on: {
        mouseleave: function($event) {
          _vm.displayed = false
        }
      }
    },
    [
      _c(
        "a",
        {
          class: _vm.active,
          on: {
            click: function($event) {
              _vm.clickHandler(_vm.menu)
            }
          }
        },
        [_vm._v(_vm._s(_vm.menu.text))]
      ),
      _vm.menu.items && _vm.menu.items.length > 0
        ? _c(
            "ul",
            {
              staticClass: "custom-scrollbar drop_menu",
              class: { display: _vm.displayed }
            },
            _vm._l(_vm.menu.items, function(item, index) {
              return _c(
                "a",
                {
                  key: index,
                  on: {
                    click: function($event) {
                      _vm.clickHandler(item)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(item.title || item.name))]
              )
            })
          )
        : _vm._e(),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "text-button": "Ok",
                "click-handler": _vm.hideVocabularyBox,
                clickOutSideModalHandler: _vm.hideVocabularyBox,
                showCancelButton: _vm.showModalCancelButton,
                modalType: _vm.modalType
              }
            },
            [_c("vocabulary-box", { attrs: { vocabulary: _vm.selectedWord } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }