import apiClient from '@/services/ApiClient.js'

export default {
    async getUnitStoryComponents(storyId) {
        return apiClient.get(`unitstory/${storyId}/backup`)
    },
    async updateStoryBanner(storyBanner) {
        return apiClient.put(`unitstory/${storyBanner.id}`, storyBanner)
    }, 
    async publishUnitStory(unitstoryId) {
        return apiClient.patch(`unitstory/${unitstoryId}/publish`)
    },
}