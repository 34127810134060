import apiClient from "./ApiClient";

export default {
    getStudentPerformance(studentId){
        return apiClient.get(`performance-report/student/${studentId}`);
    },
    getClassroomPerformance(classroomId){
        return apiClient.get(`classroom-performance-report/classroom/${classroomId}`);
    },
    getUnitsGroups(studentPerformance){
        if(!studentPerformance)
            return [];

        const STUDENT_PERFORMANCE_COMPONENT_WIDTH = 704;
        const ROW_HEADER_SIZE = 124;
        const STUDENT_UNIT_PERFORMANCE_COMPONENT_MARGIN = 16;
        const UNIT_LESSON_WIDTH = 62;
        
        let _unitsGroups = [];
        let _currentWidth = 0;

        studentPerformance.forEach(elem => {
            if(elem.lessons.length > 0){
                let _amountOfLessons = elem.lessons.length > 2 ? elem.lessons.length : 3;
                let _studentUnitPerformanceWidth = _amountOfLessons * UNIT_LESSON_WIDTH
                                                    + STUDENT_UNIT_PERFORMANCE_COMPONENT_MARGIN;

                if((_currentWidth -= _studentUnitPerformanceWidth) >= 0){
                    _unitsGroups[_unitsGroups.length - 1].push(elem);
                }
                else{
                    _currentWidth = STUDENT_PERFORMANCE_COMPONENT_WIDTH
                                        - ROW_HEADER_SIZE
                                        - _studentUnitPerformanceWidth;
                    _unitsGroups.push([elem]);
                }
            }
        });

        return _unitsGroups;
    },
}