import axios from 'axios'
import {i18n} from '@/plugins/i18n.js';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URI,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'language': i18n.locale 
  }
})

apiClient.interceptors.request.use(requestConfig => {
  let token;
  if((token = sessionStorage.getItem("auth-token"))
        && !requestConfig.notInjectToken)
    requestConfig.headers.common["Authorization"] = `Bearer ${token}`;
  return requestConfig;
});

apiClient.interceptors.response.use(response => response, error => {
  if(error.response && error.response.status === 401){
    window.location.href = "/signin";
    return;
  }

  return Promise.reject(error);
});

export default apiClient