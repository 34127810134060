import axios from "axios";
const APP_INSIGHTS_API_BASE_URI = "https://api.applicationinsights.io/v1/apps";
const ApplicationId = process.env.VUE_APP_ANALYTICS_APP_ID
const appInsightsApiClient = axios.create({
    baseURL: `${APP_INSIGHTS_API_BASE_URI}/${ApplicationId}/`,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      "X-Api-Key": process.env.VUE_APP_ANALYTICS_KEY
    }
});

export default appInsightsApiClient;
  