<template>
    <header  v-if="unit">
		<span class="unit-name" v-tooltip="unit.name || ''">{{unit.name}}</span>
		<nav id="menu" class="flex-container">
			<drop-down v-for="(menu, index) in menus" :key="index" :menu="menu" />
		</nav>
    </header>
</template>

<script>

import DropDown from '@/common/DropDown.vue'
export default {
	name: 'UnitMenuBar',
	components: {
		DropDown
	},
	computed: {
		unit(){
            return this.$store.state.currentUnit
		},
		lesson(){
            return this.$store.state.currentLesson
		},
		vocabulary(){
			return this.unit.vocabularies
		},
		menus(){	
			let assessmentRoute = 
				this.lesson && this.unit && this.lesson.hasAssessment
				? {name: 'Assessment',  params: {unitId: this.unit.id, lessonId: this.lesson.id}} : null
			let timeLineRoute = 
				this.unit 
				? {name: 'TimeLine',  params: {unitId: this.unit.id}} : null
			let lessonRoute = 
				this.lesson && this.unit 
				? {name: 'Lesson',  params: {unitId: this.unit.id, lessonId: this.lesson.id}} : null
			return [	
						{text: this.$t('opener'), route: { name: 'Opener', params: {unitId: this.unit.id}}},
						{text: this.$t('lesson'), route: lessonRoute},
						{text: this.$t('stories'), items: this.unit ? this.$store.getters.unitStories :null},						
						{text: this.$t('biographies'), items: this.unit ? this.$store.getters.biographies :null},
						{text: this.$t('timeline'), route: timeLineRoute},
						{text: this.$t('vocabulary'), items: this.vocabulary},
						{text: this.$t('video'), route: {name: 'VideoGallery', params:{unitId: this.$route.params.unitId}}},
						{text: this.$t('assessment'), route:assessmentRoute},
					]
		}
	},
    created(){
		if(this.$route.params.unitId && !this.unit)
			this.$store.dispatch('loadCurrentUnit', this.$route.params.unitId)
		if(this.$route.params.lessonId && !this.lesson)
			this.$store.dispatch('loadLesson', this.$route.params.lessonId)
	}

}
</script>
<style lang="less" scoped>

.unit-name{
	color: #0A1554;
	font-family: 'Roboto', sans-serif;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 2px;
	line-height: 16px;
	text-transform: uppercase;
	margin-left: 40px;
	align-self: center;
	max-width: 27%;
}

@media screen and (max-width: 1023px){
	.unit-name{
		max-width: 20%;
	}
}


.drop-active{
    color:#3A4DFD;
}

.drop-inactive{
    color: rgba(0,0,0,0.38);
}

header {
	display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
	background-color: #fff;
	width: 100%;
	transition: 0.33s;
	box-shadow: 0 4px 10px 0 rgba(58,77,253,0.2),  inset 0 4px 10px 0 rgba(58,77,253,0.2);
    height: 48px;

}

header #menu {
	margin-left: auto;
	margin-right: 40px;
	align-self: center;
}

</style>
