import apiClient from '@/services/ApiClient.js'

export default {
    async getLessonComponents(lessonId) {
        return apiClient.get(`lesson/${lessonId}/backup`)
    }, 
    async updateLessonBanner(lessonBanner) {
        return apiClient.put(`lesson/${lessonBanner.id}`, lessonBanner)
    }, 

    async saveLessonOverviewChanges(lesson){
        return apiClient.put(`lesson/${lesson.id}/overview`, lesson)
    },

    async publishLessonChanges(lessonId) {
        return apiClient.patch(`lesson/${lessonId}/publish`)
    },

    async reoderRoutePoint(point) {
        return apiClient.patch(`component/paragraph/route/${point.id}/order`, {number:point.number})
    },
    async addRoutePoint(paragraph) {
        return apiClient.post(`component/paragraph/${paragraph.id}/route`)
    },
    async removeRoutePoint(point) {
        return apiClient.delete(`component/paragraph/route/${point.id}`)
    },
    async updateRoutePoint(point) {
        return apiClient.put(`component/paragraph/route/${point.id}`, point)
    },
    async publishSkillLessonChanges(lessonId){  
        return apiClient.patch(`lesson/${lessonId}/publish`)
    },
    async submitSkillLessonAssessmentQuestions(payload){
        // console.log(payload)
        return apiClient.patch(`lesson/${payload.lessonId}/assessment-completed`,payload)
    }

}