<template>
    <div class="notifications-box column custom-scrollbar">
        <div class="row">
            <span class="legend" v-if="numberOfUnreadNotifications > 0">
                {{numberOfUnreadNotifications}} unread notifications</span>
        </div>
        <notification v-for="notification in notifications" :key="notification.id"
            :notification="notification" />
        <span class="btn secondary centered" :style="{height: 'auto'}" 
        v-if="notifications.length == 0">You don't have any notification so far</span>
    </div>
</template>
<script>
import BoxNotification from '@/common/BoxNotification.vue'
export default {
    name: 'NotificationsBox', 
    components:{
        'notification': BoxNotification
    }, 
    computed:{
        notifications(){
            return this.$store.state.notificationsBox
        }, 
        numberOfUnreadNotifications(){
            return this.notifications.filter(e => !e.isRead).length
        }
    }
}
</script>
<style lang="less" scoped>
.notifications-box{
    min-height: 500px;
    max-height: 500px;
    min-width: 300px;
    border: 1px solid #CFCFCF;
    border-radius: 4px;
    position: absolute;
    background-color: #fff;
    top: 45px;
    right: -8px;
    box-shadow: var(--primary-shadow);  
    overflow-y: auto;
    padding: 10px;
    box-sizing: border-box;
    z-index: 1;
    .legend{
        font-size: 16px;
    }
    > div:first-child{
        background-color: #fff;
        border-bottom: 1px solid #CFCFCF;
        min-height: 40px;
        span{
            margin: auto;
        }
        position: relative;
    }
}
</style>

