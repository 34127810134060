var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "management-dashboard-bar-container" }, [
    _c("div", { staticClass: "sections-container row col-6" }, [
      _c("div", { staticClass: "management-path" }, [
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.$t(_vm.sectionPath.parent.toLowerCase()) +
                " > " +
                _vm.$t(_vm.sectionPath.child.toLowerCase())
            )
          )
        ])
      ]),
      _c("div", { staticClass: "sections-menu col-6" }, [
        _c("label", { attrs: { for: "section" } }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.withCapitalLetter(_vm.$t("sections")) + ":")
          )
        ]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedSection,
                expression: "selectedSection"
              }
            ],
            attrs: { name: "section" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedSection = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          _vm._l(_vm.sections, function(section, index) {
            return _c(
              "option",
              { key: index, domProps: { value: section.aka } },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.withCapitalLetter(_vm.$t(section.name)))
                )
              ]
            )
          })
        )
      ]),
      _c("div", { staticClass: "properties-panel-header" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }