export default {
    unit: 'unidad',
    units: 'unidades',
    unitDescription: 'Descripción de la unidad',
    unitTitleTag: 'Título de la unidad',
    overview: 'introducción',
    lesson: 'lección',
    lessons: 'lecciones',
    biographies: 'biografías',
    biography: 'biografía',
    unitStory:'Historia de la unidad',
    story: 'Historia',
    stories:'cuentos',
    timeline: 'línea de tiempo',
    vocabulary: 'glosario',
    video: 'video',
    assessment: 'evaluación',
    unitSkills: 'Habilidades por unidad',
    essay: 'ensayo',
    essentialQuestionLegend: 'Presiona aquí, y expande tus conocimientos.',
    noContentToDisplayMessage: 'No hay contenido para mostrar',
    assessmentDirections: 'instrucciones',
    completeAssessmentMessage: 'Responde todas las preguntas antes de enviar la evaluación',
    completeEssayAlert: 'El ensayo ya se ha completado y no puede ser modificado',
    submittedAssessmentAlert: 'La evaluación ha sido enviada, no puedes modificar tus respuestas',
    submitAssessmentMessage: 'Si estas listo para enviar tu evaluación da clic al botón [ENVIAR] para proceder con el envío y evaluación',
    failedToSubmitAssessmentMessage: 'Error al enviar la evaluación',
    failedToLoadAssessmentMessage: 'Error al cargar la evaluación',
    publishingMessage: "Por favor, espere un poco, contenido publicándose",
    sucessfullPublishingMessage: "Contenido publicado exitosamente",
    correctAnswerText: "Seleccionó una opción correcta",
    wrongAnswerText:"Seleccionó una opción incorrecta",
    next: 'siguiente',
    ok: 'aceptar',
    done: 'Hecho',
    cancel: 'cancelar',
    back: 'atrás',
    submit: 'enviar',
    exit: 'salir',
    logout: 'salir',
    delete: 'eliminar',
    edit: 'editar',
    english: 'INGLÉS',
    spanish: 'ESPAÑOL',

    screenSizeNotSupportedMessage: 'Tamaño de pantalla incompatible',

    unitSkillsBanner: 'OBJETIVOS - HABILIDADES - TEKS Y ESTANDARES',
    descriptionTag: 'Descripción',

    //Back office translation

    publishcambiosQuestion: '¿Publicar los cambios?',
    preview: 'previsualización',
    keepEditing: 'seguir editando',
    publish: 'publicar',
    unableToSaveChangesAlert: 'error al guardar cambios',

    assessmentHeader: 'Encabezado',
    questionPlaceHolder: 'pregunta...',
    questionTag: 'pregunta',
    addChoiceTag: 'agregar opción',
    correctChoiceTag: 'correcta',
    placeHolderChoice: 'opción...',
    choiceTag: 'opción',
    response: 'respuesta texto',

    skillLesson: 'Lección de habilidad',
    skillLessonBanner: 'Banner de lección de habilidad',
    primaryText:'primario texto fuente',
    instruction: 'Instrucción',

    multipleEssaysInAssessmentAlert: 'No puede haber más de un ensayo por evaluación',
    multipleVennDiagramInAssessmentAlert: 'No puede haber más de un diagrama de venn por evaluación',

    complementaryText: 'texto complementario',
    characterNamePlaceholder: 'Nombre del personaje',
    CharacterPeriodOfTime: 'periodo del personaje',
    biographyMainTextPlaceHolder: 'Biografía',
    mainIlustrationTag: 'ilustración principal',
    mainCardTag: 'tarjeta principal',
    portraitTag: 'retrato',
    portraitCaptionPlaceHolder: 'pie de foto',
    biographyUpdatedMessage: 'Biografía actualizada',
    biographyUpdateErrorMessage: 'Error al actualizar la biografía',
    biographyNamePlaceHolder: 'Biografía...',
    storyNamePlaceHolder: 'Nombre de la historia ...',

    addAudioTag: 'agregar audio',
    wrongFileFormatAlert: 'Tipo de archivo invalido',

    backgroundTag: 'color de fondo',
    opacityTag: 'opacidad',
    invalidBackgrounColorAlert: 'Color de fondo es invalido',

    overviewTitleBackground: "Color del título principal",
    unitDescriptionAudio: "Descripción de la unidad",
    captionAudio: "Audio de la leyenda",
    imageCaptionPlaceHolder: "Leyenda de la imagen...",
    imageDisclaimerPlaceHolder: "Renuncia de la imagen...",
    imageTitlePlaceHolder: "Título de la imagen...",
    imageCaptionMarginPlaceHolder: "Margen de la leyenda de la imagen",
    imageCaptionBottomMarginPlaceHolder: "Margen inferior de la imagen",
    wrongImageCaptionMarginValue: "El margen de la leyenda debe ser un número entero",
    wrongImageBottomMarginValue: "El margen inferior de la imagen debe ser un número entero",

    essentialQuestionTag: 'Puntos clave',
    essentialQuestionPlaceHolder: 'puntos clave...',

    fontColorTag: 'color de fuente',
    black: 'negro',
    white: 'blanco',
    blue: 'azul',

    shadow: 'sombra',
    originalSize: 'tamaño original',

    reorderTag: 'reorganizar',

    widthSizeTag: 'tamaño',
    medium: 'mediano',
    large: 'grande',
    alignmentTag: 'alineamiento',
    left: 'izquierda',
    center: 'centrado',
    right: 'derecha',

    textAlignmentTag: 'alineamiento del texto',
    start: 'inicio',

    invalidUrlAlert: 'URL es invalida',

    titlePlaceHolder: "Título..",
    textPlaceHolder: 'texto...',

    lessonUpdatedMessage: 'Lección actualizada',
    updateLessonErrorAlert: 'error al actualizar la lección',
    lessonBanner: 'encabezado',
    lessonTitlePlaceHolder: 'Título de la lección...',

    storyBanner: 'banner de historia',
    storyTitlePlaceHolder: 'Título de la lección...',
    storyUpdatedMessage: 'Historia actualizada!',
    updateStoryErrorAlert: 'No se pudo actualizar el componente de la historia.',
    story_label: 'historia',

    saveChangesTag: 'Guardar cambios',
    savingChangesTag: 'Guardando...',

    dateTag: 'fecha',
    yearTag: 'año',
    eventTitleTag: 'Evento',
    addEventTag: 'Agregar evento',
    invalidDateAlert: 'Fecha invalida',
    failedToUpdateTimelineMessage: 'Error al actualizar la line de tiempo.',
    timelineUpdatedMessage: 'linea de tiempo actualizada.',

    addContentTag: 'agregar contenido',
    typeOfContentTag: 'Tipo de contenido',
    selectLessonAlert: 'Seleciona una lección',
    failedToAddAssessmentAlert: 'Error al agregar assessment',
    failedToEditAssessmentAlert: 'Error al editar assessment',

    addLessonTag: 'nueva',
    otherContentTag: 'más contenido',
    unitPeriodPlaceHolder: 'Periodo...',

    titleTag: 'Título',
    caption: "Leyenda",
    wordPlaceHolder: 'termino...',
    pronunciationTag: 'Pronunciación',
    pronunciationPlaceHolder: 'pronunciación...',
    definitionTag: 'Definición',
    definitionPlaceHolder: 'definición...',
    categoryTag: 'Categoría',
    categoryPlaceHolder: 'categoría...',
    pluralTag: 'Plural',
    pluralPlaceHolder: 'plural...',
    usageTag: 'Uso',
    usagePlaceHolder: 'uso...',

    catalogLegend: 'Arrastra elementos',
    propertiesPanelLegend: 'Selecciona un elemento para editarlo',

    addUnitTag: 'nueva unidad',

    newVocabularyWordTag: 'Nuevo termino',
    failedToUpdateVocabularyMessage: 'Error al actualizar el glosario',
    vocabularyUpdatedMessage: 'Glosario actualizado',

    //Notifications in store
    failedToReoderLessonAlert: 'Error al reordenar la lección',
    failedToSaveLessonAlert: 'Error al guardar la lección',
    failedToCreateLessonAlert: 'Error al crear lección',
    failedToRemoveLessonAlert: 'Error al remover la lección',
    failedToRenameLessonAlert: 'Error al actualizar el nombre de la lección',
    failedToCreateLessonComponentAlert: 'Error al crear componente',
    failedToRemoveLessonComponentAlert: 'Error al remover componente',
    failedToSaveLessonComponentAlert: 'Error al guardar componente',
    failedToReoderLessonComponentAlert: 'Error al reordenar componente',

    failedToSaveEssentialQuestionAlert: 'Error al guardar Essential Question',

    overviewUpdatedMessage: 'Overview actualizado',
    failedToUpdateOverviewAlert: 'Error al guardar cambios en overview',

    failedToCreateUnitAlert: 'Error al crear unidad',
    failedToUpdateUnitAlert: 'Error al guardar la unidad',
    failedToReorderUnitAlert: 'Error al reordenar la unidad',
    failedToRemoverUnitAlert: 'Error al remover la unidad',

    failedToCreateBiographyAlert: 'Error al crear la biografía',
    failedToUpdateBiographyNameAlert: 'Error al actualizar el nombre de la biografía',
    failedToUpdateBiographyAlert: 'Error al actualizar la biografía',
    failedToRemoveBiographyAlert: 'Error al remover la biografía',

    failedToCreateStroyAlert: 'Failed to create unitstroy!',
    failedToUpdateUnitStoryNameAlert: 'Failed to update unitstory name!',
    failedToRemoveUnitStoryAlert: 'Error al remover la historia',

    failedToLoadVocabularyAlert: 'Error al cargar el glosario',
    failedToAddVocabularyAlert: 'Error al agregar termino',
    failedToRemoveVocabularyAlert: 'Error al remover el termino',

    failedToLoadAssessmentAlert: 'Error al cargar la evaluación',
    failedToUpdateAssessmentAlert: 'Error al actualizar las instrucciones',
    failedToRemoveAssessmentAlert: 'Error al remover la evaluación',

    failedToAddQuestionAlert: 'Error al agregar la pregunta',
    failedToUpdateQuestionAlert: 'Error al actualizar pregunta',
    failedToRemoveQuestionAlert: 'Error al remover pregunta',
    failedToSetCorrectChoiceAlert: 'Error al actualizar la respuesta correcta',
    failedToUpdateChoiceAlert: 'Error al actualizar la opción',
    failedToRemoveChoiceAlert: 'Error al remover la opción',

    cambiosPublishedMessage: 'cambios publicados',
    FailedToPublishMessage: 'Error al publicar',

    failedToUploadImage: "Error al adicionar imagen",

    // Component templates
    paragraphLegend: 'IMAGEN Y TEXTO',
    paragraphLongNameLeft: 'Imagen a la  izquierda y texto',
    paragraphLongNameRight: 'Imagen a la  derecha y texto',

    sectionTitleLegend: 'SUBTÍTULO',
    sectionTitleLongName: 'Subtítulo',

    assessmentGroupLegend: 'GRUPO DE EVALUACIÓN',
    assessmentGroupLongName: 'Grupo de evaluación',

    cardLegend: 'CARTA CON IMAGEN',
    cardLongName: 'Imagen a la izquierda y carta',

    textContentLegend: 'TEXTO',
    textContentLongName: 'Texto',

    videoContentLegend: 'VIDEO',
    videoContentLongName: 'Video (Url)',

    // Questions templates
    multipleChoiceLegend: 'PREGUNTA DE OPCIÓN MULTIPLE',
    multipleChoiceLongName: 'pregunta de opción multiple',

    multipleChoice: "Opción multiple",

    vennDiagramLegend: 'DIAGRAMA DE VENN',
    vennDiagramLongName: 'Diagrama de venn',
    addElementTag: 'agregar elemento',
    elementTag: 'elemento',

    essayLegend: 'ENSAYO',
    essayLongName: 'Ensayo',

    writtenLegend: 'PREGUNTA DE RESPUESTA ABIERTA',
    writtenLongName: 'pregunta de respuesta abierta',

    writtenAnswer: "Respuesta escrita",

    interactiveMapLegend: 'MAPA INTERACTIVO',
    interactiveMapLongName: 'Mapa interactivo',


    interactiveLessonLegend: 'LECCIÓN INTERACTIVA',
    progressive: 'Progresiva',
    horizontal: 'Horizontal',
    displayNumbers: 'Display Numbersontal',

    onlyImagesLegend: "IMAGEN(S)",
    onlyImagesLongName: "Solo imagenes",

    addImageText: 'AGREGAR IMAGEN',
    addMapText: 'AGREGAR MAPA',
    addBackgroundText: 'IMAGEN DE FONDO',
    editImageText: 'CAMBIAR IMAGEN',
    secondaryImageText: 'IMAGEN SECUNDARIA',
    backgroundMapText: 'MAPA',
    addLayerText: 'AGREGAR CAPA',

    addMapStepText: 'AGREGAR PASO EN EL MAPA',

    teacherPage: 'Hoja informativa',

    propertiesTag: 'Propiedades',
    componentsTag: 'Componentes',
    layout: 'Diseño',
    content: 'Contenido',

    step: 'Paso',

    //Authentication texts
    welcomeMessage: "Bienvenido a",
    texasHistoryName: "Texas History",
    awakens: "AWAKENS",
    dontHaveAccountMessage: "No tiene cuenta?",
    someAuthenticationMessage: "However more and more people are sick and tired of using the same sample text over and over again.",
    signupHereMessage: "REGÍSTRATE",

    signInHeaderText: "",
    signUpHeaderText: "Crea ahora tu cuenta Novatrack",
    signEmailaddressLabelText: "Correo Electrónico",
    signPasswordLabelText: "Contraseña",
    signLoginButtonText: "ENTRAR",
    signForgotButtonText: "OLVIDE CONTRASEÑA",

    signupNameLabelText: "Nombre",
    invalidUsernameOrPassword: "Usuario y/o Contraseña inválidos",
    userBlocked: 'El usuario ha sido bloqueado debido a multiples intentos de ingreso.',
    emailAlreadyInUse: "Correo electrónico ya registrado",

    class: 'Clase',
    addClass: 'Nueva clase',
    removeStudentMessage: '¿Remover estudiante?',
    addStudent: 'agregar estudiante',
    classPeriod: 'Periodo:',
    teacher: 'Profesor:',
    studentsNumber: 'N° de estudiantes:',
    classDetails: 'Detalles',
    startDate: 'Inicio:',
    endDate: 'Fin:',
    studentsBatch: 'Registro de estudiantes',
    cvsFile: 'Archivo CSV',
    create: 'Crear',
    username: 'Usuario',
    firstName: 'Nombre',
    lastName: 'Apellido',
    birthDate: 'Fecha de nacimiento',
    failedToRemoveStudentAlert: 'Error al remover estudiante',

    failedToUpdateClassroomAlert: 'Error al actualizar la clase',
    removeClassroomMessage: '¿Eliminar clase?',

    failedToAddStudentAlert: 'Error al agregar estudiante',
    language: 'Idioma',

    forgotPasswordHeaderText: 'Olvidé mi contraseña',
    resetPasswordHeaderText: 'Reiniciar contraseña',

    /* classrooms management */
    middleName: "Segundo nombre",
    secondLastName: "Segundo apellido",
    failedToAddClassRoom: "Algunos campos no han sido llenados o tienen datos inválidos",
    usersManagementUnableToLoadTeachers: "No fue posible obtener los profesores",

    written: 'Preguntas abiertas',
    goToFeedBackMessage: 'realimentación',
    resetPasswordButtonText: "Establecer contraseña",
    cleanButtonText: "LIMPIAR",
    resetStudentPasswordButtonText: "RESTABLECER",
    successfulPasswordResetMessage: "Contraseña establecida correctamente",
    failedToResetPassword: "Error al reestablecer la contraseña",
    confirmPasswordLabelText: "Confirmar contraseña",
    notSamePassword: "Debe insertar la misma contraseña",
    report: "Reporte",
    feedback: "Resultados",
    scores: "Puntuación",
    score: "Puntuación",
    students: "estudiantes",

    //add and update users form
    addUsersCleanButtonText: "LIMPIAR",
    addUsersButtonText: "AGREGAR USUARIO",
    addUsersHeaderText: "Agregar nuevos usuarios",
    addUsersFirstNameLabelText: "Nombre",
    addUsersMiddleNameLabelText: "Segundo Nombre",
    addUsersLastNameLabelText: "Apellidos",
    addUsersEmailAddressLabelText: "Email Address",
    addUsersBirthDateLabelText: "Fecha de Nacimiento",
    addUsersRoleLabelText: "Categoria",
    defaultSelectOption: "Seleccione una opción",
    updateUsersButtonText: "ACTUALIZAR",
    updateUsersHeaderText: "Actualizar usuario",
    addUsersDistrictLabelText: "Distritos",

    //users management
    usersManagementUnableToLoadUsers: "Error al cargar usuarios",
    addUsersCampusLabelText: "Escuela",
    successfulAddUsersMessage: "Usuario agregado exitosamente",
    successfulUserDeletingMessage: "Usuario eliminado correctamente",
    successfulUserUpdatingMessage: "Usuario actualizado correctamente",
    "super admin": "SUPER ADMIN",
    "district manager": "DISTRICT MANAGER",
    "campus manager": "DIRECTOR DE ESCUELA",
    "district teacher": "PROFESOR DE DISTRITO",
    "content creator": "CREADOR DE CONTENIDO",
    student: "ESTUDIANTE",
    wantToDeleteUserQuestion: "Quieres eliminar",
    users: "Usuarios",
    usersManagementUnabledToAddUsers: "Error al intentar adicionar usuario",
    usersManagementUnableToLoadSchools: "Error al obtener las escuelas",
    failedToLoadDistricts: "Error al obtener los distritos",
    failedToLoadStates: "Error al obtener los estados",
    failedToLoadSchools: "Error al obtener las escuelas",
    usersManagementUnabledToUpdateUsers: "Error al actualizar el usuario",
    usersManagementUnabledToDeleteUsers: "Error al eliminar el usuario",
    mustSelectOption: "Debe seleccionar una opción",
    mustFillNameField: "Llena este campo con al menos un carácter",
    invalidEmailAddress: "Debe insertar un e-mail valido (ej. th@thistory.com)",
    invalidUsername: "La longitud del nombre de usuario debe estar entre 8 y 15 carácteres",
    invalidPassword: "Debe contener solo números y/o letras, con longitud entre 8 y 15 carácteres",
    shortInvalidPassword: "Solo letras y/o números, con longitud entre 8 y 15 carácteres",
    invalidBirthDate: "El usuario debe tener menos de 100 años",
    mustFillFields: "Algunos campos no han sido llenados o tienen datos inválidos",
    usernameInUse: "Usuario actualmente en uso",
    addUsersSecondLastNameLabelText: "Segundo nombre",
    roles: "Roles",
    role: "Rol",
    noContent: "No existe contenido para mostrar",

    //management section
    management: "Administración",
    classrooms: "Aulas",
    states: "estados",
    addDistrictsText: "ADICIONAR DISTRITO",
    addStateText: "Adicionar estado",
    addSchoolText: "Adicionar escuela",
    addDistrictNameLabelText: "Nombre del distrito",
    addStateNameLabelText: "Nombre del estado",
    addSchoolNameLabelText: "Nombre de la escuela",
    addButtonText: "ADICIONAR",
    invalidName: "Nombre en uso",
    successfulAddDistrictsMessage: "District adicionado correctamente",
    successfulUpdateDistrictsMessage: "District actualizado correctamente",
    successfulDistrictDeletingMessage: "District eliminado correctamente",
    successfulStateDeletingMessage: "Estado eliminado correctamente",
    successfulAddStatesMessage: "Estado adicionado correctamente",
    successfulUpdateStatessMessage: "Estado actualizado correctamente",
    successfulSchoolDeletingMessage: "Escuela eliminada correctamente",
    successfulAddSchoolsMessage: "Escuela adicionada correctamente",
    successfulUpdateSchoolsMessage: "Escuela actualizada correctamente",
    failedToAddDistricts: "Error al adicionar un distrito",
    failedToUpdateDistricts: "Error al actualizar un distrito",
    failedToRemoveDistricts: "Error al eliminar un distrito",
    failedToAddStates: "Error al adicionar estados",
    failedToUpdateStates: "Error al actualizar estados",
    failedToRemoveState: "Error al eliminar estados",
    failedToAddSchools: "Error al adicionar escuelas",
    failedToUpdateSchools: "Error al actualizar escuelas",
    failedToRemoveSchool: "Error al eliminar escuelas",
    failedToLoadTelemetries: "Error al obtener las telemetrias",
    failedToUpdateOverviewStatus: "Error al actualizar el estado de la introducción",
    updateDistrictsText: "Actualizar districto",
    updateStatesText: "Actualizar estado",
    updateSchoolsText: "Actualizar escuela",
    updateButtonText: "Actualizar",
    wantToDeleteDistrictQuestion: "Desea eliminar el distrito",
    wantToDeleteStateQuestion: "Desea eliminar el estado",
    wantToDeleteSchoolQuestion: "Desea eliminar la escuela",
    resetButtonText: "REESTABLECER",
    from: "Desde",
    to: "Hasta",
    search: "Buscar",
    sectionName: "Nombre de la sección",
    numberOfVisits: "Número de visitas",
    averageDuration: "Duración promedio",


    idleStateAlert: 'La sesión está a punto de expirar. La sesión se cerrar en 1 minuto. Para permanecer en la sesión haga clic en cualquier punto de la ventana.',

    /* Report translations */
    studentPerformanceHeaderText: "Reporte de Rendimiento",
    mostVisitedSections: "secciones mas visitadas",
    retakeAssessmentTeacherResponse: 'Evaluación actualizada, el estudiante puede retomar ahora.',
    somethingWentWrongAlert: 'Algo salió mal, intenta de nuevo más tarde.',
    performance: "rendimientos",
    completedUnits: "Unidades completadas",
    completedLessons: "Lecciones completadas",
    completionLevel: "Progreso",
    generalScore: "Puntuación general",
    failToLoadClassroomPerformance: "Error al obtener el rendimiento de la clase",
    averageScore: "Puntuación promedio",
    classroomPerformanceHeaderText: "Rendimiento de la clase",

    AssessmentSubmittedNotification: '<strong>{0}</strong> acaba de completar su evaluación de la lección <strong>{1}</strong> en la unidad <strong>{2}</strong>, Haga clic aquí para ver la evaluación.',
    AssessmentRetakeNotification: 'Tienes que volver a retomar la evaluación de la lección <strong>{1}</strong> en la unidad <strong>{2}</strong>, Haga clic aquí para iniciar',
    FeedbackCompleteNotification: 'Has recibido retroalimentación de la evaluación de la lección <strong>{1}</strong>en la unidad <strong>{2}</strong>',

    standardsReportTitle: 'REPORTE DE TEKS & STANDARDS',
    standardsReport: 'Reporte de TEKS',

    both: 'Ambos',
    compareTag: 'Compara y contrasta',
    bank: 'Banco de palabras',
    typeInAnAnswer: "Introduce una respuesta",
    overlay: 'capa sobrepuesta',

    deleteConfirmationMessage: '¿Estás seguro que deseas borrar esta {0}?',
    deleteContentMessage: '¿Está seguro de que desea eliminar este contenido?',

    newTeksTag: 'Agregar TEKS',
    sections: 'secciones',

    clickNextMapLegend: 'Haz clic en SIGUIENTE para seguir el mapa',

    analytics: 'Analytics',

    suggestedAnswer: 'Respuesta sugerida',

    studentAnswer: 'Respuesta del estudiante',

    contributorsPage: 'Contribuidores',

    contributorsPageBanner: 'CONTRIBUIDORES',

    scopeAndSequence: 'Alcance y Secuencia',

    timelineDirections: 'Arrastra la barra o haz clic sobre el evento para explorar la línea de tiempo.',

    noBiographyContentLegend: 'No hay contenido para mostrar en esta biografía.',

    globalConnectionsLegend: 'GLOBAL CONNECTIONS',
    globalConnectionsLongName: 'Global Connections',
    clickOnGlobalConnections: 'Click on Global Connections to see the connections around the world',
}