var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "switch-button-control flex-container" }, [
    _c(
      "div",
      {
        staticClass: "switch-button",
        class: { enabled: _vm.enabled },
        on: { click: _vm.toggle }
      },
      [
        _c("span", { staticClass: "inactive-tag" }, [
          _vm._v(_vm._s(_vm.inactiveTag))
        ]),
        _c("div", { staticClass: "button flex-container" }, [
          _c("span", { staticClass: "active-tag" }, [
            _vm._v(_vm._s(_vm.activeTag))
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }