import { Role } from '@/utils/Roles.js'
export default{
    routes:[
        {
            path: '/back-office/lesson-builder/unit/:unitId/lesson/:lessonId',
            name: 'LessonBuilder', 
            component: () => import('@/views/BackOffice/LessonBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'unitBuilderBreadcrumb', 
              subnavbar: 'builder-bar'
            }
          },
          {
              path: '/back-office/lesson-story/unit/:unitId/lesson/:lessonId',
              name: 'LessonStoryBuilder', 
              component: () => import('@/views/BackOffice/LessonStoryBuilder.vue'),
              meta: { 
                requiresAuth: true, 
                isBackOfficeSection: true,
                authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
                breadcrumb: 'unitBuilderBreadcrumb', 
                subnavbar: 'builder-bar'
              }
            },
          {
            path: '/back-office/story-builder/unit/:unitId/story/:unitstoryId',
            name: 'StoryBuilder', 
            component: () => import('@/views/BackOffice/StoryBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'unitBuilderBreadcrumb', 
              subnavbar: 'builder-bar'
            }
          },
          {
            path: '/back-office/unit-builder/unit/:unitId',
            name: 'UnitBuilder', 
            component: () => import('@/views/BackOffice/UnitBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              subnavbar: 'builder-bar'
            }
          }, 
          // {
          //   path: '/back-office/overview-builder/unit/:unitId',
          //   name: 'OverviewBuilder', 
          //   component: () => import('@/views/BackOffice/OverviewBuilder.vue'),
          //   meta: { 
          //     requiresAuth: true, 
          //     isBackOfficeSection: true,
          //     authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
          //     breadcrumb: 'unitBuilderBreadcrumb', 
          //     subnavbar: 'builder-bar'
          //   }
          // }, 

          {
            path: '/back-office/opener-builder/unit/:unitId',
            name: 'OpenerBuilder', 
            component: () => import('@/views/BackOffice/OpenerBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'unitBuilderBreadcrumb', 
              subnavbar: 'builder-bar'
            }
          }, 

          {
            path: '/back-office/dashboard-builder',
            name: 'UnitDashboardBuilder', 
            component: () => import('@/views/BackOffice/UnitDashboardBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              subnavbar: 'builder-bar'
            }
          }, 
          {
            path: '/back-office/biography-builder/unit/:unitId/biography/:biographyId',
            name: 'BiographyBuilder', 
            component: () => import('@/views/BackOffice/BiographyBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'unitBuilderBreadcrumb', 
              subnavbar: 'builder-bar'
            }
          },           
          {
            path: '/back-office/vocabulary-manager/unit/:unitId',
            name: 'VocabularyManager', 
            component: () => import('@/views/BackOffice/VocabularyManager.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'unitBuilderBreadcrumb', 
              subnavbar: 'builder-bar'
            }
          }, 
          {
            path: '/back-office/assessment-builder/unit/:unitId/lesson/:lessonId/assessment',
            name: 'AssessmentBuilder', 
            component: () => import('@/views/BackOffice/AssessmentBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'unitBuilderBreadcrumb', 
              subnavbar: 'builder-bar'
            }
          }, 
          {
            path: '/back-office/skill-lesson-builder/unit/:unitId/lesson/:lessonId',
            name: 'SkillLessonBuilder', 
            component: () => import('@/views/BackOffice/SkillLessonBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'unitBuilderBreadcrumb', 
              subnavbar: 'builder-bar'
            }
          },  
          {
            path: '/back-office/time-line-builder/unit/:unitId',
            name: 'TimeLineBuilder', 
            component: () => import('@/views/BackOffice/TimeLineBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              breadcrumb: 'unitBuilderBreadcrumb', 
              subnavbar: 'builder-bar'
            }
          }, 
          {
            path: '/back-office/teacher-page-builder/:teacherPageId/unit/:unitId',
            name: 'TeacherPageBuilder', 
            component: () => import('@/views/BackOffice/TeacherPageBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name, 
                Role.SuperAdmin.name
              ], 
              breadcrumb: 'unitBuilderBreadcrumb', 
              subnavbar: 'builder-bar'
            }
          }, 
          {
            path: '/contributors-page-builder',
            name: 'ContributorsPageBuilder', 
            component: () => import('@/views/BackOffice/ContributorsPageBuilder.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name], 
              subnavbar: 'builder-bar',
              breadcrumb: 'defaultBackOfficeBreadCrumb'
            }
          },
          {
            path: '/back-office/preview-mode/unit/:unitId/lesson/:lessonId',
            name: 'LessonBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name,
                Role.SuperAdmin.name, 
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'LessonLayout'
            }
          },
          {
            path: '/back-office/preview-mode/skill-lesson/unit/:unitId/lesson/:lessonId',
            name: 'SkillLessonBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name,
                Role.SuperAdmin.name, 
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'SkillLesson'
            }
          },
          {
            path: '/back-office/preview-mode/unit/:unitId/lesson/:lessonId',
            name: 'LessonStoryBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name,
                Role.SuperAdmin.name, 
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'LessonLayout'
            }
          }, 
          {
            path: '/back-office/preview-mode/unit/:unitId',
            name: 'UnitBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name, 
                Role.SuperAdmin.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'LessonsDashboard'
            }
          }, 
          {
            path: '/back-office/preview-mode/overview/:unitId',
            name: 'OverviewBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name, 
                Role.SuperAdmin.name, 
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'UnitOverview'
            }
          }, 
          {
            path: '/back-office/preview-mode/opener/:unitId',
            name: 'OpenerBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name, 
                Role.SuperAdmin.name, 
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'UnitOpenerOverview'
            }
          }, 
          {
            path: '/back-office/preview-mode/unit-dashboard',
            name: 'UnitDashboardBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name, 
                Role.SuperAdmin.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'UnitsDashboard'
            }
          }, 
          {
            path: '/back-office/preview-mode/unit/:unitId/biography/:biographyId',
            name: 'BiographyBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name,
                Role.SuperAdmin.name, 
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'Biography'
            }
          }, 
          {
            path: '/back-office/preview-mode/unit/:unitId/unitstory/:unitstoryId',
            name: 'StoryBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name,
                Role.SuperAdmin.name, 
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'Story'
            }
          }, 
          {
            path: '/back-office/preview-mode/unit/:unitId/lesson/:lessonId/assessment',
            name: 'AssessmentBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name,
                Role.SuperAdmin.name,
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb', 
              previewComponent: 'Assessment'
            } 
          },
          {
            path: '/back-office/preview-mode/unit/:unitId/time-line',
            name: 'TimeLineBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.ContentCreator.name, 
                Role.SuperAdmin.name, 
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb',
              previewComponent: 'time-line'
            }
          }, 
          {
            path: '/back-office/preview-mode/teacher-page/:teacherPageId',
            name: 'TeacherPageBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [ 
                Role.ContentCreator.name,
                Role.SuperAdmin.name,
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'defaultBackOfficeBreadCrumb',
              previewComponent: 'teacher-page'
            }
          },
          {
            path: '/back-office/preview-mode/contributors-page',
            name: 'ContributorsPageBuilderPreviewMode', 
            component: () => import('@/views/BackOffice/PreviewMode.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [Role.ContentCreator.name, Role.SuperAdmin.name, Role.DistrictTeacher.name], 
              breadcrumb: 'defaultBackOfficeBreadCrumb',
              previewComponent: 'contributors-page'
            }
          },
          {
            path: '/management-dashboard',
            name: 'ManagementDashboard', 
            component: () => import('@/views/ManagementDashboard.vue'),
            meta: { 
              requiresAuth: true, 
              isBackOfficeSection: true,
              authorize: [
                Role.SuperAdmin.name, 
                Role.DistrictManager.name, 
                Role.CampusManager.name
              ], 
              subnavbar: 'management-dashboard-nav-bar', 
              breadcrumb: 'classroomBreadCrumb', 
            }
          }
    ]
}