import apiClient from '@/services/ApiClient.js'

export default {
    async createEssentialQuestion(essentialQuestion) {
        return apiClient.post('essentialquestion', {
            question: essentialQuestion.question
                 ? essentialQuestion.question : '', 
            audioId:  essentialQuestion.audio && essentialQuestion.audio.id
                 ? essentialQuestion.audio.id :  0
          })
    },
    async updateEssentialQuestion(essentialQuestion) {
        return apiClient.put(`essentialquestion/${essentialQuestion.id}`,{
            question: essentialQuestion.question
                 ? essentialQuestion.question : '', 
            audioId:  essentialQuestion.audio && essentialQuestion.audio.id
                 ? essentialQuestion.audio.id :  0
          })
    }
}