import apiClient from '@/services/ApiClient.js'

export default {
    async loadContributorsPageComponent() {
        return apiClient.get(`contributors/backup`)
    },
    async updateContributorsPageComponent(payload) {
        return apiClient.put(`contributors`, payload)
    },
    async publishContributorsPageComponent() {
        return apiClient.patch(`contributors/publish`)
    },
    async loadContributorsPage(isPreviewModeActive) {
        return apiClient.get(`contributors${isPreviewModeActive ? '/backup' : '/'}`)
    }
}