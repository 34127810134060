<template>
    <div class="profileData-container column">
        <div class="containers row">
            <div class="left-container column">
                <img :src="userPicture">
            </div>
            <div class="right-container column">
                <small>{{$t("firstName")}}</small>
                <span>{{currentUser.firstName}}</span>

                <template v-if="currentUser.secondName">
                    <small>{{$t("middleName")}}</small>
                    <span>{{currentUser.secondName}}</span>
                </template>

                <small>{{$t("lastName")}}</small>
                <span>{{currentUser.lastName}}</span>

                <template v-if="currentUser.secondLastName">
                    <small>{{$t("secondLastName")}}</small>
                    <span>{{currentUser.secondLastName}}</span>
                </template>

                <small>{{$t("username")}}</small>
                <span>{{currentUser.userName}}</span>

                <small>{{$t("role")}}</small>
                <span>{{currentUser.roleName}}</span>

                <small>{{$t("birthDate")}}</small>
                <span>{{formatDate(currentUser.birthDate)}}</span>
            </div>
        </div>
        <div class="buttons row">
            <button class="btn primary" v-on:click="hideModal">
                {{$t("accept")}}</button>
        </div>
    </div>
</template>

<script>

import AuthenticationService from "@/services/AuthenticationService.js";
import moment from "moment";

export default {
    name: "ProfileData",
    computed: {
        currentUser(){
            return AuthenticationService.getProfile();
        },
        userPicture(){
            return require("@/assets/anonymous-image.png");
        }
    },
    methods: {
        formatDate(date){
            if(!date || date === "")
                return date;
                
            const EN_FORMAT = "MM/DD/YYYY";
            const ES_FORMAT = "DD/MM/YYYY";

            let _format = this.$i18n.locale === "en" ? EN_FORMAT : ES_FORMAT;
            return moment(date).format(_format); 
        },
        hideModal(){
            this.$emit("close");
        }
    }
}
</script>

<style lang="less" scoped>

.profileData-container{
    width: 400px;
    padding: 24px 0px;

    .containers{
        padding-bottom: 32px;
    }

    .left-container, .right-container{
        width: 180px;
        padding: 0px 10px;
    }

    .left-container img{
        width: 112px;
        height: 112px;

        margin-left: 6px;
    }

    .right-container{
        small{
            margin-top: 20px;

            &:first-child{
                margin-top: 0px;
            }
        }

        span{
            font-size: 18px;
            margin-top: 5px;
        }
    }

    .buttons{
        button{
            text-transform: uppercase;
            margin-left: 288px;
        }
    }
}

</style>


