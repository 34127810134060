<template>
    <transition name="fade">
        <div class="ui-snackbar flex-container" :class="{'vertical-alignment' : notification.message.length > 35}">
            <div class="ui-snackbar-text">{{notification.message}}</div>
            <div class="ui-snackbar-action" v-if="this.notification.dismissable">
                <button @click="dismiss">{{notification.textButton || 'dismiss'}}</button>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'SnackBar',  
    props:{
        notification: Object
    },
    methods:{
        dismiss(){
            this.$store.commit('removeSnackBarNotification', this.notification)
        }
    },
    mounted(){
        setTimeout(() => {
            if(!this.notification.dismissable)
                this.dismiss()               
        }, 2000);
    }
}
</script>


<style lang="less" scoped>

.vertical-alignment{
    flex-direction: column;
    .ui-snackbar-action{
        margin-top: 19px;
    }
}

.ui-snackbar {
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 288px;
    max-width: 568px;
    min-height: 48px;
    height: auto;
    
    padding: 16px 24px;
    padding-right: 12px;
    margin-top: 8px;
    
    border-radius: 4px;
    
	width: 360px;
    background-color: rgba(0,0,0,0.87);
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2);
}

.ui-snackbar-text {
    font-size: 14px;
    color: white;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	letter-spacing: 0.25px;
    line-height: 20px;
    height: auto;
}

.ui-snackbar-action {
    margin-left: auto;
    margin-top: auto;
    button {
        padding: 10px;
        border: none;
        margin: 0;
        background: none;
        cursor: pointer;
        font-size: 14px;
        text-transform: uppercase;

        height: 36px;
        min-width: 84px;
        border-radius: 2px;
        background-color: #3A4DFD;

        color: #FFFFFF;
        font-family: 'Roboto';
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 16px;
        text-align: center;
    }
}

.ui-snackbar-toggle-transition {
    transition: transform 0.3s ease;
    
    .ui-snackbar-text,
    .ui-snackbar-action {
        opacity: 1;
        transition: opacity 0.3s ease;
    }
}

.ui-snackbar-toggle-enter,
.ui-snackbar-toggle-leave {
    transform: translateY(60px);
    
    .ui-snackbar-text,
    .ui-snackbar-action {
        opacity: 0;
    }
}
</style>
