import { Role } from '@/utils/Roles.js'
export default{
    routes:[
        {
            path: '/class-room/management',
            name: 'ClassRoomManagement', 
            component: () => import('@/views/ClassRoomManagement.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [
                Role.DistrictTeacher.name
              ], 
              breadcrumb: 'classroomBreadCrumb', 
              subnavbar: 'classroom-nav-bar'
            }
        },
        {
            path: '/class-room/details',
            name: 'ClassRoomDetails', 
            component: () => import('@/views/ClassRoomDetails.vue'),
            meta: { 
              requiresAuth: true, 
              authorize: [
                Role.DistrictTeacher.name, 
                Role.CampusManager.name
              ], 
              breadcrumb: 'classroomBreadCrumb', 
              subnavbar: 'classroom-nav-bar'
            }
        },
        {
          path: '/student-reports/student/:studentId',
          name: 'StudentReports', 
          component: () => import('@/views/StudentReports.vue'),
          meta: { 
            requiresAuth: true, 
            authorize: [
              Role.DistrictTeacher.name, 
              Role.CampusManager.name
            ], 
            breadcrumb: 'classroomBreadCrumb', 
            subnavbar: 'classroom-nav-bar'
          }
        },
        {
          path: '/student-reports/student/:studentId/unit/:unitId/lesson/:lessonId',
          name: 'LessonPreview', 
          component: () => import('@/views/StudentReports.vue'),
          meta: { 
            requiresAuth: true, 
            authorize: [
              Role.DistrictTeacher.name, 
              Role.CampusManager.name
            ], 
            breadcrumb: 'classroomBreadCrumb', 
            subnavbar: 'classroom-nav-bar'
          }
        }
    ]
}