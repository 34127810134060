import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import AuthenticationService from "@/services/AuthenticationService.js";
import { Role } from "@/utils/Roles.js";

const AppInsights = {
    insightsInstance: null,
    install(vueInstance, options){
        let _options = {
            config: {
                instrumentationKey: process.env.VUE_APP_ANALYTICS_INST_KEY,
                disableExceptionTracking: true,
                disableAjaxTracking: true,
                enableCorsCorrelation: true
            }
        };

        if(!options)
            options = _options;

        let _insights = new ApplicationInsights(options);

        _insights.loadAppInsights();
        _insights.addTelemetryInitializer(AppInsights.setUserDataTelemetryInitializer);

        vueInstance.prototype.$appInsights = _insights;
        AppInsights.insightsInstance = _insights;
    },
    setUserDataTelemetryInitializer(envelope){
        let _user = AuthenticationService.getProfile();
        let _item = envelope.baseData;

        _item.properties = _item.properties || {};
        
        if(_user.roleId == Role.SuperAdmin.roleId
            || _item.properties.avoidSending)
            return false;

        let tags = envelope.tags;

        if(tags && tags["ai.user.authUserId"])
            tags["ai.user.id"] = tags["ai.user.authUserId"];

        _item.properties["userId"] = _user.id;
        _item.properties["username"] = _user.userName;
        _item.properties["userRoleId"] = _user.roleId;

        if(_user.districtId)
            _item.properties["districtId"] = _user.districtId;

        if(_user.schoolId)
            _item.properties["schoolId"] = _user.schoolId;
    }
};

export default AppInsights;