const soaConfig = {
    "lessonTypes": {
        "overview": {
            "id": 1,
            "icon": require("@/assets/icons/01_Opener.svg"),
            "showIcon": true,
            "rowStyle": "background: #f0f0f0;",
            "spanStyle": "background:#adadad;"
        },
        "lesson": {
            "id": 2,
            "icon": "",
            "showIcon": false, //true or false for lesson icon won't appear in FE
            "rowStyle": "",
            "spanStyle": ""
        },
        "story": {
            "id": 3,
            "icon": require("@/assets/icons/03_Bill_Story.svg"),
            "showIcon": true,
            "rowStyle": "background: #ffeec6;",
            "spanStyle": "background:#f4c553;"
        },
        "skill": {
            "id": 4,
            "icon": require("@/assets/icons/02_MidUnitActivity.svg"),
            "showIcon": true,
            "rowStyle": "background: #d6e4ff;",
            "spanStyle": "background:#2a57b1;"
        },
        "midAssessment": {
            "id": 5,
            "icon": require("@/assets/icons/02_MidUnitActivity.svg"),
            "showIcon": true,
            "rowStyle": "background: #d6e4ff;",
            "spanStyle": "background:#2a57b1;"
        },
        
        "endAssessment": {
            "id": 6,
            "icon": require("@/assets/icons/04_Assessment.svg"),
            "showIcon": false,
            "rowStyle": "background: #e7ffd9;",
            "spanStyle": "background:#99cd7b;"
        },
        
    },
};

export default { soaConfig };
