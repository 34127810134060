var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications-box column custom-scrollbar" },
    [
      _c("div", { staticClass: "row" }, [
        _vm.numberOfUnreadNotifications > 0
          ? _c("span", { staticClass: "legend" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.numberOfUnreadNotifications) +
                  " unread notifications"
              )
            ])
          : _vm._e()
      ]),
      _vm._l(_vm.notifications, function(notification) {
        return _c("notification", {
          key: notification.id,
          attrs: { notification: notification }
        })
      }),
      _vm.notifications.length == 0
        ? _c(
            "span",
            {
              staticClass: "btn secondary centered",
              style: { height: "auto" }
            },
            [_vm._v("You don't have any notification so far")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }