var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "pdf-view-container" },
      [
        _c("pdf", {
          staticStyle: { display: "block", width: "100%" },
          attrs: { src: _vm.PDFUrl, page: _vm.currentPage },
          on: {
            "num-pages": function($event) {
              _vm.pageCount = $event
            },
            "page-loaded": function($event) {
              _vm.currentPage = $event
            }
          }
        }),
        _vm.pageCount
          ? _c("div", { staticClass: "row btn-container" }, [
              !_vm.isPreviewMode
                ? _c(
                    "button",
                    {
                      staticClass: "btn jump",
                      class: _vm.currentPage == 1 ? "secondary" : "primary",
                      on: { click: _vm.firstPage }
                    },
                    [_vm._v("First")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn normal",
                  class: _vm.currentPage == 1 ? "secondary" : "primary",
                  on: { click: _vm.previousPage }
                },
                [_vm._v("Previous")]
              ),
              _c("span", [
                _vm._v(_vm._s(_vm.currentPage) + " / " + _vm._s(_vm.pageCount))
              ]),
              _c(
                "button",
                {
                  staticClass: "btn normal",
                  class:
                    _vm.currentPage == _vm.pageCount ? "secondary" : "primary",
                  on: { click: _vm.nextPage }
                },
                [_vm._v("Next")]
              ),
              !_vm.isPreviewMode
                ? _c(
                    "button",
                    {
                      staticClass: "btn jump",
                      class:
                        _vm.currentPage == _vm.pageCount
                          ? "secondary"
                          : "primary",
                      on: { click: _vm.lastPage }
                    },
                    [_vm._v("Last")]
                  )
                : _vm._e(),
              !_vm.isPreviewMode
                ? _c(
                    "button",
                    {
                      staticClass: "btn primary download",
                      on: { click: _vm.downloadPDF }
                    },
                    [_vm._v("Download")]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }