import apiClient from '@/services/ApiClient.js'

export default {
  async getVocabularyComponents(unitId) {
    return apiClient.get(`vocabulary/${unitId}/backup`)
  },
  async addVocabularyComponent(unitId){
    return apiClient.post('vocabulary',{
      unitId : unitId
    })
  },
  async updateVocabularyComponent(vocabularyComponent){
    return apiClient.put(`vocabulary/${vocabularyComponent.id}`, vocabularyComponent)
  },
  async removeVocabularyComponent(vocabularyComponent){
    return apiClient.delete(`vocabulary/${vocabularyComponent.id}`)
  }

}