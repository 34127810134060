import apiClient from '@/services/ApiClient.js'

export default {
  async createTimeLine(unitId) {
    return apiClient.post(`timeline/${unitId}`)
  },
  async getTimeLine(unitId){
    return apiClient.get(`timeline/${unitId}`)
  },
  async getTimeLineComponents(unitId){
    return apiClient.get(`timeline/${unitId}/backup`)
  },
  async removeTimeLine(unitId){
    return apiClient.delete(`timeline/${unitId}`)
  },
  async publishTimeLine(timelineId){
    return apiClient.patch(`timeline/${timelineId}/publish`)
  },
  async addTimeLineEvent(timeLineId){
    return apiClient.post(`timeline/${timeLineId}/blurb`)
  },
  async updateTimeline(timeline){
    return apiClient.put(`timeline/${timeline.id}`, timeline)
  },
  async removeTimeLineEvent(event){
    return apiClient.delete(`blurb/${event.id}`)
  },

}