import apiClient from "@/services/ApiClient.js";

const BASE_URL = "User";

export default{
    getUsers(requestObj){
        let _apiPath = requestObj ? `${requestObj.apiPath}/${requestObj.id}` : "";
        
        return apiClient.get(`${BASE_URL}/${_apiPath}`);
    },
    getByUsername(username){
        return apiClient.get(`${BASE_URL}/FindByUserName/${username}`)
                .then(response => {
                    let _method = response.status == 204 ? "reject" : "resolve";

                    return Promise[_method](response.data);
                });
    },
    addNewUser(newUser){
        return apiClient.post(`${BASE_URL}/registeruser`, newUser);
    },    
    editUser(modifiedUser){
        return apiClient.put(`${BASE_URL}/${modifiedUser.id}`, modifiedUser);
    },
    deleteUser(userToDelete){
        return apiClient.delete(`${BASE_URL}/${userToDelete.id}`);
    }
}