import apiClient from "./ApiClient.js";

const BASE_URL = "School";

export default {
    getSchools(requestObj){
        if(!requestObj)
            return Promise.reject();

        let _path = requestObj.apiPath;

        if(requestObj.id)
            _path = `${_path}/${requestObj.id}`;
        
        return apiClient.get(`${BASE_URL}/${_path}`);
    },
    getByName(schoolName){
        return apiClient.get(`${BASE_URL}/FindByName/${schoolName}`);
    },
    getByDistrict(districtId){
        return apiClient.get(`${BASE_URL}/GetByDistrictId/${districtId}`);
    },
    addSchool(newSchool){
        return apiClient.post(BASE_URL, newSchool);
    },
    updateSchool(updatedSchool){
        return apiClient.put(`${BASE_URL}/${updatedSchool.id}`, updatedSchool);
    },
    deleteSchool(removedSchool){
        return apiClient.delete(`${BASE_URL}/${removedSchool.id}`);
    }    
};