var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.breadCrumbElements
    ? _c(
        "div",
        { staticClass: "th-breadcrumb row ml-0 mr-0" },
        _vm._l(_vm.breadCrumbElements, function(element, index) {
          return _c("back-button", {
            key: index,
            attrs: { text: element.text, route: element.route }
          })
        })
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }