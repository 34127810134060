export default {
    unit: 'unit',
    units: 'units',
    unitDescription: 'Unit Description',
    unitTitleTag: 'Unit Title',
    overview: 'Overview',
    lesson: 'lesson',
    lessons: 'lessons',
    biographies: 'biographies',
    biography: 'biography',
    unitStory:'Unit story',
    story: 'Story',
    stories:'stories',
    timeline: 'timeline',
    vocabulary: 'vocabulary',
    video: 'video',
    assessment: 'assessment',
    unitSkills: 'unit skills',
    essay: 'essay',
    essentialQuestionLegend: 'Click on the brain to open your mind.',
    noContentToDisplayMessage: 'no content to display',
    assessmentDirections: 'directions',
    completeAssessmentMessage: 'Complete all questions before submitting assessment',
    completeEssayAlert: 'completed essays cannot be modified',
    submittedAssessmentAlert: 'Assessment has been submitted, You cannot modify your answers.',
    submitAssessmentMessage: 'If you are ready for your assessment to be graded, click [Ok] button to proceed.',
    failedToSubmitAssessmentMessage: 'Failed to submit assessment!',
    failedToLoadAssessmentMessage: 'Failed to load assessment.',
    publishingMessage: "Please, wait a while, content being published",
    sucessfullPublishingMessage: "Content published sucessfully",
    correctAnswerText: "You selected a correct Choice",
    wrongAnswerText:"You selected a wrong Choice",
    next: 'Next',
    ok: 'ok',
    done: 'Done',
    cancel: 'cancel',
    back: 'Back',
    submit: 'Submit',
    exit: 'exit',
    logout: 'log out',
    delete: 'delete',
    edit: 'edit',
    english: 'ENGLISH',
    spanish: 'SPANISH',

    screenSizeNotSupportedMessage: 'screen size is not supported',

    unitSkillsBanner: 'OBJECTIVES - SKILLS - TEKS AND STANDARDS',
    descriptionTag: 'Description',

    //Back office translation

    publishChangesQuestion: 'Publish changes?',
    preview: 'preview',
    keepEditing: 'keep editing',
    publish: 'publish',
    unableToSaveChangesAlert: 'Unable to save changes!',

    assessmentHeader: 'Assessment Header',
    questionPlaceHolder: 'question...',
    questionTag: 'question',
    addChoiceTag: 'add choice',
    correctChoiceTag: 'correct',
    placeHolderChoice: 'Choice...',
    choiceTag: 'Choice',
    response: 'Response Text',
    

    skillLesson: 'Skill Lesson',
    skillLessonBanner: 'Skill Lesson Banner',
    primaryText : 'Primary Text Source',
    instruction: 'Instruction',

    multipleEssaysInAssessmentAlert: 'There cannot be more than one essay per assessment.'
    ,
    multipleVennDiagramInAssessmentAlert: 'There cannot be more than one venn diagram per assessment.',
    complementaryText: 'complementary text',
    characterNamePlaceholder: 'character’s name',
    CharacterPeriodOfTime: 'character’s period of time',
    biographyMainTextPlaceHolder: 'biography’s Main text…',
    mainIlustrationTag: 'main illustration',
    mainCardTag: 'main card',
    portraitTag: 'portrait',
    portraitCaptionPlaceHolder: 'Portrait caption',
    biographyUpdatedMessage: 'Biography updated!',
    biographyUpdateErrorMessage: 'Failed To update biography component!',
    biographyNamePlaceHolder: 'Biography name...',
    storyNamePlaceHolder: 'Story name...',

    addAudioTag: 'add audio',
    wrongFileFormatAlert: 'Wrong file format!',

    backgroundTag: 'background color',
    opacityTag: 'opacity',
    invalidBackgrounColorAlert: 'Background color is invalid!',

    overviewTitleBackground: "Main title background color",
    unitDescriptionAudio: "Unit description",
    captionAudio: "Caption audio",
    imageCaptionPlaceHolder: "Image caption...",
    imageDisclaimerPlaceHolder: "Image disclaimer...",
    imageTitlePlaceHolder: "Image title...",
    imageCaptionMarginPlaceHolder: "Image caption margin",
    imageCaptionBottomMarginPlaceHolder: "Image bottom margin",
    wrongImageCaptionMarginValue: "Image caption margin must be an integer number",
    wrongImageBottomMarginValue: "Image bottom margin must be an integer number",

    essentialQuestionTag: 'essential question',
    essentialQuestionPlaceHolder: 'Brain text here...',

    fontColorTag: 'font color',
    black: 'black',
    white: 'white',
    blue: 'blue',

    shadow: 'shadow',
    originalSize: 'original size',

    reorderTag: 'reorder',

    widthSizeTag: 'Width size',
    medium: 'medium',
    large: 'large',
    alignmentTag: 'alignment',
    left: 'left',
    center: 'center',
    right: 'right',

    textAlignmentTag: 'text alignment',
    start: 'start',

    invalidUrlAlert: 'URL is invalid',

    titlePlaceHolder: "Title...",
    textPlaceHolder: 'text...',

    lessonUpdatedMessage: 'Lesson updated!',
    updateLessonErrorAlert: 'Failed To update lesson component!',
    lessonBanner: 'lesson banner',
    lessonTitlePlaceHolder: 'Enter title...',

    storyBanner: 'story banner',
    storyTitlePlaceHolder: 'Enter title...',
    storyUpdatedMessage: 'Story updated!',
    updateStoryErrorAlert: 'Failed To update story component!',
    story_label: 'Story',
    lessonStory: 'Lesson Story',

    saveChangesTag: 'Save Changes',
    savingChangesTag: 'Saving...',

    dateTag: 'Date',
    yearTag: 'year',
    eventTitleTag: 'Title',
    addEventTag: 'add entry',
    invalidDateAlert: 'Invalid date format.',
    failedToUpdateTimelineMessage: 'Failed to update Time Line.',
    timelineUpdatedMessage: 'Time Line updated.',

    addContentTag: 'add content',
    typeOfContentTag: 'Type of content',
    selectLessonAlert: 'Select a lesson to add an assessment',
    failedToAddAssessmentAlert: 'Failed to add assessment!',
    failedToEditAssessmentAlert: 'Failed to edit assessment!',

    addLessonTag: 'add',
    otherContentTag: 'other content',
    unitPeriodPlaceHolder: 'Period...',

    titleTag: 'Title',
    caption: "Caption",
    wordPlaceHolder: 'word...',
    pronunciationTag: 'Pronunciation',
    pronunciationPlaceHolder: 'pronunciation...',
    definitionTag: 'Definition',
    definitionPlaceHolder: 'definition...',
    categoryTag: 'Category',
    categoryPlaceHolder: 'i.e. noun.',
    pluralTag: 'Plural',
    pluralPlaceHolder: 'plural...',
    usageTag: 'Usage',
    usagePlaceHolder: 'usage...',

    catalogLegend: 'Drag and drop elements',
    propertiesPanelLegend: 'Select elements to edit properties',

    addUnitTag: 'add unit',

    newVocabularyWordTag: 'New vocabulary word',
    failedToUpdateVocabularyMessage: 'Failed to update vocabulary!',
    vocabularyUpdatedMessage: 'Vocabulary updated!',

    //Notifications in store
    failedToReoderLessonAlert: 'Failed to reorder lesson!',
    failedToSaveLessonAlert: 'Failed to save lesson changes!',
    failedToCreateLessonAlert: 'Failed to create lesson!',
    failedToRemoveLessonAlert: 'Failed to remove lesson!',
    failedToRenameLessonAlert: 'Failed to update lesson name!',
    failedToCreateLessonComponentAlert: 'Failed to create component!',
    failedToRemoveLessonComponentAlert: 'Failed to remove component!',
    failedToSaveLessonComponentAlert: 'Failed to save component!',
    failedToReoderLessonComponentAlert: 'Failed to reorder component!',

    failedToSaveEssentialQuestionAlert: 'Failed to save Essential Question!',

    overviewUpdatedMessage: 'Overview updated!',
    failedToUpdateOverviewAlert: 'Failed to save overview changes!',

    unitOpenerUpdatedMessage :  'Unit Opener Updated',

    failedToCreateUnitAlert: 'Failed to create unit!',
    failedToUpdateUnitAlert: 'Failed to save unit changes!',
    failedToReorderUnitAlert: 'Failed to reorder unit!',
    failedToRemoverUnitAlert: 'Failed to remove unit!',

    failedToCreateBiographyAlert: 'Failed to create biography!',
    failedToUpdateBiographyNameAlert: 'Failed to update biography name!',
    failedToUpdateBiographyAlert: 'Failed to update biography!',
    failedToRemoveBiographyAlert: 'Failed to remove biography!',

    failedToCreateStroyAlert: 'Failed to create unitstroy!',
    failedToUpdateUnitStoryNameAlert: 'Failed to update unitstory name!',
    failedToRemoveUnitStoryAlert: 'Failed to remove unitstory!',

    failedToLoadVocabularyAlert: 'Failed to load unit vocabulary',
    failedToAddVocabularyAlert: 'Failed to add vocabulary word',
    failedToRemoveVocabularyAlert: 'Failed to remove vocabulary word',

    failedToLoadAssessmentAlert: 'Failed to load assessment!',
    failedToUpdateAssessmentAlert: 'Failed to update assessment directions!',
    failedToRemoveAssessmentAlert: 'Failed to remove assessment!',

    failedToAddQuestionAlert: 'Failed to add question!',
    failedToUpdateQuestionAlert: 'Failed to update question!',
    failedToRemoveQuestionAlert: 'Failed to remove question!',
    failedToSetCorrectChoiceAlert: 'Failed to update correct choice!',
    failedToUpdateChoiceAlert: 'Failed to update choice!',
    failedToRemoveChoiceAlert: 'Failed to remove choice!',

    changesPublishedMessage: 'Changes published!',
    FailedToPublishMessage: 'Failed to publish!',

    failedToUploadImage: "Failed to upload image",

    // Component templates
    paragraphLegend: 'IMAGE AND TEXT CONTENT',
    paragraphLongNameLeft: 'Left-aligned image and text content',
    paragraphLongNameRight: 'Right-aligned image and text content',

    sectionTitleLegend: 'SECTION TITLE',
    sectionTitleLongName: 'Section Title',

    assessmentGroupLegend: 'ASSESSMENT GROUP',
    assessmentGroupLongName: 'Assessment group',

    cardLegend: 'CARD CONTENT WITH IMAGE',
    cardLongName: 'Left-aligned image and card content',

    textContentLegend: 'TEXT CONTENT',
    textContentLongName: 'Text content',

    videoContentLegend: 'VIDEO CONTENT',
    videoContentLongName: 'Video content (Url)',

    // Questions templates
    multipleChoiceLegend: 'MULTIPLE CHOICE QUESTION',
    multipleChoiceLongName: 'Multiple choice question',

    multipleChoice: "Multiple choice",

    vennDiagramLegend: 'VENN DIAGRAM',
    vennDiagramLongName: 'Venn diagram',
    addElementTag: 'add element',
    elementTag: 'element',

    essayLegend: 'ESSAY',
    essayLongName: 'Essay',

    writtenLegend: 'WRITTEN ANSWER',
    writtenLongName: 'Written Answer',

    writtenAnswer: "Written answer",

    interactiveMapLegend: 'INTERACTIVE MAP',
    interactiveMapLongName: 'Interactive map',
    interactiveLessonLegend: 'INTERACTIVE LESSON',
    interactiveLessonLongName: 'Interactive lesson',

    progressive: 'Progressive',
    horizontal: 'Horizontal',
    displayNumbers: 'Display Numbers',




    onlyImagesLegend: "ONLY IMAGE(s)",
    onlyImagesLongName: "Only image(s)",

    addImageText: 'ADD IMAGE',
    addMapText: 'ADD MAP',
    addBackgroundText: 'ADD BACKGROUND',
    editImageText: 'EDIT IMAGE',
    secondaryImageText: 'ADD SECONDARY IMAGE',
    backgroundMapText: 'BACKGROUND MAP',
    addLayerText: 'ADD LAYER',
    addMapStepText: 'ADD MAP STEP',
    addLessonStepText: 'ADD LESSON STEP',
    teacherPage: 'Teacher Page',
    propertiesTag: 'Properties',
    componentsTag: 'Components',
    layout: 'Layout',
    content: 'Content',

    step: 'step',

    //Authentication texts
    welcomeMessage: "Welcome to",
    texasHistoryName: "Story of",
    awakens: "AMERICA",
    dontHaveAccountMessage: "Don't have an account?",
    someAuthenticationMessage: "However more and more people are sick and tired of using the same sample text over and over again.",
    signupHereMessage: "SIGN UP HERE",

    signInHeaderText: "",
    signUpHeaderText: "Create now your Novatrack account",
    userNameLabelText: "Username",
    emailLabelText: "Email",
    signPasswordLabelText: "Password",
    passwordConfirmationLabelText: "Password confirmation",
    signLoginButtonText: "LOGIN",
    signForgotButtonText: "FORGOT PASSWORD",

    signupNameLabelText: "Name",
    invalidUsernameOrPassword: "Invalid username and/or password",
    userBlocked: 'User has been blocked, too many login attempts',
    emailAlreadyInUse: "Email already registered",

    class: 'Class',
    addClass: 'create new class',
    removeStudentMessage: 'Remove student?',
    addStudent: 'add student',
    classPeriod: 'Time period:',
    teacher: 'Teacher:',
    studentsNumber: 'Number of students:',
    classDetails: 'class details',
    startDate: 'Start date:',
    endDate: 'End date:',
    studentsBatch: 'Students batch',
    cvsFile: 'CSV File format',
    create: 'Create',
    username: 'Username',
    firstName: 'First name',
    lastName: 'Last name',
    birthDate: 'Birth date',
    failedToRemoveStudentAlert: 'Failed to remove student.',

    failedToUpdateClassroomAlert: 'Failed to update classroom',
    removeClassroomMessage: 'Remove classroom?',

    failedToAddStudentAlert: 'Failed to add student',
    language: 'Language',

    forgotPasswordHeaderText: 'Forgot Password',
    resetPasswordHeaderText: 'Choose a new password',

    /* classrooms management */
    middleName: "Middle name",
    secondLastName: "Second last name",
    failedToAddClassRoom: "Some fields are still empty or have invalid data",
    usersManagementUnableToLoadTeachers: "Failed to load teachers",

    written: 'Written Answer',
    goToFeedBackMessage: 'Go To Feedback',
    resetPasswordButtonText: "Reset password",
    cleanButtonText: "CLEAN",
    resetStudentPasswordButtonText: "RESET",
    successfulPasswordResetMessage: "Password reset successfully",
    failedToResetPassword: "Failed to reset password",
    confirmPasswordLabelText: "Confirm password",
    notSamePassword: "A same password must be inserted",
    report: "Report",
    feedback: "Feedback",
    scores: "Scores",
    score: "Score",
    students: "students",

    //add and update users form
    addUsersCleanButtonText: "RESET",
    addUsersButtonText: "ADD USER",
    addUsersHeaderText: "Add new users",
    addUsersFirstNameLabelText: "Name",
    addUsersMiddleNameLabelText: "Middle Name",
    addUsersLastNameLabelText: "Last Name",
    addUsersEmailAddressLabelText: "Email Address",
    addUsersBirthDateLabelText: "Birthdate",
    addUsersRoleLabelText: "Role",
    defaultSelectOption: "Select an option",
    updateUsersButtonText: "UPDATE",
    updateUsersHeaderText: "Update user",
    addUsersDistrictLabelText: "Districts",

    //users management
    usersManagementUnableToLoadUsers: "Failed loading users",
    addUsersCampusLabelText: "Campus",
    successfulAddUsersMessage: "User added successfully",
    successfulUserDeletingMessage: "User removed successfully",
    successfulUserUpdatingMessage: "User updated successfully",
    "super admin": "SUPER ADMIN",
    "district manager": "DISTRICT MANAGER",
    "campus manager": "CAMPUS MANAGER",
    "district teacher": "DISTRICT TEACHER",
    "content creator": "CONTENT CREATOR",
    student: "STUDENT",
    wantToDeleteUserQuestion: "Do you want to remove",
    users: "Users",
    usersManagementUnabledToAddUsers: "Failed to add an user",
    usersManagementUnableToLoadSchools: "Failed loading schools",
    failedToLoadDistricts: "Failed loading districts",
    failedToLoadStates: "Failed loading states",
    failedToLoadSchools: "Failed loading schools",
    usersManagementUnabledToUpdateUsers: "Failed updating the user",
    usersManagementUnabledToDeleteUsers: "Failed removing the user",
    mustSelectOption: "An option must be selected",
    mustFillNameField: "Fill in this field with at least one character",
    invalidEmailAddress: "A valid e-mail must be inserted (e.g. th@thistory.com)",
    invalidUsername: "The username's length should be between 8 and 15 characters",
    invalidPassword: "Should contains only letters and/or numbers, and have a length between 8 and 15 characters",
    shortInvalidPassword: "Only letters and/or numbers, having between 8 and 15 characters",
    invalidBirthDate: "The user should be 100 years old or lesser",
    mustFillFields: "Some fields are still empty or have invalid data",
    usernameInUse: "Username already in use",
    addUsersSecondLastNameLabelText: "Second Last Name",
    roles: "Roles",
    role: "Role",
    noContent: "There is no content to display",

    //management section
    management: "Management",
    classrooms: "Classrooms",
    states: "states",
    addDistrictsText: "Add District",
    addStateText: "Add State",
    addSchoolText: "Add School",
    addDistrictNameLabelText: "District's name",
    addStateNameLabelText: "State's name",
    addSchoolNameLabelText: "School's name",
    addButtonText: "ADD",
    invalidName: "Name already in use",
    failedToAddDistricts: "Failed to add a district",
    failedToUpdateDistricts: "Failed to update a district",
    failedToRemoveDistricts: "Failed to remove a district",
    failedToAddStates: "Failed to add states",
    failedToUpdateStates: "Failed to update states",
    failedToRemoveState: "Failed to remove states",
    failedToAddSchools: "Failed to add schools",
    failedToUpdateSchools: "Failed to update schools",
    failedToRemoveSchool: "Failed to remove schools",
    failedToLoadTelemetries: "Failed to load telemetries",
    failedToUpdateOverviewStatus: "Failed to update the overview's status",
    successfulAddDistrictsMessage: "District added sucessfully",
    successfulUpdateDistrictsMessage: "District updated sucessfully",
    successfulDistrictDeletingMessage: "District removed sucessfully",
    successfulStateDeletingMessage: "State removed sucessfully",
    successfulAddStatesMessage: "State added sucessfully",
    successfulUpdateStatessMessage: "State updated sucessfully",
    successfulSchoolDeletingMessage: "School removed sucessfully",
    successfulAddSchoolsMessage: "School added sucessfully",
    successfulUpdateSchoolsMessage: "School updated sucessfully",
    updateDistrictsText: "Update district",
    updateStatesText: "Update state",
    updateSchoolsText: "Update school",
    updateButtonText: "Update",
    wantToDeleteDistrictQuestion: "Would you like to remove the district",
    wantToDeleteStateQuestion: "Would you like to remove the state",
    wantToDeleteSchoolQuestion: "Would you like to remove the school",
    resetButtonText: "RESET",
    idleStateAlert: 'Session about to timeout. You will be automatically logged out in 1 minute. to remain logged click over this window.',
    somethingWentWrongAlert: 'Something went wrong, try again later.',
    retakeAssessmentTeacherResponse: 'Assessment updated! student should be able now to retake assessment',
    from: "From",
    to: "To",
    search: "Search",
    sectionName: "Section Name",
    numberOfVisits: "Number of visits",
    averageDuration: "Average Duration",
    /* Report translations */

    studentPerformanceHeaderText: "Performance Report",
    mostVisitedSections: "Most visited sections",
    performance: "performance",
    completedUnits: "Completed units",
    completedLessons: "Completed lessons",
    completionLevel: "Completion level",
    generalScore: "General score",
    failToLoadClassroomPerformance: "Failed to load the classroom performance",
    averageScore: "Average score",
    classroomPerformanceHeaderText: "classroom performance",

    AssessmentSubmittedNotification: '<strong>{0}</strong> just submitted an assessment from Lesson <strong>{1}</strong> in Unit <strong>{2}</strong>, click here to see it.',
    AssessmentRetakeNotification: 'You have to retake assessment from Lesson <strong>{1}</strong> in Unit <strong>{2}</strong>, click here to start',
    FeedbackCompleteNotification: 'You have been given feedback from assessment in Lesson <strong>{1}</strong>, Unit <strong>{2}</strong>',

    standardsReportTitle: 'TEKS/STANDARDS PERFORMANCE REPORT',
    standardsReport: 'Standards Report',

    both: 'Both',
    compareTag: 'Compare & Contrast',
    bank: 'Bank',
    typeInAnAnswer: "Type in an answer",
    overlay: 'Overlay',

    deleteConfirmationMessage: 'Are you sure you want to delete this {0}?',
    deleteContentMessage: 'Are you sure you want to delete this Content?',

    newTeksTag: 'New TEKS',

    sections: 'sections',

    clickNextMapLegend: 'Click NEXT to follow the map',
    clickNextLessonLegend: 'Click NEXT to follow the lesson',

    analytics: 'Analytics',

    suggestedAnswer: 'Suggested answer',

    studentAnswer: 'Student answer',

    contributorsPage: 'Contributors',

    contributorsPageBanner: 'CONTRIBUTORS PAGE',

    scopeAndSequence: 'Scope And Sequence',

    timelineDirections: 'Drag the scroll bar or click on an event to explore the timeline.',

    noBiographyContentLegend: 'There is not a Biography in this particular Lesson.',

    globalConnectionsLegend: 'GLOBAL CONNECTIONS',
    globalConnectionsLongName: 'Global Connections',
    clickOnGlobalConnections: 'Click on Global Connections to see the connections around the world',
}