export default {
    contributorsPage: '',
    serviceDeskWidget: false,
    contributorsPageComponent: '',
    // Content
    units: null,
    snackBarNotifications: [],
    notificationsBox: [],
    loadingStatus: false,
    showPreviewModeOptions: true,
    audioPlaying: '',
    videoPlaying: '',
    // NAVIGATION RELATED STATE
    breadcrumbElements: [{ text: 'UNIT 1', route: '/' }],
    currentView: '',
    // UNIT OVERVIEW AND LESSON DASHBOARD RELATED STATE
    overview: null,
    currentBlurb: null,
    currentUnit: null,
    teacherPage: null,
    currentLesson: null,
    currentStory: null,
    currentBiography: '',
     //UNIT OPENER STATE
     unitOpenerId: null,
     openerComponents:'',
     unitOpenerPreviewData: null,
     isMovedToLessonStory: false,
     readClicked: false,

    //Assessment group components
    assessmentGroupComponents: [],
    assessmentGroupArray:[],
    // ASSESSMENT RELATED STATE
    assessment: '',
    currentAssessmentSection: null,
    // SKILL LESSON RELATED STATE
    skillLesson: '',
    currentSkillLessonSection: null,
    skillLessonBuilderComponents: [],
    skillLessonCurrentQuestion:null,
    // currentQuestionPointer: [],
    city: [],
    // ######### BACK OFFICE STATE SECTION ######### 
    builderName: '',
    currentBackOfficeComponent: null,
    // LesssonBuilder
    isPreviewModeActive: false,
    isSwitchButtonDisplayed: true,
    isContentSectionActive: false,
    LessonBuilderComponents: [],
    lessonBannerComponent: '',
    //UnitStoryBuilder
    unitStoryBuilderComponents: [],
    storyBannerComponent: null,
    //UnitBuilder
    unitBuilderComponent: null,
    //UnitOverviewBuilder
    unitOverviewBuilderComponent: '',

    //UnitDashboardBuilder
    UnitDashboardComponents: [],
    lastUnitDashboardComponentExpanded: '',
    //Biography Builder
    BiographyBuilderComponent: '',
    //Vocabulary Manager
    vocabularyComponents: [],
    //Assessment Builder State
    assessmentHeader: '',
    assessmentQuestions: '',
    //Skill Lesson Builder State
    skillLessonHeader: '',
    skillLessonComponents: '',
    // Time Line Builder State
    timeLineBuilderComponent: '',
    // Time Line (Student View) state
    timeline: '',

    timeLineEvents: '',
    currentTimeLineEvent: '',
    isTimeLineEventCardDisplayed: false,

    teacherPageComponent: '',

    //Classroom management
    classrooms: [],
    selectedClassroom: '',
    newClassRoomSelectedTeacher: null,
    newClassroom: {
        name: '',
        file: ''
    },
    newStudent: {
        username: '',
        password: '',
        firstName: '',
        middleName: '',
        lastName: '',
        secondLastName: '',
        birthDate: '',
        classRoomId: ''
    },
    dealerPromise: null,
    selectedStudentForFeedBack: '',
    studentContentForFeedBack: [],
    authenticationForm: 'sign-in',

    selectedUnitForFeedback: '',
    selectedStudent: '',

    teacherGroups: {},
    studentPerformance: null,
    studentReportsCurrentSection: null,
    classRoomSubNavBarWithSectionSelect: false,
    classroomDetailsSection: '',
    currentClassroomManagementSection: '',
    classroomPerformance: [],

    //Users Management
    usersCollection: {},
    schools: [],
    districts: [],

    //Management Dashboard
    currentManagementSection: "",
    analyzedManagementSection: "",
    managementDashboardNavBarPath: {},

    managementObj: {
        currentState: null,
        states: [],
        districts: [],
        telemetries: []
    },
    vennDiagramElements: [],
    standardsReport: '',

    programPreview: [],
    unitPreviewExpanded: null,

    configurationSettings: [],
    lessonCount: 0,
    unitLessons: []
}