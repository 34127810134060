import apiClient from '@/services/ApiClient.js'

export default {
    async createComponent(component) {
        return apiClient.post('component', component)
    },
    async updateComponent(component) {
        return apiClient.put(`component/${component.id}`, component)
    }, 
    async reoderComponent(component) {
        return apiClient.patch(`component/${component.id}`, {order:component.order})
    },
    async removeComponent(component) {
        return apiClient.delete(`component/${component.id}`, component)
    },
    async reoderRoutePoint(point) {
        return apiClient.patch(`component/paragraph/route/${point.id}/order`, {number:point.number})
    },
    async addRoutePoint(paragraph) {
        return apiClient.post(`component/paragraph/${paragraph.id}/route`)
    },
    async removeRoutePoint(point) {
        return apiClient.delete(`component/paragraph/route/${point.id}`)
    },
    async updateRoutePoint(point) {
        return apiClient.put(`component/paragraph/route/${point.id}`, point)
    },
    async addChoiceToQuestion(question) {
        return apiClient.post(`component/question/${question.id}/choice`, question)
    },
    async updateCorrectAnswer(choice){
        return apiClient.patch(`component/question/choice/${choice.id}/correct`)
    },
    async updateChoiceTextInQuestion(choice){
        // console.log(choice)
        return apiClient.patch(`component/question/choice/${choice.id}/change-name`, {
            name: choice.text,
            responseText:choice.responseText,
            responseAudioId:choice.responseAudio.id,
            choiceNumber: choice.choiceNumber
        })
    },
    async removeChoiceFromQuestion(choice){
        return apiClient.delete(`component/question/choice/${choice.id}`)
    },
    async addVennDiagramElement(question){
        return apiClient.post(`component/question/${question.id}/venn-diagram-element`)
    }, 
    async removeElementFromVennDiagram(element){
        return apiClient.delete(`component/question/venn-diagram-element/${element.id}`)
    }, 
    async updateVennDiagramElement(element){
        return apiClient.put(`component/question/venn-diagram-element/${element.id}`, element)
    }, 
    async addTeksToQuestion(questionId){
        return apiClient.post(`component/question/${questionId}/teks`)
    }, 
    async removeTeksFromQuestion(teksId){
        return apiClient.delete(`component/question/teks/${teksId}`)
    },
    async assignToAssessmentGroup(payload){
        return apiClient.put(`component/${payload.componentId}/assessmentgroup`,{'assessmentGroupNumber':payload.assessmentGroupNumber})
    }
}