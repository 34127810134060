var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vocabulary-box column" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _c("audio-toggle", { attrs: { audio: _vm.vocabulary.audio || {} } })
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("span", [_vm._v(_vm._s(_vm.pronunciation))])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("span", [_vm._v(_vm._s(_vm.category))])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("span", { staticClass: "custom-scrollbar" }, [
        _vm._v(_vm._s(_vm.definition))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }