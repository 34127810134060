<template>
    <div>
        <div class="pdf-view-container"> 
            <pdf
                :src="PDFUrl"
                @num-pages="pageCount = $event"
                @page-loaded="currentPage = $event"
                style="display: block; width: 100%"
                :page="currentPage"
            ></pdf>
            <div class="row btn-container" v-if="pageCount">
                <button v-if="!isPreviewMode" class="btn jump" :class="currentPage == 1? 'secondary': 'primary'" @click="firstPage">First</button>
                <button class="btn normal" :class="currentPage == 1? 'secondary': 'primary'" @click="previousPage">Previous</button>
                <span>{{currentPage}} / {{pageCount}}</span>
                <button class="btn normal" :class="currentPage == pageCount? 'secondary': 'primary'" @click="nextPage">Next</button>
                <button v-if="!isPreviewMode" class="btn jump" :class="currentPage == pageCount? 'secondary': 'primary'" @click="lastPage">Last</button>
                <button v-if="!isPreviewMode" class="btn primary download" @click="downloadPDF">Download</button>
            </div>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
import PDFService from '@/services/PDFService.js'

export default {
    props:{
        isPreviewMode: Boolean
    },
    components: {
        pdf,
        PDFService
    },
    data() {
        return {
            currentPage: 1,
            pageCount: 0
        }
    },
    computed: {
        PDFUrl(){
            return this.getPDFUrl()
        }
    },
    methods:{
        nextPage(){
            if(this.currentPage < this.pageCount)
                this.currentPage++
        },
        previousPage(){
            if(this.currentPage > 1)
                this.currentPage--
        },
        firstPage(){
            this.currentPage = 1
        },
        lastPage(){
            this.currentPage = this.pageCount
        },
        downloadPDF(){
			this.isLoadingInformation = true
			PDFService.getPDF()
				.then((response)=> {
					const type = response.headers['content-type']
					const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = 'ScopeAndSequence-'+ Date.now() + '.pdf'
					link.click()
				})
				.finally(() => this.isLoadingInformation = false)
        }
    }
}

</script>

<style lang="less">
.pdf-view-container{
    margin: 10px;
    min-height: 300px;
    .btn-container{
        margin-top: 5px;
        position: relative;

        span{
            margin-left: 20px;
            margin-right: 10px;
        }

        .normal{
            min-width: 80px;
            margin-left: 10px;
        }

        .jump{
            min-width: 20px;
            margin-left: 10px;
        }
        .download{
            min-width: 20px;
            margin-left: 10px;
            position: absolute;
            right: 0;
        }
    }
}
</style>

