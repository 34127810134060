var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c(
      "div",
      {
        staticClass: "modal-mask custom-scrollbar",
        on: {
          click: function() {
            if (_vm.clickOutSideModalHandler) {
              _vm.clickOutSideModalHandler()
            }
          }
        }
      },
      [
        _vm.isVocabularyModal
          ? _c("div", { staticClass: "modal-mask-header" })
          : _vm._e(),
        _c(
          "div",
          {
            class: [
              {
                "modal-mask-container":
                  _vm.isVocabularyModal || _vm.isScopeAndSequence,
                centered: !_vm.isVocabularyModal
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticClass: "modal-container flex-container",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [_vm._t("default")],
                  2
                ),
                _vm.showButtons
                  ? _c("div", { staticClass: "modal-footer flex-container" }, [
                      _vm.showCancelButton
                        ? _c(
                            "button",
                            {
                              staticClass: "btn secondary",
                              on: {
                                click: function($event) {
                                  _vm.$emit("close")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("cancel")) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass: "btn primary",
                          class: [{ "with-margin-left": _vm.showCancelButton }],
                          on: { click: _vm.clickHandler }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.textButton) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm.isScopeAndSequence
                        ? _c(
                            "button",
                            {
                              staticClass: "btn remove-pdf",
                              on: { click: _vm.removePDF }
                            },
                            [_vm._v("\n            Remove\n          ")]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }