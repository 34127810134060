import AppInsightsApiClient from "@/services/AppInsightsApiClient.js";

export default {
    getEventsByTypesByFieldsByAggregation(requestObj){
        let _query = "events/";
        let _eventType = requestObj.type ? requestObj.type : "$all";
        let _applyClauses = [];

        if(requestObj.filters)
            _applyClauses.push(`filter(${requestObj.filters.join(" and ")})`);

        if(requestObj.groupBy)
            _applyClauses.push(`groupBy(${requestObj.groupBy.join(',')})`);

        if(requestObj.aggregations)
            _applyClauses.push(`aggregate(${requestObj.aggregations.join(',')})`);

        _query = _query.concat(_eventType);

        if(!requestObj.timespan)
            requestObj.timespan = "P1D";

        _query = _query.concat(`?timespan=${requestObj.timespan}`);

        if(_applyClauses.length){
            _query = _query.concat(`&$apply=${_applyClauses.join('/')}`);
        }

        return AppInsightsApiClient.get(_query);
    }
};