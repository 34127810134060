import Vue from 'vue'
import Router from 'vue-router'
import StudentRoutes from '@/routes/StudentRoutes.js'
import ContentCreatorRoutes from '@/routes/ContentCreatorRoutes.js'
import TeacherRoutes from '@/routes/TeacherRoutes.js'
import { Role } from '@/utils/Roles.js'
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [ 
    ...StudentRoutes.routes,
    ...ContentCreatorRoutes.routes,
    ...TeacherRoutes.routes, 
    {
      path: '/contributors-page',
      name: 'ContributorsPage', 
      component: () => import('@/views/ContributorsPage.vue'),
      meta: { 
        requiresAuth: true, 
        authorize: [
          Role.ContentCreator.name,
          Role.DistrictTeacher.name,
          Role.CampusManager.name,
          Role.DistrictManager.name,
          Role.SuperAdmin.name
        ], 
        breadcrumb: 'classroomBreadCrumb'
      }
    },  
    {
      path: '/signin',
      name: 'SignIn', 
      component: () => import('@/views/Authentication.vue'), 
      meta:{
        public:  true
      }
    },
    {
      path: '/newpassword',
      name: 'UpdatePassword', 
      component: () => import('@/views/Authentication.vue'), 
      meta:{
        form:'update-password', 
        public:  true
      }
    },
    {
      path: '*',
      redirect: '/'
    }

  ]
  
})
