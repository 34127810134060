<template>
    <div class="classroom-sub-navbar">
        <div :class="[{'flex-container': !withSectionSelect, 'row': withSectionSelect}]">
            <span :class="[{'smaller': withSectionSelect}]">
                {{breadcrumb}}</span>
            <div v-if="withSectionSelect" class="sections-menu">
                <label for="section">
                    {{`${withCapitalLetter($t("sections"))}:`}}</label>
                <select name="section" v-model="selectedSection">
                    <option v-for="(section, index) in sections" 
                            :key="index" 
                            :value="section.aka">
                        {{withCapitalLetter($t(section.name))}}</option>
                </select>
            </div>
            <div v-if="withSectionSelect" class="properties-panel-header">

            </div>  
        </div>
    </div>
</template>
<script>
import AuthenticationService from '@/services/AuthenticationService.js'
import StringUtils from "@/utils/StringUtils.js";

export default {
    name: 'ClassRoomSubNavbar',
    components: {
        StringUtils
    },
    computed:{
        breadcrumb(){
            return `${this.getDistrictOrSchool} > ${this.classroomName}`
        }, 
        classroomName(){
            return this.$store.state.selectedClassroom 
            ? this.$store.state.selectedClassroom.name
            : ''
        }, 
        getDistrictOrSchool(){
            let _user = AuthenticationService.getProfile();
            return _user.schoolName ? _user.schoolName : _user.districtName;
        },
        withSectionSelect(){
            return this.$store.state.classRoomSubNavBarWithSectionSelect;
        },
        selectedSection: {
            get(){
                let _stateVariable = this.classroomManagementSection === "classroomDetails" ? 
                                      "classroomDetailsSection" 
                                        : "studentReportsCurrentSection";

                return this.$store.state[_stateVariable];
            },
            set(value){
                let _stateVariable = this.classroomManagementSection === "classroomDetails" ? 
                                      "setClassroomDetailsSection" 
                                        : "setStudentReportsCurrentSection";
                
                this.$store.commit(_stateVariable, value);
            }
        },
        sections(){
            let _classroomDetailsSection = [
                { name: "students", aka: "students-list" },
                { name: "performance", aka: "classroom-performance" }, 
                { name: "standardsReport", aka: "standards-report"}
            ];
            
            let _studentReportsSection = [
                { name: "feedback", aka: "teacher-feedback" },
                { name: "performance", aka: "student-performance" },
                { name: "mostVisitedSections", aka: "most-visited-sections" }
            ];

            return this.classroomManagementSection === "classroomDetails" ? 
                    _classroomDetailsSection : _studentReportsSection;
        },
        classroomManagementSection(){
            return this.$store.state.currentClassroomManagementSection;
        }
    },
    methods: {
        withCapitalLetter(word){
            return StringUtils.withCapitalLetter(word);
        }
    }
}
</script>
<style lang="less" scoped>
.classroom-sub-navbar{
    width: 100%;
    box-shadow: 0 4px 10px 0 rgba(58,77,253,0.2),  inset 0 4px 10px 0 rgba(58,77,253,0.2);
	height: 48px;
    background-color: #fff;

    > div:first-child{
        align-items: center;

        width: 968px;
        height: 100%;

        margin: auto;
        position: relative;

        .sections-menu{
            width: 100%;
            text-align: center;

            label, select{
                display: inline-block;

                color: rgba(0,0,0,0.54);
                font-family: Roboto;
                font-size: 16px;
            }

            label{
                font-weight: bold;
            }

            select{
                width: 220px;
                margin-left: 10px;
            }
        }

        .properties-panel-header{
            min-width: 264px;
        }
    }

    span{
        height: 16px;
        text-transform: uppercase;
        color: #0A1554;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 16px;

        &.smaller{
            min-width: 200px;
        }
    }
}
</style>


