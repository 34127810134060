	<template>	
  <div class="builder-bar flex-container">	
    <div class="controls flex-container">	
      <span>{{ breadcrumb }}</span>	
      <switch-button	
        v-if="isSwitchButtonDisplayed"	
        :primaryTag="$t('content')"	
        :secondaryTag="$t('layout')"	
        :initialState="isContentSectionActive"	
        :actionHandler="switchButtonActionHandler"	
      />	
    </div>	
    <div class="button-container centered">	
      <div class="contributors-button-container">	
        <button	
          class="btn secondary contributors-button"	
          @click="goToContributorPageBuilder"	
        >	
          {{ $t("contributorsPage") }}	
        </button>	
      </div>	
      <div class="upload-btn-wrapper">	
        <button class="btn secondary disabled upload-btn">	
          {{ $t("scopeAndSequence") }}	
        </button>	
        <input type="file" class="" @change="onFileChange" />	
      </div>	
    </div>	
    <modal	
      v-if="showModal"	
      text-button="Ok"	
      :click-handler="hideVocabularyBox"	
      :clickOutSideModalHandler="hideVocabularyBox"	
      :showCancelButton="showModalCancelButton"	
      :modalType="modalType"	
    >	
      <scope-and-sequence :isPreviewMode="true"></scope-and-sequence	
    ></modal>	
  </div>	
</template>	
<script>	
import SwitchButton from "@/common/SwitchButton.vue";	
import PDFService from "@/services/PDFService.js";	
import ScopeAndSequence from "@/components/ClassRoomManagement/ScopeAndSequence.vue";	
import Modal from "@/common/Modal.vue";	
export default {	
  name: "BuilderBar",	
  components: {	
    "switch-button": SwitchButton,	
    ScopeAndSequence,	
    Modal,	
  },	
  data: function () {	
    return {	
      displayed: false,	
      TextWithLinks: [],	
      showModal: false,	
      showModalCancelButton: true,	
      modalType: "",	
    };	
  },	
  methods: {	
    showVocabularyBox() {	
      this.modalType = "scopeAndSequence";	
      this.showModalCancelButton = false;	
      this.showModal = true;	
      this.showModalCancelButton = false;	
    },	
    hideVocabularyBox() {	
      this.showModal = false;	
      this.showModalCancelButton = true;	
      this.modalType = "";	
      this.selectedWord = "";	
    },	
    switchButtonActionHandler(enabled) {	
      this.$store.commit("updateContentSectionState", enabled);	
    },	
    onFileChange(e) {	
      var files = e.target.files || e.dataTransfer.files;	
      if (!files.length) return;	
      if (!files[0].type.includes("pdf")) {	
        this.$store.commit("addSnackBarNotification", {	
          message: this.$t("wrongFileFormatAlert"),	
        });	
        return;	
      }	
      let formData = new FormData();	
      formData.append("file", files[0]);	
      this.uploadPDF(formData);	
    },	
    uploadPDF(data) {	
      this.$store.commit("updateLoadingStatus", true);	
      setTimeout(() => {	
        PDFService.uploadPDF(data)	
          .then(() => {	
            this.$store.commit("addSnackBarNotification", {	
              message: "pdf upload successfully!",	
              dismissable: false,	
            });	
            this.$store.commit("updateLoadingStatus", false);	
            this.showVocabularyBox();	
          })	
          .catch(() => {	
            this.$store.commit("addSnackBarNotification", {	
              message: "failed to upload pdf!",	
              dismissable: true,	
            });	
            this.$store.commit("updateLoadingStatus", false);	
          });	
      }, 1000);	
    },	
    goToContributorPageBuilder() {	
      this.$router.push({	
        name: "ContributorsPageBuilder",	
      });	
    },	
  },	
  computed: {	
    lessonNumber() {	
      return this.$route.query && this.$route.query.lessonNumber	
        ? this.$route.query.lessonNumber	
        : this.$store.state.lessonBannerComponent	
        ? this.$store.state.lessonBannerComponent.number	
        : "";	
    },	
    unitNumber() {	
      return this.$route.query && this.$route.query.unitNumber	
        ? this.$route.query.unitNumber	
        : this.$store.state.unitBuilderComponent	
        ? this.$store.state.unitBuilderComponent.number	
        : "";	
    },	
    builder() {	
      return this.$store.state.builderName;	
    },	
    breadcrumb() {	
      if (!this.builder) return "";	
      return this["breadcrumbFor" + this.builder];	
    },	
    breadcrumbForLessonBuilder() {	
      return `${this.$t("unit")} ${this.unitNumber} > ${this.$t("lesson")} ${	
        this.lessonNumber	
      }`;	
    },	
    breadcrumbForLessonStoryBuilder() {	
      return `${this.$t("unit")} ${this.unitNumber} > ${this.$t("lessonStory")} `;	
    },	
    breadcrumbForUnitBuilder() {	
      return `${this.$t("unit")} ${this.unitNumber} > LESSON DASHBOARD`;	
    },	
    breadcrumbForOverviewBuilder() {	
      return `${this.$t("unit")} ${this.unitNumber} > ${this.$t("overview")}`;	
    },	
    breadcrumbForUnitDashboardBuilder() {	
      return this.$t("units");	
    },	
    breadcrumbForBiographyBuilder() {	
      return `${this.$t("unit")} ${this.unitNumber} > ${this.$t("biography")}`;	
    },	
    breadcrumbForVocabularyManager() {	
      return `${this.$t("unit")} ${this.unitNumber} > ${this.$t("vocabulary")}`;	
    },	
    breadcrumbForOpenerBuilder(){
       return `${this.$t("unit")} ${this.unitNumber} > ${this.$t("opener")}`;
    }, 
    breadcrumbForStoryBuilder(){
       return `${this.$t("unit")} ${this.unitNumber} > ${this.$t("UnitStory")}`;
    },
    breadcrumbForAssessmentBuilder() {	
      return `${this.$t("unit")} ${this.unitNumber} > ${	
        // this.$route.query.lessonName == "" ? this.$t("lesson") : ""	
        this.$route.query.lessonName == "" ? "" : ""	
      }${this.$t("assessment")}`;	
     },	
    breadcrumbForTimeLineBuilder() {	
      return `${this.$t("unit")} ${this.unitNumber} > ${this.$t("timeline")}`;	
    },	
    breadcrumbForTeacherPageBuilder() {	
      return `${this.$t("unit")} ${this.unitNumber}${	
        this.lessonNumber > 0	
          ? " > " + this.$t("lesson") + " " + this.lessonNumber	
          : ""	
      } > ${this.$t("teacherPage")}`;	
    },	
    isSwitchButtonDisplayed() {	
      return this.$store.state.isSwitchButtonDisplayed;	
    },	
    isContentSectionActive() {	
      return this.$store.state.isContentSectionActive;	
    },	
  },	
};	
</script>	
<style lang="less" scoped>	
.builder-bar {	
  width: 100%;	
  box-shadow: 0 4px 10px 0 rgba(58, 77, 253, 0.2),	
    inset 0 4px 10px 0 rgba(58, 77, 253, 0.2);	
  height: 48px;	
  background-color: #ffffff;	
  position: relative;	
  .controls {	
    width: 1024px;	
    margin: auto;	
    position: relative;	
    > span {	
      height: 16px;	
      text-transform: uppercase;	
      width: auto;	
      color: #0a1554;	
      font-family: "Roboto", sans-serif;	
      font-size: 12px;	
      font-weight: bold;	
      letter-spacing: 2px;	
      line-height: 16px;	
      margin: 5px 0 0 40px;	
    }	
  }	
  .button-container {	
    display: flex;	
    right: -110px;	
    left: unset;	
    .contributors-button-container {	
      margin-right: 15px;	
      .contributors-button {	
        padding-left: 10px;	
        padding-right: 10px;	
      }	
    }	
    .upload-btn-wrapper {	
      cursor: pointer;	
      margin-right: auto;	
      position: relative;	
      overflow: hidden;	
      display: inline-block;	
    }	
    .upload-btn-wrapper input[type="file"] {	
      font-size: 12px;	
      position: absolute;	
      left: 0;	
      top: 0;	
      opacity: 0;	
      cursor: pointer;	
      z-index: 2;	
      width: 100%;	
      height: 100%;	
    }	
    .upload-btn {	
      padding-left: 10px;	
      padding-right: 10px;	
    }	
  }	
  .switch-button-control {	
    margin: auto;	
    position: absolute;	
    top: 50%;	
    left: 50%;	
    transform: translate(-50%, -50%);	
  }	
}	
</style>
