var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications flex-container" },
    _vm._l(_vm.notifications, function(notification) {
      return _c("snack-bar", {
        key: notification.timeStamp,
        attrs: { notification: notification }
      })
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }