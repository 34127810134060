export const Role = {
    Student: {
        name:'Student', 
        home: 'Home',
        roleId: 6,
        navbar: 'unit-menu-bar'
    },
    ContentCreator: {
        name:'Content Creator', 
        home: 'UnitDashboardBuilder', 
        roleId: 5,
        navbar: 'builder-bar'
    },
    DistrictTeacher: {
        name:'District Teacher', 
        home: 'ClassRoomManagement', 
        roleId: 4,
        navbar: 'classroom-nav-bar'
    },
    CampusManager: {
        name:'Campus Manager', 
        home: 'ManagementDashboard', 
        roleId: 3,
        navbar: 'management-dashboard-nav-bar',
        toAddAllowedRoles: () => {
            return[
                Role.DistrictTeacher
            ];
        },
        toManageAllowedSections: () => {
            return [
                { name: "users", aka: "users" },
                { name: "classrooms", aka: "classRooms" },
                { name: "analytics", aka: "topVisitedPagesPerRole" }
            ];
        }
    },
    DistrictManager: {
        name:'District Manager', 
        home: 'ManagementDashboard', 
        roleId: 2,
        navbar: 'management-dashboard-nav-bar',
        toAddAllowedRoles: () => {
            return [
                Role.ContentCreator,
                Role.CampusManager, 
                Role.DistrictTeacher, 
            ];
        },
        toManageAllowedSections: () => {
            return [
                { name: "users", aka: "users" },
                { name: "schools", aka: "schools" },
                { name: "analytics", aka: "topVisitedPagesPerRole" }
            ];
        }
    },
    SuperAdmin: {
        name:'Super Admin', 
        home: 'ManagementDashboard', 
        roleId: 1,
        navbar: 'management-dashboard-nav-bar',
        toAddAllowedRoles: () => {
            return [
                Role.ContentCreator,
                Role.CampusManager, 
                Role.DistrictTeacher, 
                Role.DistrictManager,
            ];
        },
        toManageAllowedSections: () => {
            return [
                { name: "users", aka: "users" },
                { name: "districts", aka: "districts" },
                { name: "states", aka: "states" },
                { name: "analytics", aka: "topVisitedPagesPerRole" }
            ];
        }
    }
}