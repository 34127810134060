import apiClient from '@/services/ApiClient.js'
export default {
    async uploadAudio(audio, language, id=0) {
        return apiClient.post('audio', audio,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'language': language,
                'id': id,
            } })
    },
    async getAudio(audio) {
        return apiClient({
            method:'get',
            url:`${process.env.VUE_APP_CDN}audios/${audio}`,
            responseType:'blob'
          })
    }, 
    async removedAudio(language, id){
        return apiClient.delete('audio',{
            headers: {
                'Content-Type': 'multipart/form-data',
                'language': language,
                'id': id,
            } })
    }
}