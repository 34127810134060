import apiClient from "./ApiClient.js";
import {notificationsHubConnection} from  '@/plugins/notifications-hub.js'

export default {
    getNotifications(){
        return apiClient.get('notification')
    }, 
    markNotificationAsRead(notification){
        return apiClient.patch(`notification/${notification.id}?isRead=true`)
    },
    removeNotification(notification){
        return apiClient.delete(`notification/${notification.id}`)
    },
    async startNotificationsHubConnection(){
        try {
            await notificationsHubConnection.start();
            // eslint-disable-next-line
            console.log("connected");
        } catch (err) {
            // eslint-disable-next-line
            console.log(err);
        }
    }, 
    setNotificationsHandler(options){
        notificationsHubConnection.on(options.messageType, function (message) {
            if(typeof(message) === "string")
                message = message.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
            
            options.handler(message);
        });
    }
};