var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "section",
        { staticClass: "headers-section" },
        [
          _c(_vm.header, { tag: "component" }),
          _vm.navbar ? _c(_vm.navbar, { tag: "component" }) : _vm._e()
        ],
        1
      ),
      _c("router-view"),
      _c("notifications-container"),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loader-container" },
            [
              _c("bounce-loader", {
                staticClass: "loader",
                attrs: { loading: _vm.loading, color: "#3A4DFD", size: "60px" }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "unresponsive-legend" }, [
        _c("span", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("screenSizeNotSupportedMessage")) +
              "\n    "
          )
        ])
      ]),
      _vm.showIdleStateModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.clearIdleTimeOut,
                "text-button": "Ok"
              },
              on: { close: _vm.logOut }
            },
            [
              _c("div", { staticClass: "idle-message" }, [
                _vm._v(_vm._s(_vm.$t("idleStateAlert")))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }