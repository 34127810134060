<template>
    <div class="vocabulary-box column">
        <div class="row">
             <span>{{title}}</span>
             <audio-toggle :audio="vocabulary.audio || {}"/>
        </div>
        <div class="row">
            <span>{{pronunciation}}</span>
        </div>
        <div class="row">
            <span>{{category}}</span>
        </div>
        <div class="row">
            <span class="custom-scrollbar">{{definition}}</span>
        </div>
    </div>
</template>
<script>
import AudioToggle from '@/common/AudioToggle.vue'
import { isObject } from 'util'
export default {
    name: 'VocabularyBox', 
    components:{
        AudioToggle
    },
    props:{
        vocabulary: Object
    },
    data: function(){
        return {
            windowCoordinates: {}
        };
    },
    computed:{
        title(){
            return isObject(this.vocabulary.name)
                ? this.vocabulary.name[this.locale]
                : this.vocabulary.name
        },
        pronunciation(){
            return isObject(this.vocabulary.pronunciation)
                ? this.vocabulary.pronunciation[this.locale]
                : this.vocabulary.pronunciation
        },
        category(){
            return isObject(this.vocabulary.category)
                ? this.vocabulary.category[this.locale]
                : this.vocabulary.category
        },
        definition(){
            return isObject(this.vocabulary.definition)
                ? this.vocabulary.definition[this.locale]
                : this.vocabulary.definition
        },
    }, 
    methods: {
        preventWindowScrolling: function(){
            window.scrollTo(this.windowCoordinates.x, 
                                this.windowCoordinates.y);
        }
    },
    mounted(){
        document.addEventListener("scroll", this.preventWindowScrolling, false);
        this.windowCoordinates.x = window.scrollX;
        this.windowCoordinates.y = window.scrollY;
    },
    beforeDestroy(){
        document.removeEventListener("scroll", this.preventWindowScrolling, false);    
    }
}
</script>
<style lang="less" scoped>
.vocabulary-box.column{
    width: auto;
    max-width: 560px;
}
.vocabulary-box{
    margin: 8px 17px;
    .row{
        margin-top: 16px;
        width: auto;
        color: rgba(0,0,0,0.6);
        line-height: 16px;
        font-size: 16px;
        letter-spacing: 0.4px;
        font-family: 'Roboto',sans-serif;
        span{
            white-space: normal;
            height: auto;
        }
    }
    .audio-toggle{
        margin-left: auto;
        align-self: center;
    }
    .row:nth-child(1){
        min-height: 24px;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 28px;
    }
    .row:nth-child(2){
        color: #3A4DFD;
        font-size: 16px;
        font-style: italic;
    }
    .row:nth-child(3){
        color: #0A1554;
        font-style: italic;
    }
    .row:nth-child(4){
        min-height: 28px;
        color: #0A1554;
        font-size: 20px;
        letter-spacing: 0.5px;
        line-height: 24px;
    }
    .row:nth-child(5){
        span{
            font-style: italic;
        }
        span:nth-child(2){
            font-weight: bold;
            margin-left: 5px;
        }
    }
    .row:nth-child(6){
        font-style: italic;
        margin-bottom: 12px;
        /* span{
            max-height: 100px;
            overflow-y: auto;
        } */
    }
}

</style>

