import apiClient from '@/services/ApiClient.js'


export default {
    async get(teacherPageId) {
        return apiClient.get(`skills/${teacherPageId}`)
    },
    async loadTeacherPageComponent(teacherPageId) {
        return apiClient.get(`skills/${teacherPageId}`)
    },
    async updateTeacherPageComponent(teacherPage) {
        return apiClient.put(`skills/${teacherPage.id}`, teacherPage)
    },
    async publishTeacherPageComponent(teacherPageId) {
        return apiClient.patch(`skills/${teacherPageId}/publish`)
    },
}