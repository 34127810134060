import apiClient from '@/services/ApiClient.js'

export default {
    async getSkillLesson(lessonId) {
        return apiClient.get(`assessment/${lessonId}`)
    },
    async createSkillLessonBuilderComponent(payload){
        return apiClient.post('component', payload)
    },
    async submitSkillLessonMCQQuestion(payload){
        return apiClient.post(`multiple-choice-answer/submit`,payload)
    },
    async submitSkillLessonVennDiagramQuestion(payload){
        return apiClient.post(`venn-diagram-answer/submit`,payload)
    },
    async submitSkillLessonEssayQuestion(payload){
        return apiClient.post(`essay-answer/submit`,payload)
    },
    async submitSkillLessonWrittenQuestion(payload){
        return apiClient.post(`written-answer/submit`,payload)

    }
}