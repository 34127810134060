var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "ui-snackbar flex-container",
        class: { "vertical-alignment": _vm.notification.message.length > 35 }
      },
      [
        _c("div", { staticClass: "ui-snackbar-text" }, [
          _vm._v(_vm._s(_vm.notification.message))
        ]),
        this.notification.dismissable
          ? _c("div", { staticClass: "ui-snackbar-action" }, [
              _c("button", { on: { click: _vm.dismiss } }, [
                _vm._v(_vm._s(_vm.notification.textButton || "dismiss"))
              ])
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }