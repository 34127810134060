<template>
  <transition name="modal">
    <div
      class="modal-mask custom-scrollbar"
      v-on:click="
        () => {
          if (clickOutSideModalHandler) {
            clickOutSideModalHandler();
          }
        }
      "
    >
      <div v-if="isVocabularyModal" class="modal-mask-header"></div>
      <div
        :class="[
          {
            'modal-mask-container': isVocabularyModal || isScopeAndSequence,
            centered: !isVocabularyModal,
          },
        ]"
      >
        <div v-on:click.stop class="modal-container flex-container">
          <div class="modal-body">
            <slot></slot>
          </div>
          <div v-if="showButtons" class="modal-footer flex-container">
            <button
              v-if="showCancelButton"
              class="btn secondary"
              @click="$emit('close')"
            >
              {{ $t("cancel") }}
            </button>
            <button
              class="btn primary"
              :class="[{ 'with-margin-left': showCancelButton }]"
              @click="clickHandler"
            >
              {{ textButton }}
            </button>
            <button
              v-if="isScopeAndSequence"
              class="btn remove-pdf"
              @click="removePDF"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import PDFService from "@/services/PDFService.js";

export default {
  name: "Modal",
  components: {
    PDFService,
  },
  props: {
    "click-handler": {
      type: Function,
      required: false,
      default: function () {},
    },
    "text-button": {
      type: String,
      required: false,
      default: "Ok",
    },
    clickOutSideModalHandler: {
      type: Function,
      required: false,
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    modalType: {
      type: String,
      required: false,
      default: "",
    },
    showButtons: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    isVocabularyModal() {
      return this.modalType == "vocabularyModal";
    },
    isScopeAndSequence() {
      return this.modalType == "scopeAndSequence";
    },
  },
  methods: {
    removePDF() {
      PDFService.removePDF().finally(() => {
        this.clickHandler();
        this.isLoadingInformation = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  // margin-top: 50px;
  height: 100%;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.3);

  .modal-mask-header {
    width: 100%;
    height: 104px;
    min-height: 104px;
  }

  .modal-mask-container {
    display: flex;
    justify-content: center;
    width: 100%;
    // height: calc(100vh - 104px);
    // overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.modal-container {
  padding: 16px;
  // margin-bottom: 72px;
  // margin-bottom: 64px !important;
  // margin: 0px auto;
  background-color: #fff;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  min-height: 120px;
  width: auto;
  max-width: 560px;
  flex-direction: column;
  // padding: 8px;
  // padding-bottom: 24px;
  margin-bottom: 0 !important;
}
.modal-header {
  height: 28px;
  width: 280px;
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
}
.modal-body {
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 28px;
  min-height: 48px;
  max-height: 582px;
  width: auto;
  min-width: 232px;
}

.modal-footer {
  margin-top: 3px;
  align-self: flex-end;
  button {
    margin-left: auto;
  }
  button:nth-child(2) {
    margin-right: 8px;
  }

  .with-margin-left {
    margin-left: 8px;
  }

  .remove-pdf {
    margin-left: 8px;
    margin-right: 8px;
  }
}
</style>


