import axios from 'axios'
import UnitService from '@/services/UnitService.js'
import UnitBuilderService from '@/services/UnitBuilderService.js'
import LessonService from '@/services/LessonService.js'
import AssessmentService from '@/services/AssessmentService.js'
import LessonBuilderService from '@/services/LessonBuilderService.js'
import UnitStoryBuilderService from '@/services/UnitStoryBuilderService.js'
import EssentialQuestionService from '@/services/EssentialQuestionService.js'
import ImageDetailsService from '@/services/ImageDetailsService.js'
import BiographyService from '@/services/BiographyService.js'
import StoryService from '@/services/StoryService.js'
import ImageService from '../services/ImageService.js';
import ContributorsPageService from '../services/ContributorsPageService.js';
import VocabularyService from '../services/VocabularyService.js';
import TimeLineService from '../services/TimeLineService.js';
import TeacherPageService from '../services/TeacherPageService.js';
import ClassRoomService from '../services/ClassRoomService.js';
import TeacherFeedBackService from '../services/TeacherFeedBackService.js';
import UserService from "@/services/UserService.js";
import AuthenticationService from "@/services/AuthenticationService.js";
import DistrictService from "@/services/DistrictService.js";
import SchoolService from "@/services/SchoolService.js";
import NotificationsService from "@/services/NotificationsService.js";
import StateService from "@/services/StateService.js"
import ReportsService from "@/services/ReportsService.js";
import AppInsightsService from "@/services/AppInsightsService.js";
import Setters from '@/assets/BackOfficeAssets/component-setters.json'
import Templates from '@/assets/BackOfficeAssets/Component-templates.json'
import QuestionTemplates from '@/assets/BackOfficeAssets/question-templates.json'
import Factory from '@/utils/ContentFactory.js'
import { i18n } from '@/plugins/i18n.js';
import { Role } from "@/utils/Roles.js";
import ComponentService from '../services/ComponentService.js'
import SkillLessonService from '../services/SkillLessonService.js';
import _ from "lodash";

export default {
  async loadContributorsPage(context, isPreviewModeActive) {
    ContributorsPageService.loadContributorsPage(isPreviewModeActive)
      .then(({ data }) => context.commit('loadContributorsPage', data))
  },
  async loadContributorsPageComponent(context) {
    ContributorsPageService.loadContributorsPageComponent()
      .then(({ data }) => context.commit('loadContributorsPageComponent', data))
  },
  // eslint-disable-next-line
  async updateContributorsPageComponent(context, payload) {
    ContributorsPageService.updateContributorsPageComponent(payload)
  },
  // eslint-disable-next-line
  async publishContributorsPageComponent(context) {
    ContributorsPageService.publishContributorsPageComponent()
  },
  async loadUnits(context, isBackOfficeSection) {
    let Service = isBackOfficeSection ? UnitBuilderService : UnitService
    Service.getUnits()
      .then(response => {
        context.commit('updateUnits', response.data)
      })
  },
  async loadCurrentUnit(context, unitId) {
    UnitService.getUnit(unitId)
      .then(response => context.commit('updateCurrentUnit', response.data))
  },
  loadOverview(context, unitId) {
    UnitService.getOverview(unitId)
      .then(response => context.commit('loadOverview', response.data))
  },
  updateOverviewStatus(context, unitId) {
    return UnitService.updateOverviewStatus(unitId);
  },
  async loadUnitSkills(context, unitId) {
    UnitService.getUnitSkills(unitId)
      .then(response => context.commit('loadUnitSkills', response.data))
  },
  loadLesson(context, lessonId) {
    return LessonService.get(lessonId)
      .then(response => context.commit('loadLesson', response.data))
  },
  loadStory(context, storyId) {
    return StoryService.get(storyId)
      .then(response => context.commit('loadStory', response.data))
  },
  async loadBiography(context, biographyId) {
    BiographyService.get(biographyId)
      .then(response => context.commit('updateCurrentBiography', response.data))
  },

  // ########## ACTIONS FOR ASSESSMENT SECTION ###########
  loadAssessment(context, lessonId) {
    return AssessmentService.getAssessment(lessonId)
      .then((response) => {
        context.commit('loadAssessment', response.data)
      });
  },
  async setMultipleChoiceQuestionStudentAnswer(context, answer) {
    AssessmentService.setMultipleChoiceQuestionStudentAnswer(answer)
      .then(() => { })
  },
  async updateEssayStudentAnswer(context, answer) {
    AssessmentService.updateEssayStudentAnswer(answer)
  },
  async updateWrittenStudentAnswer(context, answer) {
    AssessmentService.updateWrittenStudentAnswer(answer)
  },
  async setAssessmentCompleted(context, lessonId) {
    AssessmentService.setAssessmentCompleted(lessonId).then(() => {
      context.commit('setAssessmentCompleted')
    })
  },

  // ########## ACTIONS FOR SKILL LESSON SECTION ###########
  loadSkillLesson(context, lessonId) {
    return SkillLessonService.getSkillLesson(lessonId)
      .then((response) => {
        context.commit('loadSkillLesson', response.data)
      });
  },


  // ########################################################
  // ########## ACTIONS FOR UNIT BUILDER ###########
  // ########################################################
  loadUnitBuilderComponent(context, unitId) {
    return UnitBuilderService.getUnit(unitId)
      .then(response => {
        let unitBuilderComponent = {
          setters: Setters.unitBuilderComponentSetters,
          audio: response.data.descriptionAudio,
          ...response.data
        };

        unitBuilderComponent.lessons.map((e) => {
          e.fixed = false
        })
        if (unitBuilderComponent.lessons.length)
          unitBuilderComponent.lessons[0].fixed = true

        return unitBuilderComponent;
      });
  },
  async updateLessonOrder(context, lesson) {
    LessonService.updateLessonOrder(lesson)
      .then(() => { })
      .catch(() => {
        context.commit('addSnackBarNotification',
          {
            message: i18n.t('failedToReoderLessonAlert'),
            dismissable: true
          })
      })
  },
  async createLesson(context, unitId) {
    LessonService.createLesson(unitId)
      .then((response) => {
        let newLesson = {
          id: response.data.id,
          unitId: unitId,
          name: { en: '', es: '' },
          lessonTypeId: response.data.lessonTypeID,
          number: response.data.number,
          order: response.data.order,
        }
        context.commit('addLessonToUnit', newLesson)
        context.dispatch('updateLessonOrder', newLesson)
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToCreateLessonAlert') })
      })
  },
  async removeLesson(context, lesson) {
    LessonService.removeLesson(lesson.id)
      .then(() => { context.commit('removeLessonFromUnit', lesson) })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToRemoveLessonAlert') })
      })
  },
  async updateLessonName(context, lesson) {
    if (!lesson.id)
      return

    return LessonService.updateLessonName(lesson)
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToRenameLessonAlert') })
      })
  },
  async updateLessonTypeOrTitle(context, lesson) {
    if (!lesson.id)
      return

    return LessonService.updateLessonTypeOrTitle(lesson)
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToRenameLessonAlert') })
      })
  },
  updateUnit(context, unit) {
    let descriptionAudioId = unit && unit.audio ? unit.audio.id : null;
    let _unit = { ...unit, descriptionAudioId: descriptionAudioId };

    UnitBuilderService.updateUnit(_unit)
      .then(() => {
        UnitService.getUnit(_unit.id)
          .then(response => context.commit('updateCurrentUnit', response.data))
        context.commit('addSnackBarNotification', { message: 'Unit updated!', dismissable: false })
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToUpdateUnitAlert') })
      })
  },
  async publishUnitChanges(context, unitId) {
    return UnitBuilderService.publishUnitChanges(unitId)
      .then(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('changesPublishedMessage')
        })
        axios.all([UnitBuilderService.getUnits(), UnitService.getUnits()])
          .then(axios.spread(function (dashboard, units) {
            dashboard.data.map((e) => {
              e.setters = Setters.UnitDashboardSetters
              e.originalName = { es: e.name.es, en: e.name.en }
            })
            context.commit('updateUnits', units.data)
            context.commit('loadUnitDashboardComponents', dashboard.data)
          }))
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('FailedToPublishMessage'),
          dismissable: true
        })
      })

  },

  // ########################################################
  // ########## ACTIONS FOR LESSON BUILDER ###########
  // ########################################################

  loadLessonBuilderComponents(context, lessonId) {
    return LessonBuilderService.getLessonComponents(lessonId)
      .then(function (response) {

        //Global Connection
        if (response && response.data && response.data.components) {
          let components = response.data.components;

          components.forEach(i => {
            if (i.paragraph.type === "global-connections" && i.paragraph.routePoints.length > 0) {
              let num = 1;
              i.paragraph.routePoints.map(r => {
                r.number = num;
                num++;
              })
            }
          });
          response.data.components = components;
        }

        let lessonBannerComponent = {
          ...response.data,
          setters: Setters.LessonBannerComponentSetters
        }

        lessonBannerComponent.opacity = response.data.opacity || 100,
          lessonBannerComponent.essentialQuestion = response.data.essentialQuestion
          || new Factory.CreateEssentialQuestion(),
          lessonBannerComponent.id = parseInt(response.data.id)
        lessonBannerComponent.errors = []

        response.data.components.map((p) => {
          let componentTemplate = Templates.find(c => c.type === p.paragraph.type)
          let component = {
            ...componentTemplate, ...p.paragraph,
            // audio : p.paragraph.audio ? p.paragraph.audio : new Factory.CreateAudio(),
            errors: []
          }
         response.data.components.forEach((item)=>{
            item.audio = item.audio ? item.audio : new Factory.CreateAudio();
          });
          component.opacity = component.opacity ? component.opacity : 100;
          component.height = component.height ? component.height : 'auto'
          component.size = parseInt(component.size) || 12
          component.lessonId = parseInt(lessonId)
          component.images.map(e => {
            e.paragraphId = parseInt(component.id);
            e.audio = e.audio ? e.audio : new Factory.CreateAudio();
            e.title = e.title ? e.title : new Factory.CreateTextContent();
            e.disclaimer = e.disclaimer ? e.disclaimer : new Factory.CreateTextContent();
            e.caption = e.caption ? e.caption : new Factory.CreateTextContent();
            e.captionAudio = e.captionAudio ? e.captionAudio : new Factory.CreateAudio();
          })
          component.routePoints.map(e => e.setters = Setters.RoutePointSetters)
          p.paragraph = component;
          // console.log(component)
        })
        context.commit('loadLessonBuilderComponents', response.data.components)
        context.commit('loadLessonBannerComponent', lessonBannerComponent)
        context.commit('updateLessonBuilderComponentOrder')
      });
  },
  async createLessonBuilderComponent(context, component) {
    ComponentService.createComponent(component)
      .then((response) => {
        component.id = response.data.componentId;
        component.paragraph.id = response.data.paragraphId;
        context.commit('addLessonBuilderComponent', component)
        context.commit('updateLessonBuilderComponentOrder')
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToCreateLessonComponentAlert'),
          dismissable: true
        })
      })
  },
  async removeLessonBuilderComponent(context, component) {
    return ComponentService
      .removeComponent(component)
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToRemoveLessonComponentAlert'),
          dismissable: true
        })
      })
  },
  async updateLessonBuilderComponent(context, component) {
    ComponentService.updateComponent(component)
      .catch(() => { throw i18n.t('failedToSaveLessonComponentAlert') })
  },
  async reoderLessonComponent(context, component) {
    ComponentService.reoderComponent(component)
      .then(() => { })
      .catch(() => context.commit('addSnackBarNotification', {
        message: i18n.t('failedToReoderLessonComponentAlert'),
        dismissable: true
      }))
  },
  async updateLessonBanner(context, lessonBanner) {
    return LessonBuilderService.updateLessonBanner(lessonBanner)
      .catch(() => { throw i18n.t('failedToSaveLessonComponentAlert') })
  },


  // ########################################################
  // ########## ACTIONS FOR UNIT STORY BUILDER ###########
  // ########################################################

  loadUnitStoryBuilderComponents(context, storyId) {
    return UnitStoryBuilderService.getUnitStoryComponents(storyId)
      .then(function (response) {

        //Global Connection
        if (response && response.data && response.data.components) {
          let components = response.data.components;

          components.forEach(i => {
            if (i.paragraph.type === "global-connections" && i.paragraph.routePoints.length > 0) {
              let num = 1;
              i.paragraph.routePoints.map(r => {
                r.number = num;
                num++;
              })
            }
          });
          response.data.components = components;
        }

        let storyBannerComponent = {
          ...response.data,
          setters: Setters.StoryBannerComponentSetters
        }

        storyBannerComponent.opacity = response.data.opacity || 100,
          storyBannerComponent.essentialQuestion = response.data.essentialQuestion
          || new Factory.CreateEssentialQuestion(),
          storyBannerComponent.id = parseInt(response.data.id)
        storyBannerComponent.errors = []

        response.data.components.map((p) => {
          let componentTemplate = Templates.find(c => c.type === p.paragraph.type)
          let component = {
            ...componentTemplate, ...p.paragraph,
            audio: p.paragraph.audio ? p.paragraph.audio : new Factory.CreateAudio(),
            errors: []
          }
          component.opacity = component.opacity ? component.opacity : 100;
          component.height = component.height ? component.height : 'auto'
          component.size = parseInt(component.size) || 12
          component.storyId = parseInt(storyId)
          component.images.map(e => {
            e.paragraphId = parseInt(component.id);
            e.audio = e.audio ? e.audio : new Factory.CreateAudio();
            e.title = e.title ? e.title : new Factory.CreateTextContent();
            e.disclaimer = e.disclaimer ? e.disclaimer : new Factory.CreateTextContent();
            e.caption = e.caption ? e.caption : new Factory.CreateTextContent();
            e.captionAudio = e.captionAudio ? e.captionAudio : new Factory.CreateAudio();
          })
          component.routePoints.map(e => e.setters = Setters.RoutePointSetters)
          p.paragraph = component;
        })
        context.commit('loadUnitStoryBuilderComponents', response.data.components)
        context.commit('loadUnitStoryBannerComponent', storyBannerComponent)
        context.commit('updateUnitStoryBuilderComponentOrder')
      });
  },
  async createUnitStoryBuilderComponent(context, component) {
    ComponentService.createComponent(component)
      .then((response) => {
        component.id = response.data.componentId;
        component.paragraph.id = response.data.paragraphId;
        context.commit('addUnitStoryBuilderComponent', component)
        context.commit('updateUnitStoryBuilderComponentOrder')
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToCreateLessonComponentAlert'),
          dismissable: true
        })
      })
  },
  async updateUnitStoryBuilderComponent(context, lessonBuilderComponent) {
    ComponentService.updateComponent(lessonBuilderComponent)
      .catch(() => { throw i18n.t('failedToSaveLessonComponentAlert') })
  },
  async reoderUnitStoryComponent(context, component) {
    ComponentService.reoderComponent(component)
      .then(() => { })
      .catch(() => context.commit('addSnackBarNotification', {
        message: i18n.t('failedToReoderLessonComponentAlert'),
        dismissable: true
      }))
  },
  async removeUnitStoryBuilderComponent(context, component) {
    return ComponentService
      .removeComponent(component)
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToRemoveLessonComponentAlert'),
          dismissable: true
        })
      })
  },
  async updateStoryBanner(context, storyBanner) {
    return UnitStoryBuilderService.updateStoryBanner(storyBanner)
      .catch(() => { throw i18n.t('failedToSaveLessonComponentAlert') })
  },

  async createEssentialQuestion(context, essentialQuestion) {
    return EssentialQuestionService.createEssentialQuestion(essentialQuestion)
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToSaveEssentialQuestionAlert') })
      })
  },
  async updateEssentialQuestion(context, essentialQuestion) {
    return EssentialQuestionService.updateEssentialQuestion(essentialQuestion)
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToSaveEssentialQuestionAlert')
        })
      })
  },
  updateImageDetails(context, image) {
    let imageDetails = { ...image }
    if (image.audio)
      imageDetails.audioId = image.audio.id

    if (image.captionAudio)
      imageDetails.captionAudioId = image.captionAudio.id;

    if (!image.captionMargin)
      imageDetails.captionMargin = 0;

    if (!image.marginBottom)
      imageDetails.marginBottom = 0;

    imageDetails.position = image.position ? 'absolute' : 'inherit'

    return ImageDetailsService.updateImageDetails(imageDetails)
  },
  async publishLessonChanges(context, lessonId) {
    return LessonBuilderService.publishLessonChanges(lessonId)
      .then(() => {
        context.commmit('addSnackBarNotification', {
          message: i18n.t('changesPublishedMessage')
        })
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          context.commit('addSnackBarNotification', {
            message: error.response.data
          }
          )
        }
      })
  },
  async publishUnitStory(context, unitstoryId) {
    return UnitStoryBuilderService.publishUnitStory(unitstoryId)
      .then(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('changesPublishedMessage') })
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('FailedToPublishMessage'), dismissable: true })
      })
  },
  // ########################################################
  // ###### ACTIONS FOR UNIT OVERVIEW BUILDER ########
  // ########################################################
  loadUnitOverviewBuilderComponent(context, unitId) {
    return UnitService.getOverviewComponents(unitId)
      .then(response => {
        response.data.errors = [],
          response.data.setters = Setters.UnitOverviewSetters
        response.data.titleBackgroundColor = response.data.titleBackgroundColor || null;

        response.data.lessons.map((e) => {
          e.essentialQuestionOverview = e.essentialQuestionOverview
            || new Factory.CreateEssentialQuestion()
          e.errors = []
          e.setters = Setters.LessonOverviewSetters
        })
        context.commit('loadUnitOverviewBuilderComponent', response.data)
        context.commit('updateCurrentBackOfficeComponent', undefined)
      })
  },
  async saveLessonOverviewChanges(context, lesson) {
    LessonBuilderService.saveLessonOverviewChanges(lesson)
      .then(() => { })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToSaveLessonAlert') })
      })
  },
  updateUnitOverview(context, overview) {
    UnitBuilderService.updateUnitOverview(overview)
      .then(function () {
        context.commit('addSnackBarNotification', { message: i18n.t('overviewUpdatedMessage') })
        UnitService.getOverview(overview.unitId)
          .then(response => context.commit('loadOverview', response.data))
      })
      .catch(() => { context.commit('addSnackBarNotification', { message: i18n.t('failedToUpdateOverviewAlert') }) })
  },

  // ########################################################
  // ###### ACTIONS FOR UNIT OPENER BUILDER #######
  // ########################################################

  async createUnitOpener(context, unitId){
      UnitService.createUnitOpenerComponent(unitId)
      .then(response=>{
         context.commit('assignUnitOpenerId', response.data)
       })
    },
    loadUpdatedUnitOpenerData(context, unitId){
    return UnitService.getUnit(unitId)
    .then(response=>{
      let opernerId = response.data.unitOpener.id;
      return UnitService.getUnitOpenerComponents(opernerId).then(response=>{
        response.data.hotspots.map(e=>{
          e.setters = Setters.UnitHotSpotSetters;
          e.errors = [];
          e.audio = e.audio ? e.audio : new Factory.CreateAudio();
          
        })
      
        response.data.audio = response.data.audio ? response.data.audio : new Factory.CreateAudio();
        response.data.story.audio = response.data.audio ? response.data.audio : new Factory.CreateAudio();
        response.data.setters = Setters.UnitOpenerCardSetters;  
        response.data.story.setters = Setters.UnitStorySetters;
        response.data.errors = [];
        // response.data.imageId = response.data.unitOpenerBackgroundImageId;
        // response.data.imageName = response.data.unitOpenerBackgroundImageName;
        context.commit('loadUnitOpenerBuilderComponent', response.data)
        context.commit('updateCurrentBackOfficeComponent', undefined)
        context.commit('loadUnitData', response.data)
      })
       
    })
   },
  async updateUnitOpener(context, component){
    UnitService.updateUnitOpener(component)
      .then(function () {
        context.commit('addSnackBarNotification', { message: i18n.t('unitOpenerUpdatedMessage') })
        // UnitService.getUnitOpenerComponents(component.id)
        //   .then(response => context.commit('loadUnitOpenerData', response.data))
      })
      .catch(() => { context.commit('addSnackBarNotification', { message: i18n.t('failedToUpdateOverviewAlert') }) })
  },
  //  for student side  
    async loadOpener(context, unitId){
      return UnitService.getUnit(unitId)
      .then(response=>{
        context.commit('loadUnitData', response.data)
      })
    },

    async getUnitOpenerDetails(context, unitId){
      return UnitService.getUnit(unitId).then(response=>{
        // console.log(response.data.unitOpener, "opener id check")
        let opernerId = response.data.unitOpener.id;
        return  UnitService.getUnitPreview(opernerId).then(response=>{
          context.commit('loadOpenerPreviewData',response.data)
        })
      })
    },

    publishOpener(context, openerId){
      return UnitService.publishUnitOpener(openerId).then(() => {
        context.commit('addSnackBarNotification',
          { message: "UnitOpener Published " })
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          context.commit('addSnackBarNotification', {
            message: error.response.data
          }
          )
        }
      })
    },


  // ########################################################
  // ###### ACTIONS FOR UNIT DASHBOARD BUILDER #######
  // ########################################################
  async loadUnitDashboardComponents(context) {
    UnitBuilderService.getUnits()
      .then(response => {
        response.data.map((e) => {
          e.setters = Setters.UnitDashboardSetters,
            e.originalName = { es: e.name.es, en: e.name.en }
        })
        let units = response.data.sort(function (a, b) { return a.number - b.number })
        context.commit('loadUnitDashboardComponents', units)
      })
  },
  async createUnitDashboardComponent(context) {
    UnitService.createUnit()
      .then(response =>
        context.commit('createUnitDashboardComponent', {
          id: response.data.id,
          number: response.data.number,
          setters: Setters.UnitDashboardSetters,
          name: new Factory.CreateTextContent(),
          originalName: {}
        })
      )
  },
  async reoderUnitDashboardComponent(context, component) {
    UnitBuilderService.updateUnitOrder(component)
      .then(() => {
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToReorderUnitAlert'), dismissable: true })
      })
  },
  async updateUnitName(context, unit) {
    if (!unit.id)
      return

    UnitBuilderService.updateUnitName(unit)
      .then(() => {
        context.commit('addSnackBarNotification',
          { message: 'Unit updated!', dismissable: false })
        context.commit('setBuilderComponentPropertyValue', {
          component: unit, propertyName: 'originalName', value: unit.name
        })
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToUpdateUnitAlert'), dismissable: true })
      })
  },
  async createUnit(context) {
    UnitBuilderService.createUnit()
      .then(() => {
        UnitBuilderService.getUnits()
          .then(response => {
            response.data.map((e) => {
              e.setters = Setters.UnitDashboardSetters
              e.originalName = e.name
            })
            context.commit('loadUnitDashboardComponents', response.data)
          })
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToCreateUnitAlert'),
          dismissable: true
        })
      })
  },
  async removeUnitDashboardComponent(context, component) {
    UnitBuilderService.removeUnit(component)
      .then(() => {
        context.commit('removeUnitDashboardComponent', component)
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToRemoverUnitAlert'), dismissable: true })
      })
  },
  publishUnits(context) {
    return UnitBuilderService.publishUnits()
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('FailedToPublishMessage'),
          dismissable: true
        });

        return Promise.reject();
      });
  },
  // ########################################################
  // ###### ACTIONS FOR UNIT BIOGRAPHY BUILDER     ##########
  // ########################################################
  async loadBiographyBuilderComponents(context, biographyId) {
    BiographyService.getBiographyComponents(biographyId)
      .then((response) => {
        let biography = response.data

        biography.complementaryContent = {
          text: biography.complementaryText || new Factory.CreateTextContent(),
          audio: biography.audioComplementaryText || new Factory.CreateAudio(),
          setters: Setters.BiographyComplementatyTextComponentSetters
        }

        if (!biography.portrait) {
          biography.portrait = {
            id: 0,
            caption: new Factory.CreateTextContent(),
            disclaimer: new Factory.CreateTextContent()
          }
        }

        biography.portrait.audio = biography.audioPortrait
          || new Factory.CreateAudio();

        if (!biography.mainCard.essentialQuestion) {
          biography.mainCard.essentialQuestion = new Factory.CreateEssentialQuestion()
        }

        biography.mainCard.audio = biography.mainCard.audio
          ? biography.mainCard.audio
          : new Factory.CreateAudio()


        biography.media.errors = []
        biography.media.image =
          biography.media.image || { id: 0 }
        biography.portrait.setters = Setters.BiographyPortraitSetters

        biography.errors = []

        biography.setters = Setters.BiographyComponentSetters
        biography.media.setters = Setters.BiographyMediaContentSetters
        biography.mainCard.setters = Setters.BiographyMainCardSetters
        response.data.components.forEach((p) => {
          let componentTemplate = Templates.find(c => c.type === p.paragraph.type)
          let component = {
            ...componentTemplate,
            ...p.paragraph,
            // audioId: p.paragraph.audio ? p.paragraph.audio.id : 0,
            // audio: p.paragraph.audio ? p.paragraph.audio : {},
            errors: []
          }
          response.data.components.forEach((item)=>{
            item.audio = item.audio ? item.audio : new Factory.CreateAudio();
          });
          component.height = p.paragraph.height ? p.paragraph.height : 'auto'
          component.opacity = p.paragraph.opacity ? p.paragraph.opacity : 100
          component.size = parseInt(p.paragraph.size) || 12
          component.biographyId = parseInt(biographyId)
          component.images.map(e => {
            e.paragraphId = parseInt(p.paragraph.id)
            e.position = e.position === 'absolute' ? true : false,
              e.audio ? e.audio : {}
          })
          component.routePoints.map(e => e.setters = Setters.RoutePointSetters)
          p.paragraph = component;
        })
        context.commit('loadBiographyBuilderComponent', biography)
        context.commit('updateBiographyBuilderComponentOrder')
      })
      .catch(() => {
        context.commit('addSnackBarNotification', { message: 'Failed to add paragraph', dismissable: true })
      })
  },
  async createBiographyBuilderComponent(context, component) {
    ComponentService.createComponent(component)
      .then((response) => {
        component.id = response.data.componentId;
        component.paragraph.id = response.data.paragraphId;

        context.commit('addBiographyBuilderComponent', component);
        context.commit('updateBiographyBuilderComponentOrder');
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToCreateLessonComponentAlert'),
          dismissable: true
        })
      })
  },
  async removeBiographyBuilderComponent(context, Component) {
    return ComponentService
      .removeComponent(Component)
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToRemoveLessonComponentAlert'),
          dismissable: true
        })
      })

  },
  async updateBiographyBuilderComponent(context, component) {
    ComponentService.updateComponent(component)
      .catch(() => { throw i18n.t('failedToSaveLessonComponentAlert') })
  },
  async reoderBiographyComponent(context, component) {
    ComponentService.reoderComponent(component)
      .then(() => { })
      .catch(() => context.commit('addSnackBarNotification', {
        message: i18n.t('failedToReoderLessonComponentAlert'),
        dismissable: true
      }))
  },
  async createBiography(context, unitId) {
    BiographyService.createBiography(unitId)
      .then((response) => {
        let newBiography = {
          id: response.data,
          name: { en: '', es: '' },
          unitId: unitId
        }
        context.commit('addBiographyToUnit', newBiography)
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToCreateBiographyAlert'),
          dismissable: true
        })
      })
  },
  async updateBiographyName(context, biography) {
    if (!biography.id)
      return
    return BiographyService.updateBiographyName(biography)
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToUpdateBiographyNameAlert')
        })
      })
  },

  async updateBiographyContent(context, biography) {
    return BiographyService.updateBiographyContent(biography)
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToUpdateBiographyAlert') })
      })
  },
  async removeBiography(context, biography) {
    if (!biography.id)
      return
    return BiographyService.removeBiography(biography)
      .then(() => {
        context.commit('removeBiographyFromUnit', biography)
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToRemoveBiographyAlert'), dismissable: true })
      })
  },


  async createStory(context, unitId) {
    StoryService.createStory(unitId)
      .then((response) => {
        let newUnitStory = {
          id: response.data,
          title: { en: '', es: '' },
          unitId: unitId
        }
        //context.commit('addBiographyToUnit', newBiography)
        context.commit('addStoryToUnit', newUnitStory)
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToCreateStroyAlert'),
          dismissable: true
        })
      })
  },
  async updateUnitStoryName(context, story) {
    if (!story.id)
      return
    return StoryService.updateUnitStoryName(story)
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToUpdateUnitStoryNameAlert')
        })
      })
  },
  async removeUnitStory(context, unitstory) {
    if (!unitstory.id)
      return
    return StoryService.removeUnitStory(unitstory)
      .then(() => {
        context.commit('removeUnitStoryFromUnit', unitstory)
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToRemoveUnitStoryAlert'), dismissable: true })
      })
  },
  async publishBiography(context, biography) {
    return BiographyService.publishBiography(biography)
      .then(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('changesPublishedMessage') })
      })
      // .catch(() => {
      //   context.commit('addSnackBarNotification',
      //     { message: i18n.t('FailedToPublishMessage'), dismissable: true })
      // })
      .catch(function (error) {
        if (error.response && error.response.data) {
          context.commit('addSnackBarNotification', {
            message: error.response.data
          }
          )
        }
      })
  },
  async updateImageInfo(context, { imageId, info }) {
    return ImageService.updateImageInfo(imageId, info)
  },
  // ########################################################
  // ######      ACTIONS FOR Vocabulary Manager     #########
  // ########################################################
  async loadVocabulary(context, unitId) {
    VocabularyService.getVocabularyComponents(unitId)
      .then((response) => {
        response.data.forEach((e) => {
          e.audio = e.audio ? e.audio : {}
          e.setters = Setters.VocabularyComponentSetters
        })
        context.commit('loadVocabularyComponents', response.data)
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToLoadVocabularyAlert'),
          dismissable: true
        })
      })
  },
  async addVocabularyComponent(context, unitId) {
    VocabularyService.addVocabularyComponent(unitId)
      .then((response) => {
        let vocabularyComponent = {
          id: response.data,
          unitId: unitId,
          name: new Factory.CreateTextContent(),
          pronunciation: new Factory.CreateTextContent(),
          definition: new Factory.CreateTextContent(),
          category: new Factory.CreateTextContent(),
          plural: new Factory.CreateTextContent(),
          secondaryDefinition: new Factory.CreateTextContent(),
          audio: new Factory.CreateAudio(),
          setters: Setters.VocabularyComponentSetters
        }
        context.commit('addVocabularyComponent', vocabularyComponent)
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToAddVocabularyAlert'),
          dismissable: true
        })
      })
  },
  async updateVocabularyComponent(context, vocabularyComponent) {
    VocabularyService.updateVocabularyComponent(vocabularyComponent)
      .catch((error) => {
        throw error
      })
  },
  async removeVocabularyComponent(context, vocabularyComponent) {
    VocabularyService.removeVocabularyComponent(vocabularyComponent)
      .then(() => {
        context.commit('removeVocabularyComponent', vocabularyComponent)
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToRemoveVocabularyAlert'),
          dismissable: true
        })
      })
  },
  // ########################################################
  // ######      ACTIONS FOR Assessment Builder     #########
  // ########################################################
  async addAssessmentToLesson(context, lessonId) {
    return AssessmentService.addAssessmentToLesson(lessonId)
  },
  loadAssessmentComponents(context, lessonId) {
    return AssessmentService.getAssessmentComponents(lessonId)
      .then((response) => {
        let _questionsDirections = {};

        response.data.questionDirections.forEach(item => {
          item.audio = item.audio || {};
          item.setters = Setters.AssessmentHeaderDirectionSetters;
          _questionsDirections[item.questionType] = item;
        });

        context.commit('loadAssessmentHeader', {
          id: response.data.id,
          directions: response.data.directions,
          questionDirections: _questionsDirections,
          audio: response.data.audio ? response.data.audio : {},
          audioId: response.data.audioId,
          lessonId: lessonId,
          title: response.data.title,
          setters: Setters.AssessmentHeaderSetters
        })
        let questions = []
        response.data.questions.forEach((e) => {
          let questionTemplate = QuestionTemplates
            .find(t => t.type === e.type)
          let question = {
            ...e,
            ...questionTemplate,
            audio: e.audio || new Factory.CreateAudio(),
          }
          question.primarySetImage = !question.primarySetImage
            ? { id: null } : question.primarySetImage

          question.secondarySetImage = !question.secondarySetImage
            ? { id: null } : question.secondarySetImage

          questions.push(question)
        })
        context.commit('loadAssessmentQuestions', questions)
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToLoadAssessmentAlert'), dismissable: true })
      })
  },
  updateAssessmentDirections(context, assessmentHeader) {
    let _questionDirections = [];

    Object.keys(assessmentHeader.questionDirections).forEach(key => {
      _questionDirections.push(assessmentHeader.questionDirections[key]);
    });

    AssessmentService.updateAssessmentDirections({
      ...assessmentHeader,
      questionDirections: _questionDirections
    }).catch(() => {
      context.commit('addSnackBarNotification',
        { message: i18n.t('failedToUpdateAssessmentAlert') })
    })
  },
  async removeAssessment(context, lessonId) {
    AssessmentService
      .removeAssessment(lessonId)
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToRemoveAssessmentAlert'), dismissable: true })
      })
  },
  addQuestionToAssessment(context, question) {
    return AssessmentService.addQuestionToAssessment(question)
      .then((response) => {
        question.id = response.data
        question.question = { en: '', es: '' }
        context.commit('addQuestionToAssessment', question)
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToAddQuestionAlert'),
          dismissable: true
        })
      })
  },
  async updateQuestion(context, question) {
    ComponentService.updateComponent(question)
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToUpdateQuestionAlert'), dismissable: true })
      })
  },
  updateQuestionComponentOrder(context, question) {
    return ComponentService.reoderComponent(question)
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: 'Failed to update question order!',
          dismissable: true
        })
      });
  },
  removeQuestionFromAssessment(context, question) {
    return AssessmentService.removeQuestionFromAssessment(question)
      .then(() => {
        context.commit('removeQuestionFromAssessment', question)
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToRemoveQuestionAlert'),
          dismissable: true
        })
      })
  },
  async addChoiceToQuestion(context, question) {
    ComponentService.addChoiceToQuestion(question)
      .then((response) => {
        let num = 1;
        question.choices.map(r => {
                r.number = num;
                num++;
              })
        let choice = {  
          id: response.data,
          text: { en: '', es: '' },
          responseText:{en:'',es:''}, 
          responseAudio : new Factory.CreateAudio(),
          responseAudioId : 0, 
          correct: false,
          choiceNumber: num
        }
        
        context.commit('addChoiceToQuestion', {
          question: question,
          choice: choice
        })
      })
      .catch(() => {

      })
  },
  async updateCorrectAnswer(context, { question, choice }) {
    ComponentService.updateCorrectAnswer(choice)
      .then(() => {
        context.commit('updateCorrectAnswer', {
          question: question,
          choice: choice
        })
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToSetCorrectChoiceAlert'), dismissable: true })
      })
  },
  async updateChoiceTextInQuestion(context, choice) {
    ComponentService.updateChoiceTextInQuestion(choice)
    .then(()=>{})
      .catch(() => {
        context.commit('addSnackBarNotification',
          { message: i18n.t('failedToUpdateChoiceAlert'), dismissable: true })
      })
  },
  async removeChoiceFromQuestion(context, { question, choice }) {
    ComponentService.removeChoiceFromQuestion(choice)
      .then(() => {
        context.commit('removeChoiceFromQuestion', {
          question: question, choice: choice
        })
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToRemoveChoiceAlert'),
          dismissable: true
        })
      })
  },
  addTeksToQuestion({ commit }, question) {
    ComponentService.addTeksToQuestion(question.id)
      .then(({ data }) => commit('addTeksToQuestion', {
        question: question,
        teks: data
      }))
  },
  removeTeksFromQuestion({ commit }, { question, teks }) {
    ComponentService.removeTeksFromQuestion(teks.id)
      .then(() => commit('removeTeksFromQuestion', {
        question: question,
        teks: teks
      }))
  },

  // ########################################################
  // ######      ACTIONS FOR Skill Lesson Builder     #######
  // ########################################################
  loadSkillLessonBuilderComponents(context, lessonId) {
    return LessonBuilderService.getLessonComponents(lessonId)
      .then(function (response) {

        //Global Connection
        if (response && response.data && response.data.components) {
          let components = response.data.components;

          components.forEach(i => {
            if (i.paragraph && i.paragraph.type === "global-connections" && i.paragraph.routePoints.length > 0) {
              let num = 1;
              i.paragraph.routePoints.map(r => {
                r.number = num;
                num++;
              })
            }
          });
          response.data.components = components;
        }

        let lessonBannerComponent = {
          ...response.data,
          setters: Setters.LessonBannerComponentSetters
        }

        lessonBannerComponent.opacity = response.data.opacity || 100,
          lessonBannerComponent.essentialQuestion = response.data.essentialQuestion
          || new Factory.CreateEssentialQuestion(),
          lessonBannerComponent.id = parseInt(response.data.id)
        lessonBannerComponent.errors = []

        response.data.components.map((c) => {
          if (c.paragraph) {
            let componentTemplate = Templates.find(t => t.type === c.paragraph.type)
            let component = {
              ...componentTemplate, ...c.paragraph,
              audio: c.paragraph.audio ? c.paragraph.audio : new Factory.CreateAudio(),
              errors: [],
            }
            component.opacity = component.opacity ? component.opacity : 100;
            component.height = component.height ? component.height : 'auto'
            component.size = parseInt(component.size) || 12
            component.lessonId = parseInt(lessonId)
            component.images.map(e => {
              e.paragraphId = parseInt(component.id);
              e.audio = e.audio ? e.audio : new Factory.CreateAudio();
              e.title = e.title ? e.title : new Factory.CreateTextContent();
              e.disclaimer = e.disclaimer ? e.disclaimer : new Factory.CreateTextContent();
              e.caption = e.caption ? e.caption : new Factory.CreateTextContent();
              e.captionAudio = e.captionAudio ? e.captionAudio : new Factory.CreateAudio();
            })
            component.routePoints.map(e => e.setters = Setters.RoutePointSetters)
            c.paragraph = component;
          } else if (c.question) {
            let keys = Object.keys(c.question);
            keys.forEach((key) => {
              if(key != 'id' && key != 'assessmentId' && c.question[key] != null) {
                c.question = c.question[key];
              }
            });

            let questionTemplate = QuestionTemplates
              .find(t => t.type === c.question.type)
            let question = {
              ...c.question,
              ...questionTemplate,
              audio: c.question.audio || new Factory.CreateAudio(),
              primarySourceAudio : c.question.primarySourceAudio ? c.question.primarySourceAudio : new Factory.CreateAudio(),
              primarySourceAudioId : c.question.primarySourceAudio ? c.question.primarySourceAudio.id : 0,
              instructionAudio : c.question.instructionAudio ? c.question.instructionAudio : new Factory.CreateAudio(),
              instructionAudioId : c.question.instructionAudio ? c.question.instructionAudio.id : 0
            }
            
            if(question.type === "multiple-choice"){
              question.choices.map((c)=>{
                c.responseAudio = c.responseAudio ? c.responseAudio : new Factory.CreateAudio()
              })
            }
            question.primarySetImage = !question.primarySetImage
              ? { id: null } : question.primarySetImage

            question.secondarySetImage = !question.secondarySetImage
              ? { id: null } : question.secondarySetImage
            c.question = question
          }
            
        })
        context.commit('loadLessonBuilderComponents', response.data.components)
        context.commit('loadLessonBannerComponent', lessonBannerComponent)
        context.commit('updateLessonBuilderComponentOrder')
        context.commit('loadAssessmentGroups',response.data.assessmentGroups)
      });
  },
  async createSkillLessonBuilderComponent(context, component) {
    return ComponentService.createComponent(component)
      .then((response) => {
        if (component && component.question) {
          component.id = response.data.componentId
          component.question.id = response.data.questionId
          component.question.question = { en: '', es: '' }
          component.question.primaryTextSource = {en : '', es: ''},
          component.question.instruction = {en : '', es: ''},
          context.commit('addComponentToSkillLesson', component)
          context.commit('updateLessonBuilderComponentOrder')
        } else {
          component.id = response.data.componentId
          component.paragraph.id = response.data.paragraphId
          context.commit('addComponentToSkillLesson', component)
          context.commit('updateLessonBuilderComponentOrder')
        }
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToCreateLessonComponentAlert'),
          dismissable: true
        })
      })
  },
  assignComponentToAssessmentGroup(context, payload){
     return ComponentService.assignToAssessmentGroup(payload).then(()=>{
     })
  },
  async publishSkillLesson(context,lessonId){
    return LessonBuilderService.publishSkillLessonChanges(lessonId)
    .then(() => {
      context.commmit('addSnackBarNotification', {
        message: i18n.t('changesPublishedMessage')
      })
    })
    .catch(function (error) {
      if (error.response && error.response.data) {
        context.commit('addSnackBarNotification', {
          message: error.response.data
        }
        )
      }
    })
  },
  async submitMultipleChoice(context,payload){
    // console.log(payload)
    return SkillLessonService.submitSkillLessonMCQQuestion(payload)
  },
  async submitVennDiagramQuestion(context, payload){
    return SkillLessonService.submitSkillLessonVennDiagramQuestion(payload)
  },
  async submitEssayQuestion(context,payload){
      return SkillLessonService.submitSkillLessonEssayQuestion(payload)
  },
  async submitWrittenQuestion(context,payload){
      return SkillLessonService.submitSkillLessonWrittenQuestion(payload)
  },
  // ########################################################
  // ######      ACTIONS FOR Time Line Builder     #########
  // ########################################################
  async loadTimeLineBuilderComponent({ commit }, unitId) {
    TimeLineService.getTimeLineComponents(unitId)
      .then(({ data }) => {
        if (!data.backgroundColor)
          data.backgroundColor = ''
        data.errors = []
        data.setters = Setters.TimeLineBuilderComponentSetters
        data.blurbs.map((e, index) => {
          e.setters = Setters.EventComponentSetters
          e.order = index + 1
          e.audio = e.audio || new Factory.CreateAudio()
        })
        commit('loadTimeLineBuilderComponent', data)
      })

  },
  async createTimeLine(context, unitId) {
    return TimeLineService.createTimeLine(unitId)
  },
  async removeTimeLine(context, unitId) {
    return TimeLineService.removeTimeLine(unitId)
  },
  async publishTimeLine(context, timeLineId) {
    return TimeLineService.publishTimeLine(timeLineId)
  },
  async addTimeLineEvent({ commit }, timelineId) {
    return TimeLineService.addTimeLineEvent(timelineId)
      .then(({ data }) => {
        let event = new Factory.CreateTimeLineEvent(data, Setters.EventComponentSetters)
        commit('addTimeLineEvent', event)
        commit('sortTimeLineEvents')
      })
  },
  async removeTimeLineEvent({ commit }, event) {
    TimeLineService.removeTimeLineEvent(event)
      .then(() => {
        commit('removeTimeLineEvent', event)
        commit('sortTimeLineEvents')
      })
  },
  async updateTimeline({ commit }, timeline) {
    commit('sortTimeLineEvents')
    TimeLineService.updateTimeline(timeline)
  },
  // ########################################################
  // ######    ACTIONS FOR Time Line Student View  ##########
  // ########################################################

  async loadTimeLine(context, unitId) {
    TimeLineService.getTimeLine(unitId)
      .then((response) => {
        context.commit('loadTimeLine', response.data)
      })
  },

  // ########################################################
  // ######      ACTIONS FOR interactive map       ##########
  // ########################################################
  async updateRoutePointOrder(context, point) {
    ComponentService.reoderRoutePoint(point)
      .catch(() => {
        context.commit('addSnackBarNotification',
          {
            message: i18n.t('failedToReoderLessonComponentAlert'),
            dismissable: true
          })
      })
  },
  async addRoutePoint(context, component) {
    ComponentService.addRoutePoint(component.paragraph)
      .then((response) => {
        let point = {
          id: response.data.id,
          number: response.data.number,
          content: new Factory.CreateTextContent(),
          audio: new Factory.CreateAudio(),
          images: [],
          setters: Setters.RoutePointSetters
        }
        context.commit('addRoutePoint', { component: component, point: point })
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          {
            message: i18n.t('failedToCreateLessonComponentAlert'),
            dismissable: true
          })
      })
  },
  sortRoutePoints(component) {
    let routes = component.routePoints;
    let num = 1;
    routes.sort(function (a, b) {
      return a.id - b.id
    })
    routes.map(route => {
      route.number = num;
      num++;
    });
    component.routePoints = routes;
  },
  async removeRoutePoint(context, { map, point }) {
    ComponentService.removeRoutePoint(point)
      .then(() => {
        context.commit('removeRoutePoint', { component: map, point: point })
      })
      .catch(() => {
        context.commit('addSnackBarNotification',
          {
            message: i18n.t('failedToRemoveLessonComponentAlert'),
            dismissable: true
          })
      })
  },
  async updateRoutePoint(context, point) {
    ComponentService.updateRoutePoint(point)
      .catch(() => {
        context.commit('addSnackBarNotification',
          {
            message: i18n.t('failedToSaveLessonComponentAlert'),
            dismissable: true
          })
      })
  },

  // teacher page
  async loadTeacherPageComponent(context, teacherPageId) {
    TeacherPageService.loadTeacherPageComponent(teacherPageId)
      .then(({ data }) => {
        context.commit('loadTeacherPageComponent', data)
      })
      .catch(() => {
        context.commit('addSnackBarNotification', {
          message: i18n.t('failedToSaveLessonComponentAlert'),
          dismissable: true
        })
      }
      )
  },
  async updateTeacherPageComponent(context, component) {
    TeacherPageService.updateTeacherPageComponent(component)
  },
  async publishTeacherPageComponent(context, teacherPageId) {
    return TeacherPageService.publishTeacherPageComponent(teacherPageId)
  },
  async loadTeacherPage(context, teacherPageId) {
    TeacherPageService.get(teacherPageId)
      .then(({ data }) => {
        context.commit('loadTeacherPage', data)
      })
  },
  // classroom management actions
  loadClassRooms({ commit }) {
    let _user = AuthenticationService.getProfile();
    let _requestObj = {
      apiPath: "",
      id: null
    };

    switch (_user.roleName) {
      case Role.DistrictTeacher.name: {
        _requestObj.apiPath = "GetByTeacherId";
        _requestObj.id = _user.id;
        break;
      }

      case Role.CampusManager.name: {
        _requestObj.apiPath = "GetBySchoolId";
        _requestObj.id = _user.schoolId;
        break;
      }

      case Role.DistrictManager.name: {
        _requestObj.apiPath = "GetByDistrictId";
        _requestObj.id = sessionStorage.getItem("districtId");
        break;
      }

      default: {
        _requestObj = null;
        break;
      }
    }

    return ClassRoomService.loadClassRooms(_requestObj)
      .then((response) => commit('loadClassRooms', response.data))
      .catch((error) => {
        commit('addSnackBarNotification', {
          message: error.response ? error.response.data.message
            : 'failed to load classrooms'
        })
      }
      )
  },
  async addClassRoom({ commit, dispatch }, classroom) {
    ClassRoomService.addClassRoom(classroom)
      .then(() => dispatch('loadClassRooms'))
      .catch((error) => {
        commit('addSnackBarNotification', {
          message: error.response.data.message || 'failed to add classrooms'
        })
      })
  },
  async removeStudentFromClassroom({ commit }, { student, classroom }) {
    ClassRoomService.removeStudentFromClassroom(student)
      .then(() => {
        commit('removeStudentFromClassroom', { student, classroom })
      })
      .catch(() => {
        commit('addSnackBarNotification', {
          message: i18n.t('failedToRemoveStudentAlert')
        })
      })
  },
  async updateClassroom({ commit }, classroom) {
    ClassRoomService.updateClassroom(classroom)
      .then(({ data }) => {
        let savedStudents = data.students.filter(e => e.isSaved)
        let unsavedStudents = data.students.filter(e => !e.isSaved)
        let message = 'Unsaved students:'
        unsavedStudents.forEach(e => {
          message += ' ' + e.userName + ','
        })
        commit('addStudentsToClassroom', savedStudents)

        if (unsavedStudents.length)
          commit('addSnackBarNotification', {
            message: message
          });
      })
      .catch(() => {
        commit('addSnackBarNotification', {
          message: i18n.t('failedToUpdateClassroomAlert')
        })
      })
  },
  async removeClassroom({ commit }, classroom) {
    ClassRoomService.removeClassroom(classroom)
      .then(() => {
        commit('removeClassroom', classroom)
      })
      .catch(() => {
        commit('addSnackBarNotification', {
          message: i18n.t('failedToRemoveClassroomAlert')
        })
      })
  },
  async addStudent({ commit }, { student, classroom }) {
    ClassRoomService.addStudent(student)
      .then((response) => {
        commit('addStudent', { student: response.data, classroom: classroom })
      })
      .catch(() => {
        commit('addSnackBarNotification', {
          message: i18n.t('failedToAddStudentAlert')
        })
      })
  },
  async changeStudentLanguage({ commit }, { student, language }) {
    ClassRoomService.changeStudentLanguage(student.id, language)
      .then(() => commit('updateStudentLanguage', { user: student, language: language }))
  },
  async changeStudentAudioLanguage({ commit }, { student, language }) {
    ClassRoomService.changeStudentAudioLanguage(student.id, language)
      .then(() => commit('updateStudentAudioLanguage', { user: student, language: language }))
  },
  async setLessonCompleted({ commit }, lesson) {
    LessonService.setCompleted(lesson)
      .then(() => {
        commit('setLessonCompleted', lesson)
      })
  },
  async getStudentContentForFeedBack({ commit }, studentId) {
    commit('updateLoadingStatus', true)
    TeacherFeedBackService.getStudentContent(studentId)
      .then(({ data }) => {
        let units = data.sort((firstElem, secondElem) => {
          return firstElem.number - secondElem.number;
        })
        commit('uploadStudentContentForFeedBack', units)
        commit('updateLoadingStatus', false)
      })
  },
  async setTeacherFeedBack(context, answer) {
    TeacherFeedBackService.setTeacherFeedBack(answer)
  },
  retakeAssessment({ commit }, { lessonId, studentId }) {
    return TeacherFeedBackService.retakeAssessment(lessonId, studentId)
      .then(() => {
        commit('addSnackBarNotification', {
          message: i18n.t('retakeAssessmentTeacherResponse')
        })
      })
      .catch(() => {
        commit('addSnackBarNotification', {
          message: i18n.t('somethingWentWrongAlert')
        })
      })
  },
  getTeacherGroups(context) {
    let _user = AuthenticationService.getProfile();
    let _requestObj = {
      apiPath: "",
      id: null
    };

    switch (_user.roleName) {
      case Role.CampusManager.name: {
        _requestObj.apiPath = "GetBySchoolId";
        _requestObj.id = _user.schoolId;
        break;
      }

      case Role.DistrictManager.name: {
        _requestObj.apiPath = "GetByDistrictId";
        _requestObj.id = sessionStorage.getItem("districtId");
        break;
      }

      default: {
        _requestObj = null;
        break;
      }
    }

    return UserService.getUsers(_requestObj)
      .then(response => {
        let _teachers = _.filter(response.data,
          item => item.roleId === Role.DistrictTeacher.roleId.toString());
        let _teacherGroups = _.groupBy(_teachers, item => item.schoolName);

        context.commit("setTeacherGroups", _teacherGroups);
      }, () => Promise.reject("usersManagementUnableToLoadTeachers"));
  },
  setDealerPromise(context, value) {
    if (value === null) {
      context.commit("setDealerPromise", null);
      return;
    }

    let _promiseObj = {};

    _promiseObj.promise = new Promise(function (resolve, reject) {
      _promiseObj.resolve = resolve;
      _promiseObj.reject = reject;
    });

    context.commit("setDealerPromise", _promiseObj);
  },
  checkUsernameAvailabality(context, username) {
    return UserService.getByUsername(username)
      .then(() => {
        context.commit("resolveOrRejectPromise", {
          method: "reject",
        });
      }, error => {
        context.commit("resolveOrRejectPromise", {
          method: "resolve"
        });

        return Promise.reject(error);
      });
  },
  checkDistrictNameAvailabality(context, districtName) {
    return DistrictService.getByName(districtName)
      .then(() => {
        context.commit("resolveOrRejectPromise", {
          method: "reject",
        });
      }, error => {
        context.commit("resolveOrRejectPromise", {
          method: "resolve"
        });

        return Promise.reject(error);
      });
  },
  checkStateNameAvailabality(context, stateName) {
    return StateService.getByName(stateName)
      .then(() => {
        context.commit("resolveOrRejectPromise", {
          method: "reject",
        });
      }, error => {
        context.commit("resolveOrRejectPromise", {
          method: "resolve"
        });

        return Promise.reject(error);
      });
  },
  checkSchoolNameAvailabality(context, schoolName) {
    return SchoolService.getByName(schoolName)
      .then(() => {
        context.commit("resolveOrRejectPromise", {
          method: "reject",
        });
      }, error => {
        context.commit("resolveOrRejectPromise", {
          method: "resolve"
        });

        return Promise.reject(error);
      });
  },
  resetStudentPassword(context, requestObj) {
    return ClassRoomService.resetPassword(requestObj)
      .then(null, () => {
        return Promise.reject("failedToResetPassword");
      });
  },
  getClassroomPerformance(context, classroomId) {
    return ReportsService.getClassroomPerformance(classroomId)
      .then(({ data }) => {
        context.commit("setClassroomPerformance", data);
      }, () => {
        return Promise.reject("failToLoadClassroomPerformance");
      });
  },

  // ########################################################
  // ######      ACTIONS FOR USERS MANAGEMENT      ##########
  // ########################################################

  getUsers(context) {
    let _user = AuthenticationService.getProfile();
    let _requestObj = {
      apiPath: "",
      id: null
    };

    switch (_user.roleName) {
      case Role.CampusManager.name: {
        _requestObj.apiPath = "GetBySchoolId";
        _requestObj.id = _user.schoolId;
        break;
      }

      case Role.DistrictManager.name: {
        _requestObj.apiPath = "GetByDistrictId";
        _requestObj.id = sessionStorage.getItem("districtId");
        break;
      }

      default: {
        _requestObj = null;
        break;
      }
    }

    return UserService.getUsers(_requestObj)
      .then(users => {
        let _collections = {};
        let _roleId = 0;

        let _roleNameWithNoSpaces = _user.roleName.replace(/\s/g, '');
        let _toManageAllowedRoles = Role[_roleNameWithNoSpaces].toAddAllowedRoles();

        _toManageAllowedRoles = [
          ..._toManageAllowedRoles,
          Role[_roleNameWithNoSpaces]
        ];

        try {
          users.data
            .filter(elem =>
              _toManageAllowedRoles.some(item =>
                item.roleId === parseInt(elem.roleId)))
            .forEach(elem => {
              if (!_collections[_roleId = parseInt(elem.roleId)]) {
                let _roleName = "";

                Object.values(Role).forEach(item => {
                  if (item.roleId === _roleId)
                    _roleName = item.name;
                });

                _collections[_roleId] = { users: [], roleName: _roleName };
              }

              _collections[_roleId].users.push(elem);
            });
        }
        catch (exception) {
          return Promise.reject("usersManagementUnableToLoadUsers");
        }

        context.commit("setUsersCollection", _collections);
      }, () => Promise.reject("usersManagementUnableToLoadUsers"));
  },
  addNewUser(context, newUser) {
    return UserService.addNewUser(newUser)
      .then(newUser => {
        context.commit("addNewUser", newUser.data);
      }, () => Promise.reject("usersManagementUnabledToAddUsers"));
  },
  getSchools(context) {
    let _user = AuthenticationService.getProfile();
    let _requestObj = null;

    switch (_user.roleName) {
      case Role.DistrictManager.name: {
        _requestObj = {
          apiPath: "GetByDistrictId",
          id: sessionStorage.getItem("districtId")
        };

        break;
      }

      case Role.SuperAdmin.name: {
        _requestObj = { apiPath: "" };

        break;
      }

      default: {
        break;
      }
    }

    return SchoolService.getSchools(_requestObj)
      .then(schools => {
        context.commit("setSchools", schools.data);
      }, () => Promise.reject("usersManagementUnableToLoadSchools"));
  },
  getDistricts(context) {
    return DistrictService.getDistricts()
      .then(response => {
        context.commit("setDistricts", response.data);
      }, () => Promise.reject("failedToLoadDistricts"));
  },
  editUser(context, modifiedUser) {
    return UserService.editUser(modifiedUser)
      .then(updatedUser => {
        context.commit("updateOrDeleteUser", {
          targetUser: updatedUser.data,
          updateOrDelete: true
        });
      }, () => Promise.reject("usersManagementUnabledToUpdateUsers"));
  },
  deleteUser(context, userToDelete) {
    return UserService.deleteUser(userToDelete)
      .then(response => {
        context.commit("updateOrDeleteUser", {
          targetUser: {
            ...response.data,
            roleId: userToDelete.roleId
          },
          updateOrDelete: false
        });
      }, () => Promise.reject("usersManagementUnabledToDeleteUsers"));
  },

  // ##############################################################################
  // #########   ACTIONS FOR STATES, DISTRICTS, SCHOOLS AND ANALYTICS MANAGEMENT ##
  // ##############################################################################

  getStates(context) {
    return StateService.get()
      .then(({ data }) => {
        context.commit("setStates", data);
        return data;
      }, () => {
        return Promise.reject("failedToLoadStates");
      });
  },
  getDistrictsByState(context, stateId) {
    return StateService.getById(stateId)
      .then(({ data }) => {
        context.commit("setDistricts", data.districts);
      }, () => {
        return Promise.reject("failedToLoadDistricts");
      });
  },
  addNewDistrict(context, newDistrict) {
    return DistrictService.addDistrict(newDistrict)
      .then(({ data }) => {
        if (newDistrict.stateId === newDistrict.currentState)
          context.commit("addNewDistrict", data);
      }, () => {
        return Promise.reject("failedToAddDistricts");
      });
  },
  updateDistrict(context, updatedDistrict) {
    let _action = updatedDistrict.stateId === updatedDistrict.oldStateId ?
      "updateDistrict" : "removeDistrict";

    return DistrictService.updateDistrict(updatedDistrict)
      .then(({ data }) => {
        context.commit(_action, data);
      }, () => {
        return Promise.reject("failedToUpdateDistricts");
      });
  },
  deleteDistrict(context, removedDistrict) {
    return DistrictService.removeDistrict(removedDistrict)
      .then(({ data }) => {
        context.commit("removeDistrict", data);
      }, () => {
        return Promise.reject("failedToRemoveDistricts");
      });
  },
  addNewState(context, newState) {
    return StateService.addState(newState)
      .then(({ data }) => {
        context.commit("addNewState", data);
      }, () => {
        return Promise.reject("failedToAddStates");
      });
  },
  updateState(context, updatedState) {
    return StateService.updateState(updatedState)
      .then(({ data }) => {
        context.commit("updateState", data);
      }, () => {
        return Promise.reject("failedToUpdateStates");
      });
  },
  deleteState(context, removedState) {
    return StateService.deleteState(removedState)
      .then(({ data }) => {
        context.commit("removeState", data);
      }, () => {
        return Promise.reject("failedToRemoveState");
      });
  },
  getSchoolsByDistrict(context, districtId) {
    return SchoolService.getByDistrict(districtId)
      .then(({ data }) => {
        context.commit("setSchools", data);
      }, () => {
        return Promise.reject("failedToLoadSchools");
      });
  },
  addNewSchool(context, newSchool) {
    return SchoolService.addSchool(newSchool)
      .then(({ data }) => {
        context.commit("addSchool", data);
      }, () => {
        return Promise.reject("failedToAddSchools");
      });
  },
  updateSchool(context, updatedSchool) {
    return SchoolService.updateSchool(updatedSchool)
      .then(({ data }) => {
        context.commit("updateSchool", data);
      }, () => {
        return Promise.reject("failedToUpdateSchools");
      });
  },
  deleteSchool(context, removedSchool) {
    return SchoolService.deleteSchool(removedSchool)
      .then(({ data }) => {
        context.commit("removeSchool", data);
      }, () => {
        return Promise.reject("failedToRemoveSchool");
      });
  },
  getTopVisitedSections(context, filters) {
    let _filtersCollection = [];

    Object.keys(filters.customDimensions)
      .forEach(key => {
        _filtersCollection.push(`customDimensions/${key} eq '${filters.customDimensions[key]}'`);
      });

    let _requestObj = {
      type: "pageViews",
      timespan: filters.timespan,
      filters: _filtersCollection,
      groupBy: ["pageView/name"],
      aggregations: [
        "$count as counter",
        "pageView/duration with average as averageDuration"
      ]
    }

    AppInsightsService.getEventsByTypesByFieldsByAggregation(_requestObj)
      .then(({ data }) => {
        if (data.value)
          data.value.sort((firstElem, secondElem) => {
            let _diff = firstElem.performanceCounter.counter
              - secondElem.performanceCounter.counter;

            return -_diff;
          });
        else
          data.value = [];

        context.commit("setTelemetries", data.value);
      }, () => {
        return Promise.reject("failedToLoadTelemetries");
      });
  },

  // ############################################
  // #########   ACTIONS FOR REPORTS   ##########
  // ############################################

  getStudentPerformance(context, studentId) {
    return ReportsService.getStudentPerformance(studentId)
      .then(({ data }) => {
        data.sort((firstElem, secondElem) => {
          return firstElem.number - secondElem.number;
        });

        context.commit("setStudentPerformance", data);
      }, () => {
        return Promise.reject("failedToLoadStudentPerformance");
      });
  },

  loadNotifications({ commit }) {
    return NotificationsService.getNotifications()
      .then((response) => {
        commit('loadNotificationsInBox', response.data)
      })
  },
  removeNotificationFromBox({ commit }, notification) {
    return NotificationsService.removeNotification(notification)
      .then(() => {
        commit('removeNotificationFromBox', notification)
      })
  },
  markNotificationAsRead({ commit }, notification) {
    return NotificationsService.markNotificationAsRead(notification)
      .then(() => {
        commit('markNotificationAsRead', notification)
      })
  },

  addVennDiagramElement({ commit }, question) {
    return ComponentService.addVennDiagramElement(question)
      .then(({ data }) => {
        let element = {
          id: data,
          content: {
            en: null,
            es: null
          },
          correctAnswerSet: "empty"
        }
        commit('addVennDiagramElement', { question: question, element: element })
      })
      .catch(() => {
        commit('addSnackBarNotification', {
          message: i18n.t('somethingWentWrongAlert')
        })
      })
  },
  removeElementFromVennDiagram({ commit }, { question, element }) {
    return ComponentService.removeElementFromVennDiagram(element)
      .then(() => {
        commit('removeElementFromVennDiagram', { question: question, element: element })
      })
  },
  async updateVennDiagramElement(context, element) {
    await ComponentService.updateVennDiagramElement(element)
  },
  async setVennDiagramStudentAnswer(context, answer) {
    return AssessmentService.setVennDiagramStudentAnswer(answer)
  },
  async loadStandardsReport({ commit }, classroomId) {
    return ClassRoomService.loadStandardsReport(classroomId)
      .then(({ data }) => commit('loadStandardsReport', data))
  },
  async loadProgramPreview({ commit }) {
    commit('updateLoadingStatus', true)
    return UnitService.loadProgramPreview()
      .then(response => {
        commit('loadProgramPreview', response.data)
        commit('updateLoadingStatus', false)
      })
  },

  async configurationSettings({ commit }, payload) {
    commit('updateLoadingStatus', true)
    return LessonService.configurationSettings(payload)
      .then(response => {
        // let data = response.data.map((element, index) => Object.assign(element, { lessonType: { en: index, es: index } }))
        commit('configurationSettings', response.data)
        commit('updateLoadingStatus', false)
      })
  },
  updatingLesson({ commit }, payload = null) {
    payload = {
      component: this.$store.getters.currentUnit,
      lessons: this.$store.getters.unitLessons,
    };
    commit("updateLessonsList", payload);
  },
}