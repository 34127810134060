import AppInsights from "@/AppInsights.js";
import { Role } from '@/utils/Roles.js'
import AuthenticationService from "@/services/AuthenticationService.js"

export default{
    data: function(){
      return {
          maxWidth: 700
      }
    },
    computed:{
        locale(){
            return this.$i18n.locale
        }, 
        isLoadingInformation:{
            get(){
                return this.$store.state.loadingStatus
            }, 
            set(value){
                this.$store.commit('updateLoadingStatus', value)
            }
        },
        serviceDeskWidget:{
            get(){
                return this.$store.state.serviceDeskWidget
            }, 
            set(value){
                this.$store.commit('updateServiceDeskWidgetAdded', value)
            }
        },
        deleteIcon(){
            return require('@/assets/icons/ic_delete.svg')
        },
        imageStyles(){
          return{
            'max-width' : `${this.maxWidth}px`
          }
        }, 
        profile(){
           return AuthenticationService.getProfile()
        }
    },
    methods:{
        getImageUrl(image){
            if(image === 0 || !image)
                return process.env.VUE_APP_DEFAULT_IMAGE
            return `${process.env.VUE_APP_CDN}images/${image}`
        },
        getPDFUrl(){
            return `${process.env.VUE_APP_BASE_URI}scope-and-sequence`
        },
        startTrackingPage(options){
            AppInsights.insightsInstance.startTrackPage(options.sectionName);
        },
        stopTrackingPage(options){
            AppInsights.insightsInstance.stopTrackPage(options.sectionName);
        }, 
        getImageWidth({ path }){
            let image = path[0];
          image.width > 700
            ? this.maxWidth = 700
            : this.maxWidth = image.width
        }
    },
    filters:{
        round(value){
            return value !== Math.floor(value) ? value.toFixed(2) : value;
        }
    }, 
    mounted(){
        
        if(!sessionStorage.getItem("auth-token"))
            return;
        if(Role.Student.name === this.profile.roleName)
            return 
        if(this.serviceDeskWidget)
            return
        let widgetId = parseInt(process.env.VUE_APP_WIDGET_ID)
        window.fwSettings={
            'widget_id': widgetId
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', `https://widget.freshworks.com/widgets/${widgetId}.js`)
        recaptchaScript.setAttribute('type', 'text/javascript')
        recaptchaScript.setAttribute('id', 'serviceDeskWidget')
        document.head.appendChild(recaptchaScript) 
        this.serviceDeskWidget = true
    }
}