import apiClient from '@/services/ApiClient.js'
export default {
    async uploadPDF(pdf) {
        return apiClient.post('scope-and-sequence', pdf,{
            headers: {
                'Content-Type': 'multipart/form-data'
            } })
    },
    async getPDF() {
        return apiClient({
            method:'get',
            url:`${process.env.VUE_APP_BASE_URI}scope-and-sequence`,
            responseType:'blob'
          })
    },
    async removePDF(){
        return apiClient.delete(`scope-and-sequence`)
    }
}