import apiClient from '@/services/ApiClient.js'


export default {
    async get(lessonId) {
        return apiClient.get(`lesson/${lessonId}`)
    },
    async updateLessonOrder(lesson) {
        return apiClient.patch(`lesson/${lesson.id}`, { number: lesson.number, order: lesson.order, oldOrder: lesson.oldOrder })
    },
    async removeLesson(lessonId) {
        return apiClient.delete(`lesson/${lessonId}`)
    },
    async createLesson(unitId) {
        return apiClient.post('lesson', { unitId: unitId })
    },
    async updateLessonName(lesson) {
        return apiClient.patch(`lesson/${lesson.id}/change-name`, { title: lesson.name, lessonTypeValue: lesson.lessonTypeId })
    },
    async setCompleted(lesson) {
        return apiClient.patch(`lesson/${lesson.id}/lesson-completed`)
    },
    async configurationSettings(config) {
        return apiClient.get(`configurationsettings/getbytype/${config.lessonType}`, { langauge: config.langauge });
    },
    async updateLessonTypeOrTitle(lesson) {
        return apiClient.put(`lesson/${lesson.id}`, { title: lesson.name, lessonTypeId: lesson.lessonTypeId })
    },
}