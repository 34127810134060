var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "i",
    {
      staticClass: "audio-toggle",
      class: { "white-background": _vm.whiteBackground && _vm.audioExist }
    },
    [
      _c("img", {
        class: { "audio-playing": _vm.isAudioPlaying },
        style: { opacity: _vm.audioExist ? "1" : "0" },
        attrs: { src: _vm.audioIcon },
        on: { click: _vm.playAudio }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }