var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", [
    _c("div", { staticClass: "auth-bar-icon column" }, [
      _c("img", { staticClass: "map-image", attrs: { src: _vm.logoImage } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }