import apiClient from "./ApiClient.js";

const BASE_URL = "State";

export default {
    get(){
        return apiClient.get(BASE_URL);
    },
    getById(stateId){
        return apiClient.get(`${BASE_URL}/${stateId}`);
    },
    getByName(stateName){
        return apiClient.get(`${BASE_URL}/FindByName/${stateName}`);
    },
    addState(newState){
        return apiClient.post(BASE_URL, newState);
    },
    updateState(updatedState){
        return apiClient.put(`${BASE_URL}/${updatedState.id}`, updatedState);
    },
    deleteState(removedState){
        return apiClient.delete(`${BASE_URL}/${removedState.id}`);
    }
};