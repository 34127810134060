import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'

async function start() {
  try {
      await notificationsHubConnection.start();
  } catch (err) {
      setTimeout(() => start(), 5000);
  }
}


export const notificationsHubConnection = new HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_NOTIFICATIONS_HUB,{
        accessTokenFactory: () => {
            return sessionStorage.getItem('auth-token')
        }
  })
  .configureLogging(LogLevel.Error)
  .build()



notificationsHubConnection.onclose(async () => {
    await start();
});
