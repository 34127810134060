import apiClient from '@/services/ApiClient.js'

export default {
    async getStudentContent(studentId){
        return apiClient.get(`teacher-feedback/student/${studentId}`)
    }, 
    async setTeacherFeedBack(answer){
        return apiClient.post('answer-feedback', answer)
    },
    async retakeAssessment(lessonId, studentId){
        return apiClient.patch(`retake-assessment/${lessonId}/student/${studentId}`)
    }
}