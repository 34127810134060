var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c(
      "header",
      { staticClass: "row" },
      [
        _c("BreadCrumb"),
        _c("logo", { staticClass: "centered" }),
        _c("div", { staticClass: "profile-section row" }, [
          _c(
            "section",
            { staticClass: "profile-col" },
            [
              _vm.informationIcon && _vm.displayContributorPageIcon
                ? _c("img", {
                    staticClass: "information-icon-style",
                    attrs: { src: _vm.informationIcon },
                    on: { click: _vm.goToContributorsPage }
                  })
                : _vm._e(),
              _vm.helpIcon
                ? _c("img", {
                    ref: "notificationsButton",
                    attrs: { src: _vm.helpIcon },
                    on: { click: _vm.showNotifications }
                  })
                : _vm._e(),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.showNotificationsBox ? _c("i") : _vm._e()
              ]),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.showNotificationsBox
                    ? _c("NotificationsBox", {
                        directives: [
                          {
                            name: "closable",
                            rawName: "v-closable",
                            value: {
                              exclude: ["notificationsButton"],
                              handler: "onCloseNotificationsBox"
                            },
                            expression:
                              "{\n              exclude: ['notificationsButton'],\n              handler: 'onCloseNotificationsBox',\n            }"
                          }
                        ]
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                !_vm.showNotificationsBox && _vm.numberOfNotifications > 0
                  ? _c("span", { staticClass: "btn round failed" }, [
                      _vm._v(_vm._s(_vm.numberOfNotifications))
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm.avatar
            ? _c("img", {
                staticClass: "ml-0",
                attrs: { src: _vm.avatar },
                on: { click: _vm.showProfileData }
              })
            : _vm._e(),
          _c("span", { staticClass: "user-name", attrs: { title: _vm.user } }, [
            _vm._v(_vm._s(_vm.user))
          ]),
          _c(
            "div",
            {
              staticClass: "logout",
              on: {
                click: function($event) {
                  _vm.logOut()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("logout")))]
          )
        ]),
        _c(
          "div",
          { staticClass: "command-section flex-container" },
          [
            _vm.isTeacherPreviewMode
              ? [
                  _c(
                    "button",
                    {
                      staticClass: "preview btn primary",
                      on: { click: _vm.goBack }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.$t("back")) + "\n        "
                      )
                    ]
                  )
                ]
              : _vm._e(),
            _vm.isBackOfficeSection &&
            _vm.showPreviewModeOptions &&
            !_vm.isTeacherPreviewMode
              ? [
                  !_vm.isPreviewModeActive
                    ? _c(
                        "button",
                        {
                          staticClass: "preview btn secondary",
                          on: { click: _vm.changeMode }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("preview")) +
                              "\n        "
                          )
                        ]
                      )
                    : [
                        _c(
                          "button",
                          {
                            staticClass: "preview btn secondary",
                            on: { click: _vm.changeMode }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("keepEditing")) +
                                "\n          "
                            )
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "preview btn secondary",
                            on: {
                              click: function($event) {
                                _vm.showModal = true
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("publish")))]
                        ),
                        _vm.showModal
                          ? _c(
                              "modal",
                              {
                                attrs: {
                                  "click-handler": _vm.publish,
                                  "text-button": "publish"
                                },
                                on: {
                                  close: function($event) {
                                    _vm.showModal = false
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "message" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("publishChangesQuestion"))
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]
                ]
              : _vm._e()
          ],
          2
        ),
        _vm.showProfileDataModal
          ? _c(
              "modal",
              {
                attrs: {
                  clickOutSideModalHandler: _vm.hideModal,
                  showButtons: false
                }
              },
              [_c("profile-data", { on: { close: _vm.hideModal } })],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }