var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.unit
    ? _c("header", [
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.unit.name || "",
                expression: "unit.name || ''"
              }
            ],
            staticClass: "unit-name"
          },
          [_vm._v(_vm._s(_vm.unit.name))]
        ),
        _c(
          "nav",
          { staticClass: "flex-container", attrs: { id: "menu" } },
          _vm._l(_vm.menus, function(menu, index) {
            return _c("drop-down", { key: index, attrs: { menu: menu } })
          })
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }