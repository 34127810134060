import { i18n } from '@/plugins/i18n.js';
import { Role } from "@/utils/Roles.js";
import Setters from "@/assets/BackOfficeAssets/component-setters.json";
import SoaConfig from '../SoaConfig'

export default {
    loadContributorsPage: (state, payload) => state.contributorsPage = payload,
    loadContributorsPageComponent: (state, payload) => state.contributorsPageComponent = payload,

    // ################ GENERAL MUTATITIONS ###########################
    updateLanguage: (state, language) => {
        i18n.locale = language
    },
    updateServiceDeskWidgetAdded: (state, value) => state.serviceDeskWidget = value,
    addSnackBarNotification: (state, notification) => {
        notification.timeStamp = Date.now() / 10000
        state.snackBarNotifications.push(notification)
    },
    removeSnackBarNotification: (state, notification) => {
        state.snackBarNotifications = state.snackBarNotifications
            .filter(e => e != notification)
    },
    loadNotificationsInBox: (state, notifications) => state.notificationsBox = notifications,
    addNotificationToBox: (state, notification) => state.notificationsBox.push(notification),
    removeNotificationFromBox: (state, notification) => {
        state.notificationsBox = state.notificationsBox.filter(e => e != notification)
    },
    markNotificationAsRead: (state, notification) => notification.isRead = true,

    updateLoadingStatus: (state, status) => { state.loadingStatus = status },
    updateAudioPlaying: (state, audioPlaying) => { state.audioPlaying = audioPlaying },
    updateVideoPlaying: (state, video) => { state.videoPlaying = video },

    // ################################################################
    updateUnits: (state, units) => { state.units = units },
    updateCurrentUnit: (state, unit) => { state.currentUnit = unit },
    updateBreadcrumbElements: (state, elements) => { state.breadcrumbElements = elements },
    loadOverview: function (state, overview) {
        state.currentBlurb = null
        state.overview = overview
        state.overview.lessons.forEach(e => {
            e['status'] = 'remaining'
            e['clicked'] = false
        })
        state.currentBlurb = state.overview.lessons[0]

        if (state.currentBlurb) {
            state.currentBlurb['status'] = 'checked'
            state.currentBlurb['clicked'] = true
        }
    },
    loadTeacherPage: (state, teacherPage) => { state.teacherPage = teacherPage },
    updateCurrentBlurb(state, blurb) {
        if (state.currentBlurb) {
            state.currentBlurb.status =
                blurb.status === 'remaining' ? 'checked' : 'remaining'
        }
        state.currentBlurb = blurb
        state.currentBlurb['clicked'] = true
    },
    updateCurrentView: (state, view) => state.currentView = view,
    loadLesson: (state, lesson) => {
        if (lesson && lesson.components) {
            let components = lesson.components;
            components.forEach(p => {
                if (p.paragraph) {
                    p.paragraph.order = p.order;
                    if (p.paragraph.type === 'global-connections' && p.paragraph.routePoints.length > 0) {
                        let num = 1;
                        p.paragraph.routePoints.map(r => {
                            r.number = num;
                            num++;
                        });
                    }
                } else if (p.question) { 
                    let keys = Object.keys(p.question);
                    // console.log(keys , "mutation questions keys")
                    keys.forEach((key) => {
                        if(key != 'id' && key != 'assessmentId' && p.question[key] != null) {
                            p.question = p.question[key];
                        }
                    });
                     p.question.order = p.order;
                }
                else if(p.assessmentGroup){
                //    var groupType =  p.assessmentGroup.groupHeaderType
                //    var isSubmitted = p.assessmentGroup.isSubmitted
                //     p.assessmentGroup.components.questions.map((question)=>{
                //         question.isSubmitted = isSubmitted
                //     })
                }

            });

            lesson.components = components;
        }
        state.currentLesson = lesson
    },
    loadStory: (state, story) => {
        if (story && story.components) {
            let components = story.components;
            components.forEach(p => {
                p.paragraph.order = p.order;
                if (p.paragraph.type === 'global-connections' && p.paragraph.routePoints.length > 0) {
                    let num = 1;
                    p.paragraph.routePoints.map(r => {
                        r.number = num;
                        num++;
                    });
                }
            });

            story.components = components;
        }
        state.currentStory = story
    },

    // ################ MUTATIONS FOR ASSESSMENT (STUDENT) ###########################
    loadAssessment: (state, assessment) => { state.assessment = assessment },
    updateCurrentAssessmentSection: (state, section) => state.currentAssessmentSection = section,

    updateStudentAnswer: (state, { question, answer }) => {
        question.studentAnswer = answer
    },
    updateAnswerFeedback: (state, { answer, feedback }) => {
        answer.feedback = feedback
    },
    updateMultipleChoiceQuestionStudentAnswer: (state, { question, answer }) => {
        question.choices.map((e) => {
            e.id == answer
                ? e.correct = true
                : e.correct = false 
        })
    },
    setAssessmentCompleted: (state) => { state.assessment.assessmentCompleted = true },
    setWrittenAnswerStudentAnswer: (state, answer) => {
        let targetQuestion = state.assessment.questions.find(elem => elem.id == answer.questionId);
        targetQuestion.studentAnswer = answer.writtenText;
    },
    
    loadLessonAssessementGroupComponents(state,lesson){
      let numberOfGroups =  lesson.map((question)=>{
            let tempObj = {
                "id": question.id,
                "currentQuestionIndex": 0
            }
            return tempObj
        })
        state.currentQuestionPointer = numberOfGroups
    },
    // sendStatusToFooter(state,question){
    //     state.currentLesson.components.forEach((item)=>{
    //         // console.log(item)
    //      if(item.assessmentGroup){
    //          item.assessmentGroup.assessmentPagerStatus.forEach((e)=>{
    //              if(e.questionId == question.questionId){
    //                  e.status = 1
    //             //    console.log(e.status)
    //              }
    //          })
    //     //    console.log(item.assessmentGroup)
    //      }
    //     }) 
    //     // console.log('question',question)
    // },
    loadCurrentQuestion(state, currentQuestion){    
        state.skillLessonCurrentQuestion = currentQuestion     
    },
    submitIndividualQuestion(state){
        state.currentLesson.components.forEach((item)=>{
            if(item.assessmentGroup){
                item.assessmentGroup.components.questions.map((question)=>{
                    if(question.id === state.skillLessonCurrentQuestion.id){
                        question.isSubmitted = true
                        // question.isDisabled = true
                        if(state.skillLessonCurrentQuestion.type == 'multiple-choice'){
                            // question.correctChoiceId 

                            question.choices.map((c)=>{
                               if(c.id === question.correctChoiceId){
                                   question.isCorrect = true
                                   c.correctChoiceClass = 'correctChoice'
                               } 
                                else if(c.id != question.correctChoiceId){
                                    question.isCorrect = false    
                                    let studentChoice = question.choices.find((item) => item.correct == true)
                                    if(studentChoice.id == c.id){
                                        c.correctChoiceClass = 'incorrectChoice'
                                    }
                                }
                                
                               
                            })                            
                        }
                    }
                })
                item.assessmentGroup.assessmentPagerStatus.forEach((e)=>{
                                 if(e.questionId == state.skillLessonCurrentQuestion.id){
                                     e.status = 1
                                
                                 }
                             })
            }
        })

    },

    // ################ MUTATIONS FOR SKILL LESSON (STUDENT) ###########################
    loadSkillLesson: (state, skillLesson) => { state.skillLesson = skillLesson },

    // ################ MUTATIONS FOR LESSON BUILDER SECTION ###########################
    updatePreviewModeStatus: (state, status) => { state.isPreviewModeActive = status },
    updateCurrentBiography: (state, biography) => {
        if (!biography.portrait)
            biography.portrait = {};

        biography.portrait.audio = biography.audioPortrait;
        biography.components.map(c => {
            c.paragraph.order = c.order;
        });
        state.currentBiography = biography;
    },
    // ################ MUTATIONS FOR LESSON BUILDER SECTION ###########################
    loadLessonBuilderComponents: (state, components) => { state.LessonBuilderComponents = components },
    loadLessonBannerComponent: function (state, lessonBannerComponent) {
        state.lessonBannerComponent = {
            text: 'LESSON BANNER',
            type: 'lesson-banner',
            ...lessonBannerComponent
        }
    },
    updateContentSectionState: (state, shown) => { state.isContentSectionActive = shown },
    addLessonBuilderComponent(state, component) {
        state.LessonBuilderComponents
            .filter(c => c.order >= component.order)
            .map(e => { e.order++ })
        if (component.paragraph.type == Setters.GlobalConnectionsSetters[0].type) {
            let noRoutePoints = component.paragraph.routePoints.length + 1;
            while (noRoutePoints <= Setters.GlobalConnectionsSetters[0].defaultRoutePoints) {
                this.dispatch("addRoutePoint", component);
                noRoutePoints++;
            }
        }
        if (component.paragraph) component.paragraph = component.paragraph;

        state.LessonBuilderComponents.push(component)
    },
    updateLessonComponentsOrder(state, components) {
        components.map((e, index) => { e.order = index + 1 })
        state.LessonBuilderComponents = components
    },
    changeLessonBuilderComponentOrder(state, { component, newOrder }) {
        state.LessonBuilderComponents
            .filter(e => e.order === newOrder)
            .map((e) => e.order = component.order)
        component.order = newOrder;
    },
    updateLessonBuilderComponentOrder(state) {
        state.LessonBuilderComponents.sort(function (a, b) { return a.order - b.order })
    },
    // remove component and reorder remaining components
    deleteLessonBuilderComponent(state, component) {
        state.LessonBuilderComponents = state.LessonBuilderComponents.filter(c => c.id != component.id)
        state.LessonBuilderComponents
            .filter(e => e.order > component.order)
            .map((e) => e.order--)
    },
    updateCurrentBackOfficeComponent: (state, component) => {
        state.currentBackOfficeComponent = component
    },
    // property name where image will be stored
    setBuilderComponentPropertyValue: (state, { component, propertyName, value, language }) => {
        language
            ? component[propertyName][language] = value
            : component[propertyName] = value
    },
    addImageToComponent: (state, { component, image }) => {
        image.position = image.position != 'inherit' ? true : false
        component.images.push(image)
    },
    updateImageDetailsComponent: (state, { component, image, property, value }) => {
        component.images.filter(e => e.id == image.id)
            .map((e) => e[property] = value)
    },
    removeImageFromComponent: (state, { component, imageId }) => {
        component.images = component.images.filter(i => i.id != imageId);
    },
    linkAudioIdToEssentialQuestion: (state, { component, id }) => {
        component.essentialQuestion.audio.id = id
        component.essentialQuestion.audioId = id
    },
    sortRoutePoints: (state, { component, points }) => {
        component.paragraph.routePoints = points.sort(function (a, b) {
            return a.number - b.number
        });
    },
    //Assessment group components
    loadAssessmentGroupcomponents(state, components) {
        state.assessmentGroupComponents = components;
    },

    loadComponentToAssessmentGroup(state, component) {
        state.assessmentGroupComponents.push(component);
    },
    createAssessmentGroup(state, payload){
        state.assessmentGroupArray.push(payload)
    },
    loadAssessmentGroups(state, payload){
        state.assessmentGroupArray = payload;
    },

    // ################ MUTATIONS FOR UNIT STORY SECTION ###########################
    loadUnitStoryBuilderComponents: (state, components) => { state.unitStoryBuilderComponents = components },
    loadUnitStoryBannerComponent: function (state, storyBannerComponent) {
        state.storyBannerComponent = {
            text: 'STORY BANNER',
            type: 'story-banner',
            ...storyBannerComponent
        }
    },
    addUnitStoryBuilderComponent(state, component) {
        state.unitStoryBuilderComponents
            .filter(c => c.order >= component.order)
            .map(e => { e.order++ })
        if (component.paragraph.type == Setters.GlobalConnectionsSetters[0].type) {
            let noRoutePoints = component.paragraph.routePoints.length + 1;
            while (noRoutePoints <= Setters.GlobalConnectionsSetters[0].defaultRoutePoints) {
                this.dispatch("addRoutePoint", component);
                noRoutePoints++;
            }
        }
        if (component.paragraph) component.paragraph = component.paragraph;

        state.unitStoryBuilderComponents.push(component)
    },
    updateUnitStoryComponentsOrder(state, components) {
        components.map((e, index) => { e.order = index + 1 })
        state.unitStoryBuilderComponents = components
    },
    changeUnitStoryBuilderComponentOrder(state, { component, newOrder }) {
        state.unitStoryBuilderComponents
            .filter(e => e.order === newOrder)
            .map((e) => e.order = component.order)
        component.order = newOrder;
    },
    updateUnitStoryBuilderComponentOrder(state) {
        state.unitStoryBuilderComponents.sort(function (a, b) { return a.order - b.order })
    },
    // remove component and reorder remaining components
    deleteUnitStoryBuilderComponent(state, component) {
        state.unitStoryBuilderComponents = state.unitStoryBuilderComponents.filter(c => c.id != component.id)
        state.unitStoryBuilderComponents
            .filter(e => e.order > component.order)
            .map((e) => e.order--)
    },

    updateSwitchButtonDisplayStatus: (state, status) => { state.isSwitchButtonDisplayed = status },
    // ################ UNIT BUILDER MUTATIONS ###########################
    addBackOfficeComponentValidationError: (state, error) => {
        state.currentBackOfficeComponent && state.currentBackOfficeComponent.paragraph
            ? state.currentBackOfficeComponent.paragraph.errors.push(error)
            : state.currentBackOfficeComponent.errors.push(error)
    },

    removeBackOfficeComponentValidationError(state, { error, component = state.currentBackOfficeComponent }) {
        let errors = component.paragraph
            ? component.paragraph.errors.filter(e => e != error)
            : component.errors.filter(e => e != error)
        component.paragraph
            ? component.paragraph.errors = errors
            : component.errors = errors
    },
    setBuilderName: (state, builderName) => { state.builderName = builderName },
    loadUnitBuilderComponent: (state, component) => {
        state.unitBuilderComponent = component
    },
    updateLessonsList: (state, { component, lessons }) => {
        component.lessons = lessons
        let indx = 0;
        let typeLesson = SoaConfig.soaConfig.lessonTypes.lesson.id
        component.lessons.map((e, i) => {
            if (e.lessonTypeId == typeLesson || e.lessonTypeId == null) {
                indx = indx + 1;
                e.number = indx
            } else {
                e.number = 0
            }
            e.order = i + 1
        })
    },
    removeLessonFromUnit: (state, lesson) => {
        if (state.unitBuilderComponent.lessons) {
            state.unitBuilderComponent.lessons =
                state.unitBuilderComponent.lessons.filter(e => e.id != lesson.id)
            // state.unitBuilderComponent.lessons.filter(e => e.order > lesson.order)
            //     .map(e => e.order--)
        }

        if (state.UnitDashboardComponents.lessons) {
            state.UnitDashboardComponents.lessons =
                state.UnitDashboardComponents.lessons.filter(e => e.id != lesson.id)
        }
    },
    addLessonToUnit: (state, lesson) => {
        lesson.number = state.unitBuilderComponent.lessons.length
        state.unitBuilderComponent.lessons.push(lesson)
    },

    loadUnitOverviewBuilderComponent: (state, components) => {
        state.unitOverviewBuilderComponent = components
    },
    updateLessonNumber(state, componentObj){
        state.unitBuilderComponent.lessons[componentObj.updateObj].lessonTypeId = componentObj.lessonType;
    },
    updateIfSameLessonNumber(state,componentObj){
        state.unitBuilderComponent.lessons[componentObj.updateObj].number = componentObj.lessonCurrentNumber
        // this.lessons[index].number = lessonCurrentNumber;
    },

    // unitopenerBuilder

    assignUnitOpenerId: (state, payload) => {
        state.unitOpenerId = payload
    },
    loadUnitData(state, payload) {
        state.unitData = payload
    },
    loadUnitOpenerBuilderComponent(state, payload) {
        state.openerComponents = payload
    },

    loadOpenerPreviewData(state, payload) {
        state.unitOpenerPreviewData = payload
    },

    readbtnClicked(state, payload){
        state.readClicked = payload.redirectToOpener;
    },

    //UnitDashboardBuilder
    loadUnitDashboardComponents: (state, components) => {
        state.UnitDashboardComponents = components
    },
    createUnitDashboardComponent: (state, component) => {
        component.setters = Setters.UnitDashboardSetters;
        component.originalName = { es: component.name.es, en: component.name.en }

        let indexOfExistingUnitInArray = -1
        state.UnitDashboardComponents.forEach((e, index) => {
            if (e.number == component.number) {
                indexOfExistingUnitInArray = index
            }
        })
        indexOfExistingUnitInArray > -1
            ? state.UnitDashboardComponents[indexOfExistingUnitInArray] = component
            : state.UnitDashboardComponents.push(component);
    },
    changeUnitDashboardComponentOrder: function (state, units) {
        units.map((e, index) => {
            e.number = index + 1
        })
        state.UnitDashboardComponents = units
    },
    addUnit(state, unit) {
        state.UnitDashboardComponents.push(unit)
    },
    removeUnitDashboardComponent(state, component) {
        state.UnitDashboardComponents = state.UnitDashboardComponents
            .filter(e => e != component)

        state.UnitDashboardComponents
            .map((e, index) => e.number = index + 1)
    },
    sortUnitDashboardComponents: (state) => {
        state.UnitDashboardComponents.sort((firstElem, secondElem) => {
            return firstElem.number - secondElem.number;
        });

        state.UnitDashboardComponents = [...state.UnitDashboardComponents];
    },

    // ################ MUTATIONS FOR Biography BUILDER SECTION ###########################
    loadBiographyBuilderComponent: (state, biography) => {
        if (biography && biography.components) {
            let components = biography.components;
            components.forEach(p => {
                p.paragraph.order = p.order;
                if (p.paragraph.type === 'global-connections' && p.paragraph.routePoints.length > 0) {
                    let num = 1;
                    p.paragraph.routePoints.map(r => {
                        r.number = num;
                        num++;
                    });
                }
            });

            biography.components = components;
        }
        state.BiographyBuilderComponent = biography
    },

    addBiographyBuilderComponent(state, component) {
        state.BiographyBuilderComponent.components
            .filter(c => c.order >= component.order)
            .map(e => { e.order++ })
        if (component.paragraph.type == Setters.GlobalConnectionsSetters[0].type) {
            let noRoutePoints = component.paragraph.routePoints.length + 1;
            while (noRoutePoints <= Setters.GlobalConnectionsSetters[0].defaultRoutePoints) {
                this.dispatch("addRoutePoint", component);
                noRoutePoints++;
            }
        }
        state.BiographyBuilderComponent.components.push(component)
    },
    //Biography Builder
    updateBiographyComponentsOrder(state, components) {
        components.map((e, index) => { e.order = index + 1 })
        state.BiographyBuilderComponent.components = components
    },
    changeBiographyBuilderComponentOrder(state, { component, newOrder }) {
        state.BiographyBuilderComponent.components
            .filter(e => e.order === newOrder)
            .map((e) => e.order = component.order)
        component.order = newOrder;
    },
    updateBiographyBuilderComponentOrder(state) {
        state.BiographyBuilderComponent.components
            .sort(function (a, b) { return a.order - b.order })
    },
    // remove component and reorder remaining components
    deleteBiographyBuilderComponent(state, component) {
        state.BiographyBuilderComponent.components = state.BiographyBuilderComponent
            .components.filter(c => c.id != component.id)
        state.BiographyBuilderComponent.components
            .filter(e => e.order > component.order)
            .map((e) => e.order--)
    },
    addBiographyToUnit: (state, biography) => {
        state.unitBuilderComponent
            .biographies.push(biography)
    },
    addStoryToUnit: (state, story) => {
        state.unitBuilderComponent
            .unitStories.push(story)
    },
    removeUnitStoryFromUnit: (state, story) => {
        if (!state.unitBuilderComponent.unitStories)
            return
        state.unitBuilderComponent.unitStories =
            state.unitBuilderComponent.unitStories.filter(e => e != story)
    },
    removeBiographyFromUnit: (state, biography) => {
        if (!state.unitBuilderComponent.biographies)
            return
        state.unitBuilderComponent.biographies =
            state.unitBuilderComponent.biographies.filter(e => e != biography)
    },
    loadVocabularyComponents: (state, vocabularyComponents) => {
        state.vocabularyComponents = vocabularyComponents
    },
    addVocabularyComponent: (state, vocabularyComponent) => {
        state.vocabularyComponents.push(vocabularyComponent)
    },
    removeVocabularyComponent: (state, vocabularyComponent) => {
        state.vocabularyComponents = state.vocabularyComponents
            .filter(e => e != vocabularyComponent)
    },
    updateShowPreviewModeOptions: (state, status) => { state.showPreviewModeOptions = status },

    // ########################################################
    // #####      MUTATIONS FOR Assessment Builder     ########
    // ########################################################
    loadAssessmentQuestions: (state, questions) => { state.assessmentQuestions = questions },
    loadAssessmentHeader: (state, assessmentHeader) => { state.assessmentHeader = assessmentHeader },
    removeQuestionFromAssessment: (state, question) => {
        let elements = state.assessmentQuestions.filter(e => e != question);

        elements.forEach((e, index) => { e.order = index + 1; });
        state.assessmentQuestions = elements;
    },
    addQuestionToAssessment: (state, question) => {
        let questions = state.assessmentQuestions;
        let firstComponent = questions.find(elem => elem.type == question.type);

        if (firstComponent) {
            let index = question.order > 1 ? firstComponent.order + question.order - 1
                : firstComponent.order;

            questions.forEach(elem => {
                if (elem.type == question.type && elem.order >= index)
                    elem.order++;
            });

            question.order = index;
        }

        questions.push(question);
    },
    addChoiceToQuestion: (state, { question, choice }) => {
        question.choices.push(choice)
    },
    updateCorrectAnswer: function (state, { question, choice }) {
        question.choices
            .filter(e => e.id != choice.id)
            .forEach(c => c.correct = false)
    },
    reorderQuestionsByType: (state) => {
        state.assessmentQuestions = state.assessmentQuestions.sort((a, b) => {
            var typeA = a.type.toUpperCase(); // ignore upper and lowercase
            var typeB = b.type.toUpperCase(); // ignore upper and lowercase
            if (typeA > typeB) {
                return -1;
            }
            if (typeA < typeB) {
                return 1;
            }
            return 0;
        })
    },
    addTeksToQuestion: (state, { question, teks }) => question.teks.push(teks),
    removeTeksFromQuestion: (state, { question, teks }) => {
        let index = question.teks.indexOf(teks)
        question.teks.splice(index, 1)
    },
    updateQuestionComponentsOrder(state) {
        state.assessmentQuestions.sort(function (a, b) { return a.order - b.order })
    },
    sortAssessmentBuilderComponents(state, collectionsData = {
        sourceCollection: null,
        targetCollectionName: "assessmentQuestions",
        getInnerCollectionFunction: null,
        setInnerCollectionFunction: null
    }) {
        let groupedByTypes = { "multiple-choice": [], "venn-diagram": [], "written": [], "essay": [] };
        let resultingQuestions = [];

        let elements = collectionsData.sourceCollection ? collectionsData.sourceCollection
            : state[collectionsData.targetCollectionName];

        if (collectionsData.getInnerCollectionFunction)
            elements = collectionsData.getInnerCollectionFunction(elements);

        elements.forEach(question => {
            groupedByTypes[question.type].push(question);
        });

        for (let group in groupedByTypes) {
            if (groupedByTypes[group].length) {
                groupedByTypes[group].sort((firstElem, secondElem) => firstElem.order - secondElem.order);
                resultingQuestions = resultingQuestions.concat(groupedByTypes[group]);
            }
        }

        resultingQuestions.forEach((elem, index) => { elem.order = index + 1; });

        if (collectionsData.setInnerCollectionFunction)
            collectionsData.setInnerCollectionFunction(state[collectionsData.targetCollectionName],
                resultingQuestions);
        else
            state[collectionsData.targetCollectionName] = resultingQuestions;
    },
    updateQuestionComponentOrder(state, components) {
        let elements = components ? components : state.assessmentQuestions;

        elements.forEach((e, index) => { e.order = index + 1; });
        state.assessmentQuestions = elements;
    },
    changeQuestionComponentOrder(state, { component, newOrder }) {
        state.assessmentQuestions
            .filter(e => e.order === newOrder)
            .map((e) => e.order = component.order)
        component.order = newOrder;
    },
    removeChoiceFromQuestion(state, { question, choice }) {
        question.choices = question.choices.filter(e => e != choice)
    },
    addQuestionDirection(state, input) {
        state.assessmentHeader.questionDirections = {
            ...state.assessmentHeader.questionDirections,
            [input.type]: {
                questionType: input.type,
                content: { [input.language]: input.value },
                audio: {},
                setters: Setters.AssessmentHeaderDirectionSetters
            }
        };
    },
    updateQuestionDirection(state, input) {
        state.assessmentHeader.questionDirections[input.type]
            .content[input.language] = input.value;
    },
    storeAssessmentDirectionsAudioIds(state) {
        if (!state.assessmentHeader.questionDirections) return;
        Object.keys(state.assessmentHeader.questionDirections).forEach(key => {
            let _element = state.assessmentHeader.questionDirections[key];

            if (_element.audio.id)
                _element.audioId = _element.audio.id;
        });
    },


    // ########################################################
    // #####      MUTATIONS FOR Skill Lesson Builder     ########
    // ########################################################

    // addComponentToSkillLesson: (state, component) => {
    //     let components = state.LessonBuilderComponents;
    //     let firstComponent = components.find(elem => elem.type == component.type);

    //     if (firstComponent) {
    //         let index = component.order > 1 ? firstComponent.order + component.order - 1
    //             : firstComponent.order;

    //         components.forEach(elem => {
    //             if (elem.type == component.type && elem.order >= index)
    //                 elem.order++;
    //         });

    //         component.order = index;
    //     }

    //     components.push(component);
    // },
    addComponentToSkillLesson(state, component) {
        state.LessonBuilderComponents
            .filter(c => c.order >= component.order)
            .map(e => { e.order++ })
        if (component.paragraph && component.paragraph.type == Setters.GlobalConnectionsSetters[0].type) {
            let noRoutePoints = component.paragraph.routePoints.length + 1;
            while (noRoutePoints <= Setters.GlobalConnectionsSetters[0].defaultRoutePoints) {
                this.dispatch("addRoutePoint", component);
                noRoutePoints++;
            }
        }
        if (component.paragraph) component.paragraph = component.paragraph;

        state.LessonBuilderComponents.push(component)
    },


    // Timeline Builder Mutations
    loadTimeLineBuilderComponent: (state, component) => state.timeLineBuilderComponent = component,
    organizeTimelineEvents: (state, events) => {
        events.map((e, index) => e.order = index)
        state.timeLineBuilderComponent.blurbs = events
    },
    updateEventsOrder: (state, { blurbs }) => {
        blurbs.map((e, index) => e.order = index)
        state.timeLineBuilderComponent.blurbs = blurbs
    },
    sortTimeLineEvents: (state) => {
        state.timeLineBuilderComponent
            .blurbs
            .map((e, i) => e.order = i)
            .sort(function (a) { return a.order })
    },
    addTimeLineEvent: (state, event) => state.timeLineBuilderComponent.blurbs.push(event),
    removeTimeLineEvent: (state, event) => {
        state.timeLineBuilderComponent.blurbs = state.timeLineBuilderComponent.blurbs
            .filter(e => e != event)
    },
    // Time Line Student Mutations
    loadTimeLine: (state, timeline) => {
        state.timeline = timeline
    },
    updateCurrentTimeLineEvent: (state, event) => {
        state.currentTimeLineEvent = event
    },
    updateIsTimeLineEventCardDisplayedStatus: (state, status) => {
        state.isTimeLineEventCardDisplayed = status
    },


    // Interactive map mutations
    updateRoutePoints: (state, { component, points }) => {
        points.map((e, index) => {
            e.number = index + 1
        })
        component.routePoints = points
    },
    addRoutePoint: (state, { component, point }) => {
        component.paragraph.routePoints.push(point)
        if (component.paragraph.type === "global-connections" && component.paragraph.routePoints.length === 5) {
            let routes = component.paragraph.routePoints;
            let num = 1;
            routes.sort(function (a, b) {
                return a.id - b.id
            })
            routes.map(route => {
                route.number = num;
                num++;
            });
            component.paragraph.routePoints = routes;
        }
        // this.dispatch("sortRoutePoints", component);
    },
    removeRoutePoint: (state, { component, point }) => {
        component.paragraph.routePoints = component.paragraph.routePoints
            .filter(e => e != point)
        component.paragraph.routePoints.map((e, index) => {
            e.number = index + 1
        })
    },
    addImageIdToRoutePoint: (state, { point, imageId }) => {
        point.images.push(imageId)
    },
    removeImageIdToRoutePoint: (state, { point, imageId }) => {
        point.images = point.images.filter(e => e != imageId)
    },
    updateImageIdToRoutePoint: (state, { point, oldImgId, newImgId }) => {
        const idx = point.images.indexOf(oldImgId);
        point.images[idx] = newImgId;
    },

    // Teacher PageMutations
    loadTeacherPageComponent: (state, component) => {
        state.teacherPageComponent = component
    },

    //Classroom management
    loadClassRooms: (state, classrooms) => state.classrooms = classrooms,
    addClassRoom: (state, classroom) => state.classrooms.push(classroom),
    updateSelectedClassroom: (state, classroom) => state.selectedClassroom = classroom,
    removeStudentFromClassroom: (state, { student, classroom }) => {
        classroom.students = classroom.students.filter(e => e != student)
    },
    removeClassroom(state, classroom) {
        state.classrooms = state.classrooms.filter(e => e != classroom)
    },
    addStudent: (state, { student, classroom }) => classroom.students.push(student),
    addStudentsToClassroom: (state, newStudents) => {
        newStudents.forEach(e => {
            state.selectedClassroom.students.push(e)
        })
    },

    updateStudentLanguage: (state, { user, language }) => user.textLanguage = language,
    updateStudentAudioLanguage: (state, { user, language }) => user.audioLanguage = language,
    updateSelectedStudent: (state, student) => state.selectedStudentForFeedBack = student,

    setLessonCompleted: (state, lesson) => lesson.completed = true,
    uploadStudentContentForFeedBack: (state, content) => state.studentContentForFeedBack = content,
    updateAuthenticationForm: (state, form) => state.authenticationForm = form,

    updateSelectedUnitForFeedback: (state, unit) => state.selectedUnitForFeedback = unit,
    updateAnswerScore: (state, { answer, score }) => answer.score = score,
    setTeacherGroups(state, teacherGroups) {
        state.teacherGroups = teacherGroups;
    },
    setNewClassRoomSelectedTeacher(state, selectedTeacher) {
        state.newClassRoomSelectedTeacher = selectedTeacher;
    },
    setDealerPromise(state, promiseObj) {
        state.dealerPromise = promiseObj;
    },
    resolveOrRejectPromise(state, obj) {
        state.dealerPromise[obj.method]();
    },
    setStudentReportsCurrentSection(state, value) {
        state.studentReportsCurrentSection = value;
    },
    setClassRoomSubNavBarWithSectionSelect(state, value) {
        state.classRoomSubNavBarWithSectionSelect = value;
    },
    setClassroomDetailsSection(state, currentSection) {
        state.classroomDetailsSection = currentSection;
    },
    setClassroomManagementSection(state, currentSection) {
        state.currentClassroomManagementSection = currentSection;
    },
    setClassroomPerformance(state, value) {
        state.classroomPerformance = value;
    },

    //Users Management Mutations

    setUsersCollection(state, usersCollection) {
        state.usersCollection = usersCollection;
    },
    addNewUser(state, newUser) {
        let _usersCollection = { ...state.usersCollection };
        let _roleId = 0;

        try {
            if (!_usersCollection[_roleId = parseInt(newUser.roleId)]) {
                let _newUserRoleName = "";

                Object.values(Role).forEach(item => {
                    if (item.roleId === _roleId)
                        _newUserRoleName = item.name;
                });

                _usersCollection[_roleId] = { users: [], roleName: _newUserRoleName };
            }
        }
        catch (exp) {
            throw "failedGroupingUsersByRole";
        }

        _usersCollection[_roleId].users.push(newUser);

        state.usersCollection = _usersCollection;
    },
    setSchools(state, schools) {
        state.schools = schools;
    },
    setDistricts(state, districts) {
        state.managementObj.districts = districts;
    },
    updateOrDeleteUser(state, userObj) {
        let _user = userObj.targetUser;
        let _userCollection = { ...state.usersCollection[_user.roleId] };

        if (userObj.updateOrDelete) {
            let _userToModify = _userCollection.users.find(elem => elem.id === _user.id);

            _userToModify.firstName = _user.firstName;
            _userToModify.middleName = _user.middleName;
            _userToModify.lastName = _user.lastName;
            _userToModify.secondLastName = _user.secondLastName;
            _userToModify.email = _user.email;
            _userToModify.schoolId = _user.schoolId;
            _userToModify.birthDate = _user.birthDate;
        }
        else {
            _userCollection.users = _userCollection.users.filter(elem =>
                elem.id !== _user.id);
        }

        state.usersCollection[_user.roleId] = _userCollection;
    },

    //Management Dashboard Mutations

    setManagmentCurrentSection(state, currentManagementSection) {
        state.currentManagementSection = currentManagementSection;
    },
    setAnalyzedManagementSection(state, analyzedSection) {
        state.analyzedManagementSection = analyzedSection;
    },
    setManagementDashboardNavBarPath(state, sectionPath) {
        state.managementDashboardNavBarPath = sectionPath;
    },
    setStates(state, states) {
        state.managementObj.states = states;
    },
    setCurrentState(state, currentState) {
        state.managementObj.currentState = currentState;
    },
    addNewDistrict(state, newDistrict) {
        state.managementObj.districts.push(newDistrict);
    },
    updateDistrict(state, updatedDistrict) {
        let _elem = state.managementObj.districts
            .find(elem => elem.id === updatedDistrict.id);

        if (_elem) {
            _elem.name = updatedDistrict.name;

            state.managementObj.districts = [...state.managementObj.districts];
        }
    },
    removeDistrict(state, removedDistrict) {
        let _updateCollection = state.managementObj.districts
            .filter(elem => elem.id !== removedDistrict.id);

        state.managementObj.districts = _updateCollection;
    },
    addNewState(state, newState) {
        state.managementObj.states.push(newState);
    },
    updateState(state, updatedState) {
        let _elem = state.managementObj.states
            .find(elem => elem.id === updatedState.id);

        if (_elem) {
            _elem.name = updatedState.name;

            state.managementObj.states = [...state.managementObj.states];
        }
    },
    removeState(state, removedState) {
        let _updateCollection = state.managementObj.states
            .filter(elem => elem.id !== removedState.id);

        state.managementObj.states = _updateCollection;
    },
    addSchool(state, newSchool) {
        state.schools.push(newSchool);
    },
    updateSchool(state, updatedSchool) {
        let _elem = state.schools.find(elem => elem.id === updatedSchool.id);

        if (_elem) {
            _elem.name = updatedSchool.name;

            state.schools = [...state.schools];
        }
    },
    removeSchool(state, removedSchool) {
        let _updateCollection = state.schools.filter(elem => elem.id !== removedSchool.id);

        state.schools = _updateCollection;
    },
    setTelemetries(state, telemetries) {
        state.managementObj.telemetries = telemetries;
    },

    /* Mutations for Reports */

    setStudentPerformance(state, studentPerformance) {
        state.studentPerformance = studentPerformance;
    },

    removeLessonFromFeedback(state, { unit, lesson }) {
        unit.lessons = unit.lessons.filter(e => e.id != lesson.id)
    },
    removeUnitFromFeedback(state, unit) {
        state.studentContentForFeedBack = state.studentContentForFeedBack
            .filter(e => e.id != unit.id)
    },

    addVennDiagramElement: (state, { question, element }) => {
        question.vennDiagramElements.push(element)
    },
    removeElementFromVennDiagram: (state, { question, element }) => {
        let index = question.vennDiagramElements.indexOf(element)
        question.vennDiagramElements.splice(index, 1)
    },
    updateVennDiagramElement(state, { element, set }) {
        element.studentAnswerSet = set
    },
    loadStandardsReport: (state, report) => state.standardsReport = report,

    loadProgramPreview: (state, payload) => state.programPreview = payload,
    updateUnitPreviewExpanded: (state, payload) => state.unitPreviewExpanded = payload,

    configurationSettings: (state, payload) => state.configurationSettings = payload,
    lessonCount: (state, payload) => state.lessonCount = payload,
    unitLessons: (state, payload) => state.unitLessons = payload,
}
