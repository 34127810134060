import apiClient from '@/services/ApiClient.js'

export default {
    async getUnit(unitId) {
        return apiClient.get(`unit/${unitId}/backup`)
    },
    async updateUnit(unit){
        return apiClient.put(`unit/${unit.id}`, unit)
    },
    async updateUnitOverview(overview){
        return apiClient.put(`unit/${overview.unitId}/overview`, overview)
    },
    async publishUnitChanges(unitId) {
        return apiClient.patch(`unit/${unitId}/publish`)
    },
    async getUnits() {
        return apiClient.get('units/backups')
    },
    async updateUnitName(unit){
        return apiClient.patch(`unit/${unit.id}/change-name`, {
            name: unit.name
        })
    }, 
    async updateUnitOrder(unit){
        return apiClient.patch(`unit/${unit.id}`, {
            number: unit.number
        })
    }, 
    async createUnit(){
        return apiClient.post('unit')
    }, 
    async removeUnit(unit){
        return apiClient.delete(`unit/${unit.id}`)
    }, 
    async publishUnits(){
        return apiClient.patch('course/publish')
    }
}