import apiClient from '@/services/ApiClient.js'

export default {
  async loadProgramPreview() {
    return apiClient.get('program/preview')
  },
  async getUnits() {
    return apiClient.get('units')
  },
  async getUnit(id) {
    return apiClient.get(`unit/${id}`)
  },
  async getOverview(unitId) {
    return apiClient.get(`unit/${unitId}/overview`)
  },
  async getOverviewComponents(unitId) {
    return apiClient.get(`unit/${unitId}/overview-backups`)
  },
  updateOverviewStatus(unitId){
    return apiClient.patch(`unit/${unitId}/overview`);
  },
  async getUnitSkills(unitId){
    return apiClient.get(`unit/${unitId}/unitskills`)
  },
  async createUnit(){
    return apiClient.post('unit')
  },
  async createUnitOpenerComponent(unitId){
     return apiClient.post(`unitopener`,{ unitId: unitId })
  },
  async getUnitOpenerComponents(openerId){
    return apiClient.get(`unitopener/${openerId}/backup`)
  },
  async updateUnitOpener(component){
    return apiClient.put(`unitopener/${component.id}`, component)
  },

  async getUnitPreview(openerId){
    return apiClient.get(`unitopener/${openerId}`)
  },
  async publishUnitOpener(openerId){
    return apiClient.patch(`unitopener/${openerId}/publish`)
  }

}