import apiClient from "@/services/ApiClient.js";
import AppInsights from "@/AppInsights.js";
import store from "@/store.js";
import { Role } from "@/utils/Roles.js";

export default{
    signIn(userCredentials){
        return apiClient.post("user/authenticate", userCredentials)
                .then(response => {
                    sessionStorage.setItem("auth-token", response.data.token);
                    sessionStorage.setItem("language", response.data.textLanguage);
                    sessionStorage.setItem("profile",JSON.stringify(response.data));
                    sessionStorage.setItem("districtId", response.data.districtId);

                    AppInsights.insightsInstance
                                .setAuthenticatedUserContext(response.data.username,
                                                            response.data.username);

                    return response;
                }, ({response:{data}}) => { 
                    return Promise.reject(data.message); 
                });
    },
    signUp(userCredentials){
        return apiClient.post("signup", userCredentials)
                .then(null, () => { return Promise.reject("emailAlreadyInUse"); });
    },
    addUsers(data){
        return apiClient.post("user/adduser", data)
                .then(null, () => Promise.reject("emailAlreadyInUse"));
    },
    logOut(){
        return apiClient.get("user/logout")
                .then(() => {
                    let _section = store.state.analyzedManagementSection;
                    let _profile = JSON.parse(sessionStorage.getItem("profile"));

                    let _avoidSending = _section == "" 
                                            || _profile.roleId == Role.SuperAdmin.roleId;

                    AppInsights.insightsInstance
                                .stopTrackPage(_section, { avoidSending: _avoidSending });
                    AppInsights.insightsInstance.flush();
                    AppInsights.insightsInstance.clearAuthenticatedUserContext();
                    
                    sessionStorage.removeItem('auth-token')
                    sessionStorage.removeItem("language")
                    sessionStorage.removeItem("profile")
                    sessionStorage.removeItem("districtId");

                    window.location.href = "/signin";
                });
    },
    forgotPassword(email){
        return apiClient.post('User/passwordreset',null,  {
            params:{
                emailAddress: email
            }
        })
    },
    updatePassword(passwordUpdated){
        return apiClient.post("User/NewPassword", passwordUpdated)
    },
    changeLanguage(userId, language){
        return apiClient.put(`User/ChangeStudentLanguage/${userId}/${language}`)
    }, 
    getProfile(){
        let profile = sessionStorage.getItem('profile')
        return JSON.parse(profile)
    }
}