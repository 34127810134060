export default {
    blurbs: (state) => { return state.overview ? state.overview.lessons : [] },
    biographies: function (state) {
        try {
            let biographies = state.currentUnit.biographies
            Array.from(biographies).forEach(function (e) {
                e.route = { name: 'Biographies', params: { unitId: state.currentUnit.id, biographyId: e.id } }
            })
            return biographies
        } catch (err) {
            return []
        }
    },
    unitStories: function (state) {
        try {
            let unitStories = state.currentUnit.unitStories
            Array.from(unitStories).forEach(function (e) {
                e.route = { name: 'Stories', params: { unitId: state.currentUnit.id, unitstoryId: e.id } }
            })
            return unitStories
        } catch (err) {
            return []
        }
    },    
    isAssessmentNextButtonDisabled: function (state) {
        if (!state.currentAssessmentSection)
            return false
        switch (state.currentAssessmentSection.type) {
            case 'multiple-choice':
                return state.multipleChoiceQuestionsRemaining > 0
                    ? true : false;
            case 'essay':
                return state.currentAssessmentSection
                    .studentAnswer.length > 0 ? false : true;
            default:
                return false;
        }
    },
    videos: (state) => { return state.currentUnit ? state.currentUnit.videos : [] },
    currentUnit: (state) => { return state.currentUnit ? state.currentUnit : {} },
    unitLessons: (state) => { return state.currentUnit ? state.currentUnit.lessons : [] },

    configurationSettings: (state) => state.configurationSettings,
    lessonCount: (state) => state.lessonCount,
}