<template>
    <div class="box-notification row" @click="markNotificationAsRead"
    :style="{backgroundColor: notification.isRead ? '#F6F7FA' : '#fff'}">
        <span>{{notification.fromUser.firstName[0]}}{{notification.fromUser.lastName[0]}}</span>
        <div class="column">
            <p v-html="message"></p>
            <span>{{date}}</span>
        </div>
        <span class="close-icon" @click.stop="removeNotification()">&times;</span>
    </div>
</template>
<script>
import {Notification} from '@/utils/Notification.js'
export default {
    name: 'BoxNotification', 
    props: {
        notification: Object
    },
    computed:{
        date(){
            return new Date(this.notification.creationDate).toLocaleString()
        }, 
        message(){
            let args = this.notification.notificationArgs
            let username = `${this.notification.fromUser.firstName} ${this.notification.fromUser.lastName}`
            let message = this.$t(this.notification.message, {
                '0': username ,
                '1' :args.lessonNumber > 9 ? args.lessonNumber : '0'+ args.lessonNumber,
                '2' :args.unitNumber > 9 ? args.unitNumber : '0'+args.unitNumber,
            })
            return  message
        }, 
        studentId(){
            return this.notification.fromUser.id
        }
    },
    methods: {
        markNotificationAsRead(){
            if(!this.notification.isRead){
                this.$store.dispatch('markNotificationAsRead', this.notification)
            }
            if(this.notification.message === Notification.AssessmentSubmittedNotification)
               this.redirectToStudentReport()
            if(this.notification.message === Notification.AssessmentRetakeNotification
                || this.notification.message === Notification.FeedbackCompleteNotification)
                this.redirectToStudentAssessment()
        }, 
        removeNotification(){
            this.$store.dispatch('removeNotificationFromBox', this.notification)
        },
        //User for student notification
        redirectToStudentAssessment(){
            this.$router.push({
                name: 'Assessment',
                params: {
                    lessonId: this.notification.notificationArgs.lessonId, 
                    unitId: this.notification.notificationArgs.unitId
                }, 
                query:{
                    refresh: true
                }
            })
        }, 
        //User for student notification
        redirectToStudentReport(){
            this.$router.push({
                name:'StudentReports', 
                params: {
                    studentId: this.studentId
                }
            })
        }
    },
}
</script>
<style lang="less" scoped>
.box-notification{
    width: 100%;
    border-top: 1px solid #CFCFCF;
    .column:nth-child(2){
        P{
            margin:auto;
            width: 190px;
            height: auto;
            padding: 10px;
            white-space:normal;
            
            color: rgba(0, 0, 0, 0.6);
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 28px;
        }
        span{
            color: rgba(0,0,0,0.60);
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-style: italic;
            letter-spacing: 0.4px;
            line-height: 16px;
            text-align: justify;
            margin-left: 8px;
        }
    }
    >span:first-child{
        min-height: 40px;
        min-width: 40px;
        border-radius: 50%;
        text-align: center;
        color: #fff;
        background-color: #3A4DFD;
        text-overflow: none;
        font-weight: 700;
        line-height: 40px;
        text-transform: uppercase;
        max-height: 40px;
        align-self: center;
        cursor: pointer;
        margin-left: 8px;
    }
    .close-icon{
        margin: auto;
        margin-right: 8px;
        font-weight: 400;
        font-size: 24px;
        color: rgba(0,0,0,0.5);
    }

}
</style>

