import apiClient from '@/services/ApiClient.js'

export default {
    async get(unitstoryId) {
        return apiClient.get(`unitstory/${unitstoryId}`)
    },
    async createStory(unitId) {
        return apiClient.post('unitstory', {
            unitId: unitId
        })
    },
    async removeUnitStory(unitstory){
        return apiClient
            .delete(`unitstory/${unitstory.id}`)
    },
    async updateUnitStoryName(story){
        return apiClient
            .patch(`unitstory/${story.id}/change-name`, story)
    }
}