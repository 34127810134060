import apiClient from '@/services/ApiClient.js'

export default {
    linkImageToParagraph(imageDetails) {
        return apiClient.post('imagedetails', imageDetails)
    },
    updateImageDetails(imageDetails) {
        return apiClient.put(`imagedetails/${imageDetails.id}`, imageDetails)
    },
    removeImageFromParagraph(imageDetailsId) {
        return apiClient.delete(`imagedetails/${imageDetailsId}`)
    }
}