var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "th-logo" }, [
    _c("img", { staticClass: "map-image", attrs: { src: _vm.logoImage } }),
    _vm.isBackOfficeSection
      ? _c("span", { staticClass: "logged-user" }, [
          _vm._v(_vm._s(_vm.$t("content creator")))
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }