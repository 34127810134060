var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "builder-bar flex-container" },
    [
      _c(
        "div",
        { staticClass: "controls flex-container" },
        [
          _c("span", [_vm._v(_vm._s(_vm.breadcrumb))]),
          _vm.isSwitchButtonDisplayed
            ? _c("switch-button", {
                attrs: {
                  primaryTag: _vm.$t("content"),
                  secondaryTag: _vm.$t("layout"),
                  initialState: _vm.isContentSectionActive,
                  actionHandler: _vm.switchButtonActionHandler
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "button-container centered" }, [
        _c("div", { staticClass: "contributors-button-container" }, [
          _c(
            "button",
            {
              staticClass: "btn secondary contributors-button",
              on: { click: _vm.goToContributorPageBuilder }
            },
            [
              _vm._v(
                "\t\n        " +
                  _vm._s(_vm.$t("contributorsPage")) +
                  "\t\n      "
              )
            ]
          )
        ]),
        _c("div", { staticClass: "upload-btn-wrapper" }, [
          _c("button", { staticClass: "btn secondary disabled upload-btn" }, [
            _vm._v(
              "\t\n        " + _vm._s(_vm.$t("scopeAndSequence")) + "\t\n      "
            )
          ]),
          _c("input", {
            attrs: { type: "file" },
            on: { change: _vm.onFileChange }
          })
        ])
      ]),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "text-button": "Ok",
                "click-handler": _vm.hideVocabularyBox,
                clickOutSideModalHandler: _vm.hideVocabularyBox,
                showCancelButton: _vm.showModalCancelButton,
                modalType: _vm.modalType
              }
            },
            [_c("scope-and-sequence", { attrs: { isPreviewMode: true } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }