var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box-notification row",
      style: { backgroundColor: _vm.notification.isRead ? "#F6F7FA" : "#fff" },
      on: { click: _vm.markNotificationAsRead }
    },
    [
      _c("span", [
        _vm._v(
          _vm._s(_vm.notification.fromUser.firstName[0]) +
            _vm._s(_vm.notification.fromUser.lastName[0])
        )
      ]),
      _c("div", { staticClass: "column" }, [
        _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
        _c("span", [_vm._v(_vm._s(_vm.date))])
      ]),
      _c(
        "span",
        {
          staticClass: "close-icon",
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.removeNotification()
            }
          }
        },
        [_vm._v("×")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }