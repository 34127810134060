<template>
  <div class="back-button row ml-0 " @click="GoToRoute()">
      <img :src="arrowIcon">
      <span >{{text}}</span>
  </div>
</template>

<script>
export default {
    name: 'backButton',
    props:{
            text: String, 
            route: Object
        },
    data () {
        return {
            arrowIcon: ''
        }
    }, 
    methods: {
        GoToRoute(){
            window.scrollTo(0, 0);
            this.$router.push(this.route);
        }
    },
    created(){
        this.arrowIcon = require('@/assets/icons/ic_arrow_back_white.svg')
    }
}
</script>
<style lang="less" >


.back-button{
    color: #FFF;
    position: relative;
    margin-right: 56px;
    cursor: pointer;
    background-color: #0A1554;
    height: 56px;
    min-width: 120px;	
    text-transform: uppercase;
    z-index: 1;
    margin-right: 58px !important;
    img{
        height: 21px;
        width: 24px;
        align-self: center;
        margin:auto;
        margin-right:14px;
    }
    span{
        align-self: center;
        margin-right: auto;
        font-size: 24px;	
        font-weight: 600;	
        letter-spacing: 0.18px;	
        line-height: 29px;
    }
    &::after{
        content: '';
        position: absolute;
        top: 0;
        right: -29px;
        height: 56px;
        width: 80px;
        z-index: -1;
        background-color: #0A1554;
        -webkit-transform: skew(-45deg);
            transform: skew(-45deg);
    }
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: -29px;
        height: 56px;
        width: 80px;
        z-index: -1;
        background-color: #0A1554;
        -webkit-transform: skew(-45deg);
            transform: skew(-45deg);
    }

    &:nth-child(even){
        background-color: #3A4DFD;
        &::before, &::after{
            background-color: #3A4DFD;
        }
    }
}
</style>
