<template>
    <div class="notifications flex-container">
        <snack-bar v-for="notification in notifications"
             :key="notification.timeStamp" :notification="notification"/>
    </div>
</template>
<script>
import SnackBar from '@/common/SnackBar.vue'
export default {
    name: 'SnackBarNotificationsContainer',
    components:{
        'snack-bar': SnackBar
    }, 
    computed:{
        notifications(){
            return this.$store.state.snackBarNotifications
        }
    }
}
</script>
<style lang="less" scoped>

.notifications{
    flex-direction: column;
    min-width: 1px;
    min-height: 1px;
    position: fixed;
    right: 0;
    top: 128px;
    z-index: 10000;
}
</style>


