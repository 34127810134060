<template>
  <div class="th-logo">
      <img class="map-image" :src="logoImage"/>
      <span v-if="isBackOfficeSection" class="logged-user">{{$t('content creator')}}</span>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  computed:{
      isBackOfficeSection(){
          return this.$route.path.includes('back-office')
            ? true : false
      },
      logoImage(){
          return require('@/assets/3 Header Logo.png')
      }
  }
}
</script>

<style lang="less">
.th-logo{
    text-transform: uppercase;
    > span{
        display: block;
    }
    max-width: 120px;
}
.th-logo span{
    color: #0A1554;
    font-family: 'Athelas';
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.80px;
    margin-top: -5px;
}

.map-image{
    width: 160px;
    margin-left: -20px;
}

.logged-user {
    width: fit-content;
}
</style>