import apiClient from '@/services/ApiClient.js'

export default {
    async get(biographyId) {
        return apiClient.get(`biography/${biographyId}`)
    }, 
    async getBiographyComponents(biographyId) {
        return apiClient.get(`biography/${biographyId}/backup`)
    }, 
    async createBiographyComponent(biographyComponent) {
        return apiClient.post('paragraph', biographyComponent)
    },
    async updateBiographyComponent(biographyComponent) {
        return apiClient.put(`paragraph/${biographyComponent.id}`, biographyComponent)
    },
    async reoderBiographyComponent(biographyComponent) {
        return apiClient.patch(`paragraph/${biographyComponent.id}`, {order:biographyComponent.order})
    },
    async removeBiographyComponent(biographyComponent) {
        return apiClient.delete(`paragraph/${biographyComponent.id}`, biographyComponent)
    }, 
    async createBiography(unitId){
        return apiClient.post('biography',{
            unitId: unitId
          })
    },   
    async updateBiographyContent(biography){
        return apiClient
            .put(`biography/${biography.id}`, biography)
    }, 
    async updateBiographyName(biography){
        return apiClient
            .patch(`biography/${biography.id}/change-name`, biography)
    }, 
    async publishBiography(biography){
        return apiClient
            .patch(`biography/${biography.id}/publish`)
    }, 
    async removeBiography(biography){
        return apiClient
            .delete(`biography/${biography.id}`)
    }
}