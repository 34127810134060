import apiClient from '@/services/ApiClient.js'

export default {
    async getAssessment(lessonId) {
        return apiClient.get(`assessment/${lessonId}`)
    },
    async setMultipleChoiceQuestionStudentAnswer(answer){
        return apiClient.post('multiple-choice-answer', answer)
    },
    async updateEssayStudentAnswer(answer){
        return apiClient.post('essay-answer', answer)
    },
    async setVennDiagramStudentAnswer(answer){
        return apiClient.post('venn-diagram-answer', answer)
    },
    async setAssessmentCompleted(lessonId){
        return apiClient.patch(`lesson/${lessonId}/assessment-completed`)
    },
    //Calls to API for Assessment Builder
    async addAssessmentToLesson(lessonId){
        return apiClient.post(`assessment`,{
            lessonId: lessonId
        })
    },
    async getAssessmentComponents(lessonId){
        return apiClient.get(`assessment/${lessonId}/backup`)
    },
    async updateAssessmentDirections(assessment){
        return apiClient.put(`assessment/${assessment.id}`, assessment)
    },
    async removeAssessment(lessonId){
        return apiClient.delete(`assessment/${lessonId}`)
    },
    async addQuestionToAssessment(question){
        return apiClient.post(`assessment/${question.assessmentId}/question`, question)
    },
    async updateQuestion(question){
        return apiClient.put(`question/${question.id}`, question)
    },
    async updateQuestionComponentOrder(question){
        return apiClient.patch(`question/${question.id}`, question)
    },
    async addChoiceToQuestion(question){
        return apiClient.post(`choice/${question.id}`)
    },
    async removeQuestionFromAssessment(question){
        return apiClient.delete(`question/${question.id}`)
    },
    async updateCorrectAnswer(choice){
        return apiClient.patch(`choice/${choice.id}/correct`)
    },
    async updateChoiceTextInQuestion(choice){
        return apiClient.patch(`choice/${choice.id}/change-name`, {
            name: choice.text
        })
    },
    async removeChoiceFromQuestion(choice){
        return apiClient.delete(`choice/${choice.id}`)
    },
    async updateWrittenStudentAnswer(answer){
        return apiClient.post("written-answer", answer);
    }, 

    async addVennDiagramElement(question){
        return apiClient.post(`venn-diagram-element/${question.id}`)
    }, 
    async removeElementFromVennDiagram(element){
        return apiClient.delete(`venn-diagram-element/${element.id}`)
    }, 
    async updateVennDiagramElement(element){
        return apiClient.put(`venn-diagram-element/${element.id}`, element)
    }, 
    async addTeksToQuestion(questionId){
        return apiClient.post(`question/${questionId}/teks`)
    }, 
    async removeTeksFromQuestion(teksId){
        return apiClient.delete(`teks/${teksId}`)
    }, 
}