import apiClient from "./ApiClient.js";

const BASE_URL = "District";

export default {
    getDistricts(){
        return apiClient.get(BASE_URL);
    },
    getByState(stateId){
        return apiClient.get(`${BASE_URL}/GetByStateId/${stateId}`);
    },
    getByName(districtName){
        return apiClient.get(`${BASE_URL}/FindByName/${districtName}`);
    },
    addDistrict(newDistrict){
        return apiClient.post(BASE_URL, newDistrict);
    },
    updateDistrict(updatedDistrict){
        return apiClient.put(`${BASE_URL}/${updatedDistrict.id}`, updatedDistrict);
    },
    removeDistrict(removeDistrict){
        return apiClient.delete(`${BASE_URL}/${removeDistrict.id}`);
    }
};